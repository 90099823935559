// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main_btns {
    padding: 5px 10px;
    background: #7a5029;
    cursor: pointer;
    height: 65px;
    color: #ffffff;
    font-size: 13px;
}
.head_top_of_b_c_n_t p {
    margin-bottom: 10px;
}
.main_btns:hover{
    opacity: 0.8;
}`, "",{"version":3,"sources":["webpack://./src/Main_content/Sub_content/Button_cnt.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,cAAc;IACd,eAAe;AACnB;AACA;IACI,mBAAmB;AACvB;AACA;IACI,YAAY;AAChB","sourcesContent":[".main_btns {\n    padding: 5px 10px;\n    background: #7a5029;\n    cursor: pointer;\n    height: 65px;\n    color: #ffffff;\n    font-size: 13px;\n}\n.head_top_of_b_c_n_t p {\n    margin-bottom: 10px;\n}\n.main_btns:hover{\n    opacity: 0.8;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
