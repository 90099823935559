import React, { useContext, useState } from 'react'
import './Header.css'
import { E_invoice_context } from '../E_invoice_context';
import AuthService from '../Service/Authservice';
import { useNavigate } from 'react-router-dom';


const Header = ({ toggle, setToggle }) => {
    const { decryptData, decodedPayload, CurrentUser } = useContext(E_invoice_context)
    var Head_img = require('../Header/images/MBFSLogo.png');
    const Get_first_letter = decodedPayload && decodedPayload.UserName.charAt(0).toUpperCase()
    const username = decryptData('Username')
    const Get_identifier = CurrentUser && CurrentUser
    const userType = decryptData('userType')
    const [Cpassword, Setcpassword] = useState('')
    const [confirmcpassword, Setconfirmcpassword] = useState('')
    const [CpasswordErr, SetcpasswordErr] = useState('')
    const Navigate = useNavigate()
    const handlechangepassword = (e) => {
        e.preventDefault();
        SetcpasswordErr('');
        if (Cpassword === "" || confirmcpassword === "") {
            SetcpasswordErr('Please enter both passwords');
            return; 
        }
    
        if (Cpassword !== confirmcpassword) {
            SetcpasswordErr('Passwords do not match');
            return; 
        }
    
        // Call the API to change the password
        AuthService.PostServiceCall(`api/Login/ChangePassword?password=${encodeURIComponent(confirmcpassword)}&identifier=${Get_identifier}`)
            .then((res) => {
                console.log(res);
                // Clear input fields and close modal
                Setcpassword('');
                Setconfirmcpassword('');
                document.getElementById('changepasswordcancelbtn').click();
                document.getElementById('alert_change').click();
            })
            .catch((err) => {
                console.error("Error:", err);
                // Handle error - show error message to the user
                SetcpasswordErr('Failed to change password. Please try again.');
            });
    }
    
    const Logout = () => {
        sessionStorage.clear()
        Navigate('/');
    }

    const sso_logout = () => {
        sessionStorage.clear()
        AuthService.GetServiceCall('api/Login/SSOLogOutURL')
            .then((res) => {
                if (res && res !== undefined && res !== null) {
                    window.location.href = res.ssourl;
                }
            })
            .catch((err) => console.log('Error:', err));
    }

    return (
        <div>
            <div>
                <input type="text" hidden id='alert_change' data-bs-toggle="modal" data-bs-target="#alert_change_pass" />
                <div className="modal fade" id="profile_hit" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog mdl_dailog">
                        <div className="modal-content mdl_cnt">
                            <div className="modal-header mdl_header">
                                <h5 className="modal-title mdl_title">My Profile</h5>
                            </div>
                            <div className="modal-body">
                                <label className="label_w_s mt-2" htmlFor="a_c_i_pan">Name</label>
                                <input className="search_inp_filed in_tb mb-2" id='' defaultValue={username} type="text" name="" placeholder=""></input>

                                <label className="label_w_s mt-2" htmlFor="a_c_i_pan">User Name</label>
                                <input className="search_inp_filed in_tb mb-2" id='' defaultValue={userType} type="text" name="" placeholder=""></input>

                                <label className="label_w_s mt-2" htmlFor="a_c_i_pan">Department</label>
                                <input className="search_inp_filed in_tb mb-2" id='' type="text" name="" placeholder=""></input>
                                <p style={{ color: 'red' }}>{CpasswordErr}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="grn_btn">Save</button>
                                <button type="button" id='changepasswordcancelbtn' data-bs-dismiss="modal" className="btn_delete">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="exampl_1" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog mdl_dailog">
                        <div className="modal-content mdl_cnt">
                            <div className="modal-header mdl_header">
                                <h5 className="modal-title mdl_title">Change Password</h5>
                            </div>
                            <div className="modal-body">
                                <label className="label_w_s" htmlFor="a_c_i_pan">New Password</label>
                                <input className="search_inp_filed in_tb" id='Setcpassword' onChange={(e) => Setcpassword(e.target.value)} type="text" name="" placeholder="New Password"></input>

                                <label className="label_w_s mt-3" htmlFor="a_c_i_pan">Confirm New Password</label>
                                <input className="search_inp_filed in_tb mb-3" id='Setconfirmcpassword' onChange={(e) => Setconfirmcpassword(e.target.value)} type="text" name="" placeholder="Confirm New Password"></input>

                                <p style={{ color: 'red' }}>{CpasswordErr}</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="grn_btn" onClick={handlechangepassword} >Save</button>
                                <button type="button" id='changepasswordcancelbtn' data-bs-dismiss="modal" className="btn_delete">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="alert_change_pass" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog mdl_dailog">
                        <div className="modal-content mdl_cnt">
                            <div className="modal-header mdl_header">
                                <h5 className="modal-title mdl_title">Alert Change Password</h5>
                            </div>
                            <div className="modal-body">
                                <p>Your Password Was Changed Successfully</p>
                            </div>
                            <div className="modal-footer">
                                <button type="button" id='' data-bs-dismiss="modal" className="btn_delete">Ok</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='header_top'>
                <div className='header_left_sd'>
                    <div onClick={() => setToggle(!toggle)} className="hamberger_sym three_bars_top_menu ">
                        <span className="line_1"></span>
                        <span className="line_2"></span>
                        <span className="line_1"></span>
                    </div>
                    <div className='mbfs_image'>
                        <img src={Head_img} alt="MBFS_LOGO" />
                    </div>
                    <div className='e_invoice_head'>
                        <p>e-Invoicing</p>
                    </div>
                </div>
                <div className='end_setting_btn' id="dropdownMenuButton1" data-bs-toggle="dropdown">
                    <span>{Get_first_letter}</span>
                    <ul className="dropdown-menu cus_drop_dwn" aria-labelledby="dropdownMenuButton1">
                        <li className='head_top_onset'>Settings</li>
                        {/* <li className='drop_sty_nav' data-bs-toggle="modal" data-bs-target="#profile_hit"><i className="fa-solid fa-user"></i><p>My Profile</p></li> */}
                        <li className='drop_sty_nav cent' data-bs-toggle="modal" data-bs-target="#exampl_1"><i className="fa-solid fa-key"></i><p>Change Password</p></li>
                        {/* <li className='drop_sty_nav' onClick={Logout}><i className="fa-solid fa-lock"></i><p>Logout</p></li> */}
                        <li className='drop_sty_nav' onClick={sso_logout}><i className="fa-solid fa-lock"></i><p>Logout</p></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Header