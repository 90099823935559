import React, { useContext, useEffect, useState } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context';
import AuthService from '../../../../Service/Authservice';
import Invoice_email_table from '../../Invoice/Invoice_Email_list/Invoice_email_table';
import Invoice_email_view from '../../Invoice/Invoice_Email_list/Invoice_email_view';
import All_invoice_view from '../../Invoice/All_invoice/All_invoice_view';
import { useNavigate } from 'react-router-dom';


const Performa_Email_list = () => {
  const { sessionEndTime, itemdropdown, proforma, proformaAccess, setIsProfoma, show, SetShow, invoiceviewdropdown, decryptData, Getinvoicetype } = useContext(E_invoice_context)

  useEffect(() => {
    if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
      (sessionEndTime < new Date())) {
      document.getElementById('go_to_logintime_performa_list').click();
    }
    Getinvoicetype();
  }, [])

  const Navigate = useNavigate()

  const Logout = () => {
    sessionStorage.clear()
    Navigate('/');
  }


  const [InvoiceEmailshow, SetInvoiceEmailShow] = useState(1);
  const [Emailinvoicedata, setEmailinvoicedata] = useState()
  const [Emailinvoiceresponse, setEmailinvoicereponse] = useState()
  const [Emailinvoicesearch, setEmailinvoicesearch] = useState('')
  const [EmailinvoicePaginations, setEmailinvoicePaginations] = useState({ Current_page: 1 });
  const [Emailinvoiceselect, setEmailinvoiceselect] = useState({ suppliername: '', invoicetype: '' })
  const [EmailViewData, setEmailViewData] = useState()

  var invoiceTypeDown = []
  var ViewTypeDown = []
  var MakerTypeDown = []

  if (proforma && proforma.length !== 0) {
    invoiceTypeDown = itemdropdown && itemdropdown
      .filter(item => proforma.includes(item.invoiceTypeConfigurationIdentifier))
      .filter(item => proformaAccess && proformaAccess.some(rec => item.InvoiceTypeConfigurationAutoId === rec.InvoiceTypeConfigurationAutoId && (rec.makerFlag === true || rec.viewFlag === true)));

    ViewTypeDown = itemdropdown && itemdropdown
      .filter(item => proforma.includes(item.invoiceTypeConfigurationIdentifier))
      .filter(item => proformaAccess && proformaAccess.some(rec => item.InvoiceTypeConfigurationAutoId === rec.InvoiceTypeConfigurationAutoId && (rec.viewFlag === true)));

    MakerTypeDown = itemdropdown && itemdropdown
      .filter(item => proforma.includes(item.invoiceTypeConfigurationIdentifier))
      .filter(item => proformaAccess && proformaAccess.some(rec => item.InvoiceTypeConfigurationAutoId === rec.InvoiceTypeConfigurationAutoId && (rec.makerFlag === true)));
  }
  else {
    invoiceTypeDown = itemdropdown
    ViewTypeDown = itemdropdown
    MakerTypeDown = itemdropdown
  }

  useEffect(() => {
    setIsProfoma(true)
  }, [])

  const invoiceEmailpageNumbers = [];
  for (let i = 1; i <= Math.ceil(Emailinvoiceresponse && Emailinvoiceresponse / 10); i++) {
    invoiceEmailpageNumbers.push(i);
  }

  const invoicehEmailandlePageChange = (pageNumber) => {
    setEmailinvoicePaginations({ ...EmailinvoicePaginations, Current_page: EmailinvoicePaginations.Current_page = pageNumber });
  };

  useEffect(() => {
    GetInvoiceEmailList();
  }, [EmailinvoicePaginations, Emailinvoiceselect])

  const EmailinvoiceList = {
    search: Emailinvoicesearch,
    pageSize: 10,
    currentPage: EmailinvoicePaginations.Current_page,
    invoiceTypeIdentifier: Emailinvoiceselect.invoicetype,
    supplierIdentifier: Emailinvoiceselect.suppliername,
    customerType: "",
    customerIdentifier: "",
    statusFlag: 3,
    isMail: true,
    isCreditNote: false,
    isProforma: true,
    sortField: "",
    descFlag: false
  }

  const GetInvoiceEmailList = () => {
    AuthService.PostServiceCall('api/Invoice/GetAllInvoices', EmailinvoiceList)

      .then((res) => {
        // console.log(res)
        setEmailinvoicedata(res.data)
        setEmailinvoicereponse(res.recordCount)
      })

  }

  const handleView = (unq) => {
    SetInvoiceEmailShow(4)
    // console.log(unq + "identifier")
    AuthService.GetServiceCall(`api/Invoice/GetInvoiceById?InvoiceIdentifier=${unq}`)
      .then((res) => {
        // console.log(res)
        // console.log(res.data.amountInput)
        setEmailViewData(res.data);
        // setitemviewdata({
        //     ...itemviewdata,
        //     itemName: res.responseData[0].itemName,
        //     itemCode: res.responseData[0].itemCode,
        //     invoiceType: res.responseData[0].invoiceType,
        //     itemDescription: res.responseData[0].itemDescription,
        //     hsncode: res.responseData[0].hsncode,
        //     hsncode1: res.responseData[0].hsncode1,
        //     hsncode2: res.responseData[0].hsncode2,
        //     hsncode3: res.responseData[0].hsncode3,
        //     cgstrate: res.responseData[0].cgstrate,
        //     sgstrate: res.responseData[0].sgstrate,
        //     igstrate: res.responseData[0].igstrate,
        //     ccessRate: res.responseData[0].ccessRate,
        //     sapgl: res.responseData[0].sapgl,
        //     itemMasterIdentifier: res.responseData[0].itemMasterIdentifier
        // })
      })
  }

  const emailentersearch = (e) => {
    if (e.keyCode === 13) {
      GetInvoiceEmailList();
    }
  }


  return (

    <div>
      <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />
      <input type='button' hidden id='go_to_logintime_performa_list' data-bs-toggle="modal" data-bs-target="#go_to_login_performa_list" />

      <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_performa_list" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
          <div className="modal-content mdl_cont">
            <div className="modal-header mdl_header">
              <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
            </div>
            <div className="modal-body mdl_bdy">
              Something Went Wrong.Please Login Again
            </div>
            <div className="modal-footer">
              <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
            </div>
          </div>
        </div>
      </div>
      
      <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
          <div className="modal-content mdl_cont">
            <div className="modal-header mdl_header">
              <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
            </div>
            <div className="modal-body mdl_bdy">
              Something Went Wrong.Please Login Again
            </div>
            <div className="modal-footer">
              <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
            </div>
          </div>
        </div>
      </div>

      <div className='h4_btm_bdr'>
        {
          show && show === 1 ? ('') : (<i onClick={() => SetShow(1)} className="fa-solid fa-circle-arrow-left"></i>)
        }
        <h4>Proforma Mail List</h4>
      </div>
      {
        show && show === 1 &&

        <div className='total_outfit_fr_tab'>
          <div className='invoice_em_top_head'>
            <select className="select_inp_filed" onChange={(e) => setEmailinvoiceselect({ ...Emailinvoiceselect, invoicetype: e.target.value })} aria-label="Default select example">
              <option value='' lected>Invoice Type</option>
              {
                invoiceTypeDown && invoiceTypeDown.map((itemdrop, uq) => (
                  <option key={uq} value={itemdrop.invoiceTypeConfigurationIdentifier}>
                    {itemdrop.invoiceTypeName}
                  </option>
                ))
              }

            </select>
            <select className="select_inp_filed" onChange={(e) => setEmailinvoiceselect({ ...Emailinvoiceselect, suppliername: e.target.value })} aria-label="Default select example">
              <option value=''>Supplier Name</option>
              {
                invoiceviewdropdown && invoiceviewdropdown.map((invoicedrop, uq) => (
                  <option key={uq} value={invoicedrop.supplierIdentifier}>
                    {invoicedrop.supplierTradeName}
                  </option>
                ))
              }
            </select>
            <input onKeyDown={emailentersearch} onChange={(e) => setEmailinvoicesearch(e.target.value)} className="search_inp_filed" type="text" name="" id="" placeholder="Search"></input>
            <i onClick={GetInvoiceEmailList} className="fa-solid fa-magnifying-glass search_icon"></i>
          </div>
          <Invoice_email_table
            SetInvoiceEmailShow={SetInvoiceEmailShow}
            Emailinvoiceselect={Emailinvoiceselect}
            Emailinvoiceresponse={Emailinvoiceresponse}
            Emailinvoicedata={Emailinvoicedata}
            invoiceEmailpageNumbers={invoiceEmailpageNumbers}
            EmailinvoicePaginations={EmailinvoicePaginations}
            invoicehEmailandlePageChange={invoicehEmailandlePageChange}
            handleView={handleView}
          />
        </div>
      }
      {
        show === 4 &&
        <div>
          {/* <Invoice_email_view
          EmailViewData={EmailViewData}
          /> */}
          <All_invoice_view ViewTypeDown={ViewTypeDown} MakerTypeDown={MakerTypeDown} />
        </div>
      }
    </div>
  )
}

export default Performa_Email_list