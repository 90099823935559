import React, { useContext, useState } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'

const Item_master_input = ({ create_fieldempty }) => {
    const { itemshow, setitemshow, itemdropdown, setitemdropdownvalue, itemdropdowncreate, ItemRegFlag,
        setitemdropdownvaluecreate, handleitemcreatechange, createitemmaster, handleitemsaveinput, errfieldone,handleitemsaveinputone } = useContext(E_invoice_context)

    const [regFlag, setRegFlag] = useState(false)
    const handleInvoiceTypeChange = (e) => {
        handleitemcreatechange(e)
        setRegFlag(itemdropdowncreate && itemdropdowncreate.filter((item) => (item.invoiceTypeConfigurationIdentifier === e.target.value))[0] && itemdropdowncreate.filter((item) => (item.invoiceTypeConfigurationIdentifier === e.target.value))[0].reg_Unreg_Flg)
    }



    // console.log(regFlag)

    return (
        <div style={{ border: '1px solid #eaeaea', overflow: 'hidden' }}>
            <div className="creat_c_m c_s_c">
                <i onClick={() => setitemshow(1)} className="fa-solid fa-circle-arrow-left"></i>
                <p style={{ marginBottom: '0', fontSize: '14px', color: '#c95643' }}>Create Item</p>
            </div>
            <div className='create_itm'>
                <div className='row'>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">Invoice Type</label>
                        <select className="select_inp_filed" style={{ width: '100%' }} name='invoiceTypeIdentifier' value={createitemmaster.invoiceTypeIdentifier} onChange={handleInvoiceTypeChange} aria-label="Default select example">

                            <option selected value=''>Select</option>
                            {
                                itemdropdowncreate && itemdropdowncreate.map((itemdro, uq) => (
                                    <option key={uq} value={itemdro.invoiceTypeConfigurationIdentifier}>
                                        {itemdro.invoiceTypeName}
                                    </option>
                                ))
                            }
                        </select>
                        {
                            errfieldone === false &&
                            <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.invoiceTypeIdentifier === '' ? ('Please Provide valid Information ') : ('')}</p>
                        }

                    </div>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">Item Name<span className="mandatory_star2">*</span></label>
                        <input className="search_inp_filed in_tb" type="text" name="itemName" value={createitemmaster.itemName} onChange={handleitemcreatechange} placeholder=""></input>
                        {
                            errfieldone === false &&
                            <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.itemName === '' || createitemmaster.itemName.length > 50 ? ('Please Provide valid Information') : ('')}</p>
                        }

                    </div>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">Item Code<span className="mandatory_star2">*</span></label>
                        <input className="search_inp_filed in_tb" type="text" name="itemCode" value={createitemmaster.itemCode} onChange={handleitemcreatechange} placeholder=""></input>
                        {
                            errfieldone === false &&
                            <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.itemCode === '' || createitemmaster.itemCode.length > 50 ? ('Please Provide valid Information ') : ('')}</p>
                        }

                    </div>
                </div>
                <div className='item_dexcription mt-4 '>
                    <label className="label_w_s" htmlFor="a_c_i_pan">Item Description<span className="mandatory_star2">*</span></label>
                    <textarea className='input_txt_are' name="itemDescription" value={createitemmaster.itemDescription} onChange={handleitemcreatechange} id="" rows="2"></textarea>
                    {
                        errfieldone === false &&
                        <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.itemDescription === '' ? ('Please Provide valid Information ') : ('')}</p>
                    }

                </div>
            </div>

            <div className="creat_c_m c_s_c">
                <p style={{ marginBottom: '0', fontSize: '14px', color: '#c95643' }}>GST Rates</p>
            </div>
            {
                regFlag ? (<div className='gst_rowates'>

                    <div className='col-lg-4 gst_rates'>
                        <div>
                            <label className="label_w_s" htmlFor="a_c_i_pan">HSN code for contract starting from 1<span className="mandatory_star2">*</span></label>
                            <input onChange={handleitemcreatechange} className="search_inp_filed in_tb" value={createitemmaster.hsncode1} type="text" name="hsncode1" placeholder=""></input>
                            {
                                errfieldone === false &&
                                <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.hsncode1 === '' ? ('Please Provide valid Information ') : ('')}</p>
                            }
                        </div>

                        <div>
                            <label className="label_w_s" htmlFor="a_c_i_pan">HSN code for contract starting from 2<span className="mandatory_star2">*</span></label>
                            <input onChange={handleitemcreatechange} className="search_inp_filed in_tb" type="text" value={createitemmaster.hsncode2} name="hsncode2" placeholder=""></input>
                            {
                                errfieldone === false &&
                                <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.hsncode2 === '' ? ('Please Provide valid Information ') : ('')}</p>
                            }
                        </div>
                        <div>
                            <label className="label_w_s" htmlFor="a_c_i_pan">HSN code for contract starting from 3<span className="mandatory_star2">*</span></label>
                            <input onChange={handleitemcreatechange} className="search_inp_filed in_tb" type="text" value={createitemmaster.hsncode3} name="hsncode3" placeholder=""></input>
                            {
                                errfieldone === false &&
                                <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.hsncode3 === '' ? ('Please Provide valid Information ') : ('')}</p>
                            }
                        </div>

                    </div>

                </div>) : (
                    <div className='gst_rowates'>
                        <div className='col-lg-4 gst_rates'>
                            <div>
                                <label className="label_w_s" htmlFor="a_c_i_pan">HSN code<span className="mandatory_star2">*</span></label>
                                <input onChange={handleitemcreatechange} className="search_inp_filed in_tb" type="text" value={createitemmaster.hsncode} name="hsncode" placeholder="" maxLength={8}></input>
                                {
                                    errfieldone === false &&
                                    <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.hsncode === '' ? ('Please Provide valid Information ') : ('')}</p>
                                }
                            </div>
                        </div>
                    </div>
                )
            }

            <div className="creat_c_m c_s_c row">
                <div className='col-lg-6 col-12'>
                    <p style={{ marginBottom: '0', fontSize: '14px', color: '#c95643' }}>GST Rates</p>
                </div>
                <div className='col-lg-6 col-12'>
                    <p style={{ marginBottom: '0', fontSize: '14px', color: '#c95643' }}>GST - Interstate</p>
                </div>
            </div>
            <div className='gst_instratae'>
                <div className='row'>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">CGST (%)<span className="mandatory_star2">*</span></label>
                        <input onChange={handleitemcreatechange} className="search_inp_filed in_tb" value={createitemmaster.cgstrate} type="text" name="cgstrate" placeholder=""></input>
                        {
                            errfieldone === false &&
                            <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.cgstrate === '' ? ('Please Provide valid Information ') : ('')}</p>
                        }

                    </div>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">SGST (%)<span className="mandatory_star2">*</span></label>
                        <input onChange={handleitemcreatechange} className="search_inp_filed in_tb" value={createitemmaster.sgstrate} type="text" name="sgstrate" placeholder=""></input>
                        {
                            errfieldone === false &&
                            <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.sgstrate === '' ? ('Please Provide valid Information') : ('')}</p>
                        }

                    </div>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">IGST (%)<span className="mandatory_star2">*</span></label>
                        <input onChange={handleitemcreatechange} className="search_inp_filed in_tb" value={createitemmaster.igstrate} type="text" name="igstrate" placeholder=""></input>
                        {
                            errfieldone === false &&
                            <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.igstrate === '' ? ('Please Provide valid Information') : ('')}</p>
                        }

                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">Cess Rate (%)<span className="mandatory_star2">*</span></label>
                        <input onChange={handleitemcreatechange} className="search_inp_filed in_tb" value={createitemmaster.ccessRate} type="text" name="ccessRate" placeholder=""></input>
                        {
                            errfieldone === false &&
                            <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.ccessRate === '' ? ('Please Provide valid Information ') : ('')}</p>
                        }

                    </div>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">SAP GL</label>
                        <input onChange={handleitemcreatechange} className="search_inp_filed in_tb" value={createitemmaster.sapgl} type="text" name="sapgl" placeholder=""></input>
                        {/* {
                            errfieldone === false &&
                            <p style={{ fontSize: '12px', color: 'red' }}>{createitemmaster.sapgl === '' ? ('Please Provide valid Information ') : ('')}</p>
                        } */}

                    </div>
                </div>
            </div>
            {
                regFlag && regFlag === true ? (
                    <div className="p_T_c_btn mt-4 mb-4"><button className="grn_btn" onClick={handleitemsaveinput}>Save</button><button onClick={() => setitemshow(1)} className="diable_btn_edit">Cancel</button></div>
                ) : (
                    <div className="p_T_c_btn mt-4 mb-4"><button className="grn_btn" onClick={handleitemsaveinputone}>Save</button><button onClick={() => setitemshow(1)} className="diable_btn_edit">Cancel</button></div>
                )
            }

        </div>
    )
}

export default Item_master_input