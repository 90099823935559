import React, { useContext, useEffect } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'
import Paginations from '../../Pagination'
import Custom_master_cms from '../../Customer_master/Custom_master_cms'

const Customer_master_cms = ({ isHide, view }) => {
    const { SetShow, regFlag, GetContractCustomer, CMSCustomerDataObj, Pendingcustomerrescount, CMSCustomerPaginationNumbers, handleCMSCustomerPaginationNumber, setCMSCustomerSearch,
        CMSCustomerPagination, ViseFlag, CMSCustomerSearch, setCreateViewCustomerData, SetCMSCustomerPagination, setcustomviewshow, CustomerType, GetCMSCustomerDataObj, formatDate, GetCustomer, customviewshow } = useContext(E_invoice_context)

    // console.log(Pendingcustomerrescount)

    const GetCMSCustomers = (type, identifier) => {
        // alert(regFlag + "-" + CustomerType)
        if (regFlag) {
            GetContractCustomer(identifier)
            if (ViseFlag) {
                SetShow(4)
            }
            else
                SetShow(2)
        }
        else {
            GetCustomer(type, identifier)
            if (ViseFlag) {
                SetShow(4)
            }
            else
                SetShow(2)
        }

    }
    const GetCMSMasterView = (type, identifier) => {
        GetCustomer(type, identifier)
        setcustomviewshow(2)
    }

    const handleBack = () => {
        if (ViseFlag) {
            SetShow(4)
        }
        else
            SetShow(2)
        setCreateViewCustomerData([])
    }

    const searchcmscustomer = () => {
        SetCMSCustomerPagination({ ...CMSCustomerPagination, Current_page: CMSCustomerPagination.Current_page = 1 });
        GetCMSCustomerDataObj();
    }
    const entercmssearch = (e) => {
        if (e.keyCode === 13) {
            searchcmscustomer();
        }
    }
    useEffect(() => {
        GetCMSCustomerDataObj();
    }, [CMSCustomerPagination])
    return (
        <div>
            {
                customviewshow && customviewshow === 1 &&
                <div>
                    {!isHide && <> <i onClick={handleBack} style={{ fontSize: '24px', color: '#1e854f' }} className="fa-solid fa-circle-arrow-left"></i> <p style={{ backgroundColor: '#f1f1f1', fontSize: '13px', padding: '5px 10px' }}>Customer Master - CMS</p> </>}

                    <div className="create_btn_top" style={{ justifyContent: 'end' }}>
                        <div className='create_right_aligh'>
                            <input value={CMSCustomerSearch.cms} onKeyDown={entercmssearch} onChange={(e) => setCMSCustomerSearch({ ...CMSCustomerSearch, cms: e.target.value })} className="search_inp_filed" type="text" name="" id="" placeholder="Search"></input>
                            <i onClick={searchcmscustomer} className="fa-solid fa-magnifying-glass search_icon"></i>
                        </div>
                    </div>
                    <div className='table_c_m'>
                        <table className='table_c__M'>
                            <thead>
                                <tr>
                                    <th><span style={{ opacity: '0' }}>Customer</span></th>
                                    <th>CMS Contract Number</th>
                                    <th>Customer Name</th>
                                    <th>Activation Date</th>
                                    <th>Dealer</th>
                                    <th>City</th>
                                    <th>Payment Mode</th>
                                    <th>Vehicle Type</th>
                                    <th>CMS Status</th>
                                    <th>PAN NO.</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    CMSCustomerDataObj && CMSCustomerDataObj.map((cms, cmsiq) => (
                                        <tr key={cmsiq}>
                                            {
                                                view && view === true ? (
                                                    <td><button onClick={() => GetCMSMasterView(2, cms.contractIdentifier)} className='c_m_btn'>View</button></td>
                                                ) : (
                                                    <td><button onClick={() => GetCMSCustomers(2, cms.contractIdentifier)} className='c_m_btn'>select</button></td>
                                                )
                                            }

                                            <td>{cms.cmscontractNumber}</td>
                                            <td>{cms.customerName}</td>
                                            <td>{cms.contractActivationDate}</td>
                                            <td>{cms.dealer}</td>
                                            <td>{cms.city}</td>
                                            <td>{cms.paymentMode}</td>
                                            <td>{cms.vehicleType}</td>
                                            <td>{cms.cmsstatus}</td>
                                            <td>{cms.panNumber}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div >
                    <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <div>
                            <p style={{ color: '#9a9a9a', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{Pendingcustomerrescount}</span></p>
                        </div>
                        <div>
                            <Paginations
                                pageNumbers={CMSCustomerPaginationNumbers}
                                Paginations={CMSCustomerPagination}
                                handlePageChange={handleCMSCustomerPaginationNumber}
                                LastPage={Pendingcustomerrescount && Math.ceil(Pendingcustomerrescount / 10)}
                            />
                        </div>
                    </div>
                </div>
            }

            {
                customviewshow && customviewshow === 2 &&

                <Custom_master_cms />
            }
        </div>
    )
}

export default Customer_master_cms