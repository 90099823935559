import React, { useContext, useEffect } from "react";
import { E_invoice_context } from "../../../../E_invoice_context";

const Credit_pending_view_data = () => {
    const { pendingcreditdata, invoiceAccess, isAdmin, handleRejectpendingcreditInvoice, setpendingCreditShow, handleApprovependingcreditInvoice, pendingcreditgstflag, pendingcreditinvoicedata, pendingcreditcalculatedata } = useContext(E_invoice_context);
    const converter = require('number-to-words');
    const totalInvoiceValue = pendingcreditdata?.invoiceTotalValue || 0;
    const totalInvoiceWords = converter.toWords(totalInvoiceValue);
    const invoiceDate = new Date(pendingcreditdata?.invoiceDate);
    // const formattedInvoiceDate = invoiceDate.toLocaleDateString('en-GB', {
    //     day: '2-digit',
    //     month: 'short',
    //     year: 'numeric',
    // });
    var CheckerFlag = false;
    if (isAdmin) {
        CheckerFlag = true
    }
    else {
        if (invoiceAccess && invoiceAccess.length === 0) {
            CheckerFlag = true
        }
        else {
            CheckerFlag = invoiceAccess && invoiceAccess.filter(rec => (rec.invoiceTypeConfigurationAutoId === pendingcreditinvoicedata && pendingcreditinvoicedata.invoiceTypeConfigurationAutoId))[0] && invoiceAccess.filter(rec => (rec.invoiceTypeConfigurationAutoId === pendingcreditinvoicedata && pendingcreditinvoicedata.invoiceTypeConfigurationAutoId))[0].checkerFlag;
        }
    }
    const rejectinvoice = () => {
        document.getElementById('rejecte_credit').click()
    }
    const approveinvoice = () => {
        document.getElementById('approve_credit').click()
    }
    return (
        <div>
            <div className='total_outfit_fr_tab'>
                <div className='top_hd_aci'>
                    <p><b>Invoice No: </b> <span>{pendingcreditdata?.invoiceSerialNumber}</span></p>
                    <p><b>Invoice Date: </b><span>{pendingcreditdata?.invoiceDate}</span></p>
                </div>
            </div>
            <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item acc_item">
                    <button className="accordion-button All_c_i_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        <span>Supplier Details</span><i className="fa-solid fa-angle-down"></i>
                    </button>
                    <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body All_c_i_acc_bd">
                            <label className='label_w_s' htmlFor="a_c_i_sel">Invoice Type</label>
                            {pendingcreditdata?.invoiceType !== null || pendingcreditdata?.invoiceType !== "" ? (
                                <select
                                    style={{ width: "200px" }}
                                    id="a_c_i_sel"
                                    className="select_inp_filed"
                                    aria-label="Default select example"
                                    disabled
                                >
                                    <option selected>{pendingcreditdata?.invoiceType}</option>
                                </select>
                            ) : (
                                <select
                                    style={{ width: "200px" }}
                                    id="a_c_i_sel"
                                    className="select_inp_filed"
                                    aria-label="Default select example"
                                >
                                    <option selected>Select</option>
                                </select>)}
                        </div>
                        <div className='supplier_details'>
                            <h6>Supplier Details</h6>
                            <div className='row'>
                                <div className='col-lg-3 col-12'>
                                    <label htmlFor="">Supplier Name</label>
                                    <p>{pendingcreditdata?.invoiceType}</p>
                                </div>
                                <div className='col-lg-3 col-12'>
                                    <label htmlFor="">Supplier Address</label>
                                    <p>{pendingcreditdata?.supplierBuildingNumber},{pendingcreditdata?.supplierBuildingName} {pendingcreditdata?.supplierLocation} {pendingcreditdata?.supplierDistrict}-{pendingcreditdata?.supplierPincode}</p>
                                </div>
                                <div className='col-lg-3 col-12'>
                                    <label htmlFor="">Supplier Pan Number</label>
                                    <p>{pendingcreditdata?.supplierPan}</p>
                                </div>
                                <div className='col-lg-3 col-12'>
                                    <label htmlFor="">Supplier GST</label>
                                    <p>{pendingcreditdata?.supplierGstin}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item acc_item">
                    <button className="accordion-button All_c_i_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        <span>Customer Details</span><i className="fa-solid fa-angle-down"></i>
                    </button>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body All_c_i_acc_bd">
                            <div className='row'>
                                <div className='col-lg-6 col-12 '>
                                    <label className='label_w_s' htmlFor="a_c_i_sel">Search Customer Details</label>
                                    <div className='c_d_sept'>
                                        <input id='' disabled className="search_inp_filed" value={pendingcreditdata?.customerMasterTradeName} type="text" name="" placeholder=""></input>
                                    </div>
                                </div>
                            </div>
                            <div className='row c_d_invoice'>
                                <div className='col-lg-5 col-12'>
                                    <span>Bill To Address</span>
                                    <div className='row c_d_inoice_on'>
                                        <div className='col-lg-5 col-12'>
                                            <label htmlFor="">Customer Legal Name</label>
                                            <p>{pendingcreditdata?.customerMasterTradeName}</p>

                                            <label htmlFor="">Customer Phone</label>
                                            <p>{pendingcreditdata?.customerPhone}</p>
                                        </div>
                                        <div className='col-lg-7 col-12'>
                                            <label htmlFor="">Customer GST</label>
                                            <p>{pendingcreditdata?.customerGst}</p>

                                            <label htmlFor="">Customer Email</label>
                                            <p>{pendingcreditdata?.customerEmail}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-5 col-12 ship_add_col'>
                                    <span>Ship To Address</span>
                                    <div className='row c_d_inoice_tw'>
                                        <div className='col-lg-6 col-12'>
                                            <label htmlFor="">GST</label>
                                            <p>{pendingcreditdata?.shipGst}</p>

                                            <label htmlFor="">Email</label>
                                            <p>{pendingcreditdata?.shipEmail}</p>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <label htmlFor="">Phone</label>
                                            <p>{pendingcreditdata?.shipPhone}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-2 col-12 c_d_inoice_tr'>
                                    <div>
                                        <label htmlFor="">{pendingcreditdata && pendingcreditdata.shipGst === null || pendingcreditdata && pendingcreditdata.shipGst === "" || pendingcreditdata && pendingcreditdata.shipGst === undefined ? "B2C" : "B2B"}</label>
                                        <p>{pendingcreditdata && pendingcreditdata.shipGst === null || pendingcreditdata && pendingcreditdata.shipGst === "" || pendingcreditdata && pendingcreditdata.shipGst === undefined ? "UnRegisterd" : "Registerd"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    pendingcreditinvoicedata && pendingcreditinvoicedata[0].invRegUnreg && (
                        <div className="accordion accordion-flush" id="accordionFlushExample_3_0n">
                            <div className="accordion-item acc_item">
                                <button className="accordion-button All_c_i_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour_on" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    <span>Customer Contract Details</span><i className="fa-solid fa-angle-down"></i>
                                </button>
                                <div id="collapsefour_on" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample_3_0n">
                                    <div className="accordion-body All_c_i_acc_bd">
                                        <div className='customer_conract'>
                                            <div className='customer_conract_1'>
                                                <div className=''>
                                                    <button className='grn_btn' disabled>Customer Contract</button>
                                                </div>
                                                <div className='' style={{ display: 'flex', gap: '10px' }}>
                                                    <input className='search_inp_filed' type="text" name="" id="" placeholder='Enter Contract No.' disabled />
                                                    <i className="fa-solid fa-magnifying-glass search_icon" disabled></i>
                                                </div>
                                                <div className=''>
                                                    <label htmlFor="">Customer Name</label>
                                                    <p>{pendingcreditdata?.remarketingCustomerName}</p>
                                                </div>
                                                <div className=''>
                                                    <label htmlFor="">CMS Contract Number</label>
                                                    <p>{pendingcreditdata?.remarketingCustomerId}</p>
                                                </div>
                                                <div className=''>
                                                    <label htmlFor="">GST NO.</label>
                                                    <p>{pendingcreditdata?.remarketingCustomerGst}</p>
                                                </div>
                                            </div>
                                            <div className='customer_conract_1 on'>

                                                <div className=''>
                                                    <label htmlFor="">VIN NO.</label>
                                                    <p>{pendingcreditdata?.reMarkVinno}</p>
                                                </div>
                                                <div className='' style={{ display: 'flex', justifyContent: 'start', gap: "20px" }}>
                                                    <div>
                                                        <label htmlFor="">Registration  No.</label>
                                                        <p>{pendingcreditdata?.vehicleNumber}</p>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="">Model</label>
                                                        <p>{pendingcreditdata?.remarkModel}</p>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <label htmlFor="">PAN Number</label>
                                                    <p>{pendingcreditdata?.reMarkPanNumber}</p>
                                                </div>
                                                <div className=''>
                                                    <label htmlFor="">Start Date</label>
                                                    <p>{pendingcreditdata?.reMarkContractStartDate}</p>
                                                </div>
                                                <div className=''>
                                                    <label htmlFor="">Total Cost</label>
                                                    <p>{pendingcreditdata?.reMarkTotalCostAmount}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='customer_contact_blr'>
                                            <div className='cus_cont_top'>
                                                <div className='custom_cont_div'>
                                                    <label htmlFor="">Sales Amount</label>
                                                    <input className='search_inp_filed' type="text" name="" id="" value={pendingcreditdata?.remarkSalesAmount} disabled />
                                                </div>
                                                <div className='custom_cont_div'>
                                                    <input type="radio" name="gst" placeholder='10000' checked={pendingcreditgstflag === 1} id="" disabled />
                                                    <label htmlFor="">GST Registered</label>
                                                </div>
                                                <div className='custom_cont_div'>
                                                    <input type="radio" name="gst" placeholder='10000' id="" checked={pendingcreditgstflag === 2} disabled />
                                                    <label htmlFor="">GST Unregistered</label>
                                                </div>
                                            </div>
                                            {pendingcreditgstflag === 2 && (<div className='cus_cont_top tw'>
                                                <div className='custom_cont_div_2'>
                                                    <label htmlFor="">Number of Quaters</label>
                                                    <p>{pendingcreditcalculatedata?.noOfQuaters}</p>
                                                </div>
                                                <div className='custom_cont_div_2'>
                                                    <label htmlFor="">Depreciation per quater</label>
                                                    <p>{pendingcreditcalculatedata?.dep_per_Qua}</p>
                                                </div>
                                                <div className='custom_cont_div_2'>
                                                    <label htmlFor="">Total Depreciation</label>
                                                    <p>{pendingcreditcalculatedata?.totalDep}</p>
                                                </div>
                                                <div className='custom_cont_div_2'>
                                                    <label htmlFor="">Assessed Amount</label>
                                                    <p>{pendingcreditcalculatedata?.assessed_Amount}</p>
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)}
                <div className="accordion-item acc_item">
                    <button className="accordion-button All_c_i_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        <span>Invoice Details</span><i className="fa-solid fa-angle-down"></i>
                    </button>
                    <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body All_c_i_acc_bd">
                            <div className='row rw_all_ci_bd'>
                                <div className='col-lg-3 col-12'>
                                    <p className='i_d_head'>Invoice Item</p>
                                    {pendingcreditdata?.invoiceItemName !== null || pendingcreditdata?.invoiceItemName !== "" ? (
                                        <select
                                            style={{ width: "200px" }}
                                            id="a_c_i_sel"
                                            className="select_inp_filed"
                                            aria-label="Default select example"
                                            disabled
                                        >
                                            <option value={pendingcreditdata?.invoiceItemMasterIdentifier} selected>{pendingcreditdata?.invoiceItemName}</option>
                                        </select>
                                    ) : (
                                        <select
                                            style={{ width: "200px" }}
                                            id="a_c_i_sel"
                                            className="select_inp_filed"
                                            aria-label="Default select example"
                                        >
                                            <option selected>Select</option>
                                        </select>)}
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <p className='i_d_head'>Description of Goods / Service</p>
                                    <textarea disabled className='input_txt_are' value={pendingcreditdata?.invoiceItemDesctiption}></textarea>
                                </div>
                            </div>
                            <div className='row rw_all_ci_tw'>
                                {/* <div className='col-lg-3 col-12 amt_serch'>
                                    <label htmlFor="amount">Amount</label>
                                    <input style={{ width: '90px' }} id='amount' className="search_inp_filed in_tb" type="text" name="" placeholder="" value={pendingcreditdata?.amountInput} disabled></input>
                                </div> */}
                                {/* <div className='row rw_all_ci_tw'> */}
                                <div className='col-lg-3 col-12 amt_serch'>
                                    <label htmlFor="amount">Amount</label>
                                    <input
                                        style={{ width: '90px' }}
                                        id='amount'
                                        className="search_inp_filed in_tb"
                                        type="text"
                                        name=""
                                        placeholder=""
                                        value={pendingcreditdata?.amountInput}
                                        disabled
                                    />
                                </div>
                                <div className='col-lg-9 col-12 amt_radio'>
                                    <div>
                                        <input
                                            disabled
                                            id='tax_val'
                                            className="radio_all_c"
                                            type="radio"
                                            name=""
                                            placeholder=""
                                            checked={pendingcreditdata?.inputType === 1}
                                        />
                                        <label htmlFor="tax_val">Taxable Value</label>
                                    </div>
                                    <div>
                                        <input
                                            disabled
                                            id='amt_val'
                                            className="radio_all_c"
                                            type="radio"
                                            name=""
                                            placeholder=""
                                            checked={pendingcreditdata?.inputType === 3}
                                        />
                                        <label htmlFor="amt_val">Amount(Incl. GST & TCS)</label>
                                    </div>
                                    <div>
                                        <input
                                            disabled
                                            id='amt_inc'
                                            className="radio_all_c"
                                            type="radio"
                                            name=""
                                            placeholder=""
                                            checked={pendingcreditdata?.inputType === 2}
                                        />
                                        <label htmlFor="amt_inc">Amount(Incl. GST & No TCS)</label>
                                    </div>
                                    <div>
                                        <input
                                            disabled
                                            id='amt_exc'
                                            className="radio_all_c"
                                            type="radio"
                                            name=""
                                            placeholder=""
                                            checked={pendingcreditdata?.inputType === 4}
                                        />
                                        <label htmlFor="amt_exc">Amount(Incl. GST & Excl. TCS)</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row rw_all_ci_tr'>
                            <div className='col-lg-10 col-12'>
                                <table className='inc_amt_table'>
                                    <tr>
                                        <th>HSN/SAC <br /> Code</th>
                                        <th>Taxable Value <br /> (INR)</th>
                                        <th>Unit of <br /> Measurement</th>
                                        <th>Unit <br /> Price</th>
                                        <th>Value <br /> (INR)</th>
                                    </tr>
                                    <tr>
                                        <td><input id='' disabled className="search_inp_filed in_tb" value={pendingcreditdata?.invoiceHsncode} type="text" name="" placeholder=""></input></td>
                                        <td><input id='' disabled className="search_inp_filed in_tb" value={pendingcreditcalculatedata?.taxableValue} type="text" name="" placeholder=""></input></td>
                                        <td> {pendingcreditdata?.unitMeasurementName !== null || pendingcreditdata?.unitMeasurementName !== "" ? (
                                            <select
                                                style={{ width: "150px" }}
                                                id="a_c_i_sel"
                                                className="select_inp_filed"
                                                aria-label="Default select example"
                                                disabled
                                            >
                                                <option selected>{pendingcreditdata?.unitMeasurementName}</option>
                                            </select>
                                        ) : (
                                            <select
                                                style={{ width: "200px" }}
                                                id="a_c_i_sel"
                                                className="select_inp_filed"
                                                aria-label="Default select example"
                                            >
                                                <option selected>Select</option>
                                            </select>)}
                                        </td>
                                        <td><input id='' disabled className="search_inp_filed in_tb" value={pendingcreditdata?.invoiceUnitPrice} type="text" name="" placeholder=""></input></td>
                                        <td><input id='' disabled className="search_inp_filed in_tb" value={pendingcreditcalculatedata?.value} type="text" name="" placeholder=""></input></td>
                                    </tr>
                                </table>
                                <table className='inc_amt_table_tw'>
                                    <thead>
                                        <tr className='top_te_head'>
                                            <th colspan="2">CGST</th>
                                            <th colspan="2">SGST</th>
                                            <th colspan="2">IGST</th>
                                            <th colspan="2">Cess</th>
                                        </tr>
                                        <tr className='top_tw_head'>
                                            <th>Rate %</th>
                                            <th>Amount</th>
                                            <th>Rate %</th>
                                            <th>Amount</th>
                                            <th>Rate %</th>
                                            <th>Amount</th>
                                            <th>Rate %</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input id='' disabled className="search_inp_filed in_tb" value={pendingcreditcalculatedata?.cgstRate} type="text" name="" placeholder=""></input></td>
                                            <td><input id='' disabled className="search_inp_filed in_tb" value={pendingcreditcalculatedata?.cgstAmt} type="text" name="" placeholder=""></input></td>
                                            <td><input id='' disabled className="search_inp_filed in_tb" value={pendingcreditcalculatedata?.sgstRate} type="text" name="" placeholder=""></input></td>
                                            <td><input id='' disabled className="search_inp_filed in_tb" value={pendingcreditcalculatedata?.sgstAmt} type="text" name="" placeholder=""></input></td>
                                            <td><input id='' disabled className="search_inp_filed in_tb" value={pendingcreditcalculatedata?.igstRate} type="text" name="" placeholder=""></input></td>
                                            <td><input id='' disabled className="search_inp_filed in_tb" value={pendingcreditcalculatedata?.igstAmt} type="text" name="" placeholder=""></input></td>
                                            <td><input id='' disabled className="search_inp_filed in_tb" value={pendingcreditcalculatedata?.cessRate} type="text" name="" placeholder=""></input></td>
                                            <td><input id='' disabled className="search_inp_filed in_tb" value={pendingcreditcalculatedata?.cessAmt} type="text" name="" placeholder=""></input></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-lg-2 col-12 table_amt_ttl'>
                                <div>
                                    <p className='amt_wht'>Total Value</p>
                                    <p className='amt_dig'>{pendingcreditcalculatedata?.totalValue}</p>
                                </div>
                                <div>
                                    <p className='amt_wht'>TCS</p>
                                    <p className='amt_dig'>{pendingcreditcalculatedata?.tcsAmt}</p>
                                </div>
                                <div>
                                    <p className='amt_wht'>Total Invoice Value</p>
                                    <p className='amt_dig'>{pendingcreditcalculatedata?.totalInvoiceValue}</p>
                                </div>
                            </div>
                        </div>
                        <div className='amt_last_para'>
                            <p>Tax Is Payable On Reverse Charge</p>
                            <select
                                id=''
                                style={{ width: '60px' }}
                                className="select_inp_filed"
                                aria-label="Default select example" disabled>
                                <option selected>Select</option>
                                {
                                    pendingcreditdata?.reverseCharge == true ? (
                                        <option value="1" selected >Yes</option>
                                    ) : (
                                        <option value="2" selected >No</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className='amt_last_para_tw'>
                            <p >Total Invoice Value (In Figures) : <span><b>{pendingcreditcalculatedata?.totalInvoiceValue}</b></span></p>
                        </div>
                        <div className='amt_last_para_tr'>
                            <p >Total Invoice Value (In Words) : <span><b>{pendingcreditcalculatedata?.totalInvoiceValueInWords}</b></span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className='a_c_i_btm_btn'>
                    <button onClick={() => setpendingCreditShow(1)} className='grn_btn'>Go Back</button>
                </div>

                {
                    pendingcreditdata?.invoiceNoteStatus == 1 ? (
                        <div className=' top_hd_aci' style={{ display: 'flex', gap: '10px' }}>
                            <button onClick={approveinvoice} className='grn_btn'>Approve</button>
                            <button onClick={rejectinvoice} className='grn_btn'>Reject</button>
                            <button onClick={() => setpendingCreditShow(1)} className='diable_btn_edit'>Cancel</button>
                        </div>) : ''
                }
            </div>




            <input type="text" id='pendingapprovedcredit_successfully' hidden data-bs-toggle="modal" data-bs-target="#pendingapprovedcredit_successfully_mdl" />
            <input type="text" id='pendingrejectedcredit_successfully' hidden data-bs-toggle="modal" data-bs-target="#pendingrejectedcredit_successfully_mdl" />

            <div className="modal fade mdl_e_fade" id="pendingapprovedcredit_successfully_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Credit Note Approved Successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade mdl_e_fade" id="pendingrejectedcredit_successfully_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Credit Note Rejected Successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type="text" id='rejecte_credit' hidden data-bs-toggle="modal" data-bs-target="#rejected_credit" />
            <div className="modal fade mdl_e_fade" id="rejected_credit" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Are You Sure you Want To Reject the Invoice ?
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={() => handleRejectpendingcreditInvoice(pendingcreditdata?.invoiceIdentifier)} className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                            <button type="button" className="btn btn mdl_btn_cancel" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type="text" id='approve_credit' hidden data-bs-toggle="modal" data-bs-target="#approve_creditt" />
            <div className="modal fade mdl_e_fade" id="approve_creditt" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Are You Sure you Want To Approve the Invoice ?
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={() => handleApprovependingcreditInvoice(pendingcreditdata?.invoiceIdentifier, 2)} className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                            <button type="button" className="btn btn mdl_btn_cancel" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}


export default Credit_pending_view_data