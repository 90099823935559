import React, { useContext, useEffect } from 'react'
import All_invoice_main_table from '../All_invoice/All_invoice_main_table'
import All_create_invoice from '../All_invoice/All_create_invoice'
import Customer_master from '../All_invoice/Customer_master'
import All_invoice_view from '../All_invoice/All_invoice_view'
import Customer_master_cms from '../All_invoice/Customer_master_cms'
import { E_invoice_context } from '../../../../E_invoice_context'
import Non_CMS_Customer_Create from '../All_invoice/Non_CMS_Customer_Create'
import { useNavigate } from 'react-router-dom'

const Pending_approvel_new = () => {
  const { sessionEndTime, SetShow, setIsProfoma, invoiceAccess, noncmsbilladdress, itemdropdown, invoicing, setContractCustomer,
    Editboxaddressbill, setAllinvoicepagination, setPanValue, InvReqObj, setInvReqObj, show, Getstatecodeconfig,
    nonCMS, GetCustomer, Allinvoicepagination, decryptData, Getinvoicetype } = useContext(E_invoice_context)


  useEffect(() => {
    if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
      (sessionEndTime < new Date())) {
      document.getElementById('go_to_logintime_invoice_pending').click();
    }
    Getinvoicetype();
  }, [])

  const Navigate = useNavigate()

  const Logout = () => {
    sessionStorage.clear()
    Navigate('/');
  }

  var invoiceTypeDown = []
  var ViewTypeDown = []
  var MakerTypeDown = []

  if (invoicing && invoicing.length !== 0) {
    invoiceTypeDown = itemdropdown && itemdropdown
      .filter(item => invoicing.includes(item.invoiceTypeConfigurationIdentifier))
      .filter(item => invoiceAccess && invoiceAccess.some(rec => item.InvoiceTypeConfigurationAutoId === rec.InvoiceTypeConfigurationAutoId && (rec.makerFlag === true || rec.viewFlag === true)));

    ViewTypeDown = itemdropdown && itemdropdown
      .filter(item => invoicing.includes(item.invoiceTypeConfigurationIdentifier))
      .filter(item => invoiceAccess && invoiceAccess.some(rec => item.InvoiceTypeConfigurationAutoId === rec.InvoiceTypeConfigurationAutoId && (rec.viewFlag === true)));

    MakerTypeDown = itemdropdown && itemdropdown
      .filter(item => invoicing.includes(item.invoiceTypeConfigurationIdentifier))
      .filter(item => invoiceAccess && invoiceAccess.some(rec => item.InvoiceTypeConfigurationAutoId === rec.InvoiceTypeConfigurationAutoId && (rec.makerFlag === true)));
  }
  else {
    invoiceTypeDown = itemdropdown
    ViewTypeDown = itemdropdown
    MakerTypeDown = itemdropdown
  }

  useEffect(() => {
    setInvReqObj({
      ...InvReqObj,
      "search": "",
      "currentPage": 1,
      "pageSize": 10,
      "invoiceTypeIdentifier": "",
      "supplierIdentifier": "",
      "customerType": "",
      "customerIdentifier": "",
      "statusFlag": 0,
      "isMail": false,
      "isCreditNote": false,
      "isProforma": false,
      "sortField": "invoiceAutoId",
      "descFlag": true
    })
    SetShow(1)
    setAllinvoicepagination({ ...Allinvoicepagination, Current_page: Allinvoicepagination.Current_page = 1 })
    setPanValue('')
    setIsProfoma(false)
    setContractCustomer({})
  }, [])

  useEffect(() => {
    Getstatecodeconfig();
    GetCustomer(1, '');
    noncmsbilladdress();
  }, [nonCMS, Allinvoicepagination, Editboxaddressbill])

  return (

    <div >
      <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />
      <input type='button' hidden id='go_to_logintime_invoice_pending' data-bs-toggle="modal" data-bs-target="#go_to_login_invoice_pending" />

      <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_invoice_pending" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
          <div className="modal-content mdl_cont">
            <div className="modal-header mdl_header">
              <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
            </div>
            <div className="modal-body mdl_bdy">
              Something Went Wrong.Please Login Again
            </div>
            <div className="modal-footer">
              <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
          <div className="modal-content mdl_cont">
            <div className="modal-header mdl_header">
              <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
            </div>
            <div className="modal-body mdl_bdy">
              Something Went Wrong.Please Login Again
            </div>
            <div className="modal-footer">
              <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
            </div>
          </div>
        </div>
      </div>

      <div className=''>
        {
          show && show === 1 ? (<><div className='h4_btm_bdr'>
            {
              show && show === 1 ? ('') : (<i onClick={() => { SetShow(1); setPanValue(''); }} className="fa-solid fa-circle-arrow-left"></i>)
            }
            <h4>Invoices Pending Approval</h4>
          </div>
            <All_invoice_main_table isAll={false} isCredit={false} invoiceTypeDown={invoiceTypeDown} />
          </>
          ) : (
            <>
              {
                show === 2 ? (
                  <>
                    <div className='h4_btm_bdr'>
                      {
                        show && show === 1 ? ('') : (<i onClick={() => { SetShow(1); setPanValue(''); }} className="fa-solid fa-circle-arrow-left"></i>)
                      }
                      <h4>Invoices Pending Approval</h4>
                    </div>
                    <All_create_invoice MakerTypeDown={MakerTypeDown} /></>
                ) : (
                  <>
                    {
                      show === 3 ? (
                        <Customer_master />
                      ) : (
                        <>
                          {
                            show === 4 ? (
                              <>
                                <div className='h4_btm_bdr'>
                                  {
                                    show && show === 1 ? ('') : (<i onClick={() => { SetShow(1); setPanValue(''); }} className="fa-solid fa-circle-arrow-left"></i>)
                                  }
                                  <h4>Invoices Pending Approval</h4>
                                </div>
                                <All_invoice_view ViewTypeDown={ViewTypeDown} MakerTypeDown={MakerTypeDown} /></>
                            ) : (
                              <>
                                {
                                  show === 5 ? (
                                    <Non_CMS_Customer_Create />
                                  ) : (
                                    <>
                                      {
                                        show === 6 ? (
                                          <Customer_master_cms />
                                        ) : ('')
                                      }
                                    </>
                                  )
                                }
                              </>
                            )
                          }
                        </>
                      )
                    }
                  </>
                )
              }
            </>
          )
        }
      </div>
    </div>

  )
}

export default Pending_approvel_new