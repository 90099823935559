import React, { useContext, useEffect } from 'react'
import { E_invoice_context } from '../../../E_invoice_context'
import Create_user from './Create_user'
import User_view from './User_view'
import Pagination from '../Pagination'
import { useNavigate } from 'react-router-dom'

const User_master = () => {
    const { sessionEndTime, userPages, setUserPages, setGetRecords, GetRecords, UserRecords, handleUserView, UserhandlePageChange, GetUserRecords, CurrentUser,
        UserPaginations, UserpageNumbers, setUserView, UserView, Userresponsed, handleUserSearch, handleCreateNew, ProfomaTypes, InvoiceTypes, decryptData } = useContext(E_invoice_context)

    useEffect(() => {
        if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
            (sessionEndTime < new Date())) {
            document.getElementById('go_to_logintime_user').click();
        }
        setUserView({ ...UserView })
    }, [])

    const Navigate = useNavigate()

    const Logout = () => {
        sessionStorage.clear()
        Navigate('/');
    }

    useEffect(() => {
        if (CurrentUser)
            GetUserRecords();
    }, [UserPaginations])

    const userentersearch = (e) => {
        if (e.keyCode === 13) {
            handleUserSearch();
        }
    }

    return (
        <div>
            <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />
            <input type='button' hidden id='go_to_logintime_user' data-bs-toggle="modal" data-bs-target="#go_to_login_user" />

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Please Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Please Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="h4_btm_bdr"><h4>User Master</h4></div>
            {
                userPages === 1 &&
                <div className='total_outfit_fr_tab'>
                    <div className="create_btn_top">
                        <div>
                            <button onClick={handleCreateNew} className="grn_btn">Create User</button>
                        </div>
                        <div className='create_right_aligh'>
                            <input onKeyDown={userentersearch} className="search_inp_filed" type="text" name="" id="" value={GetRecords.search} onChange={(e) => setGetRecords({ ...GetRecords, search: e.target.value })} placeholder="Search"></input>
                            <i onClick={handleUserSearch} className="fa-solid fa-magnifying-glass search_icon"></i>
                        </div>
                    </div>
                    <table className='table_c__M'>
                        <thead>
                            <tr>
                                <th><span style={{ opacity: '0' }}>Hidden Text</span></th>
                                <th>Username</th>
                                <th>User Id</th>
                                <th>Department</th>
                            </tr>
                        </thead>
                        <tbody>
                            {UserRecords && Userresponsed ? UserRecords.map((user, ind) => (
                                <tr key={ind}>
                                    <td><button className='c_m_btn' onClick={() => handleUserView(user.identifier)}>View</button></td>
                                    <td>{user.userName}</td>
                                    <td>{user.emailId}</td>
                                    <td>{user.departmentName}</td>
                                </tr>
                            )) : <tr><td className='text-center' colSpan={4}>No Records Found..</td></tr>}
                        </tbody>
                    </table>

                    <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <div>
                            <p style={{ color: '#9a9a9a', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{Userresponsed}</span></p>
                        </div>
                        <div>
                            <Pagination
                                pageNumbers={UserpageNumbers}
                                Paginations={UserPaginations}
                                handlePageChange={UserhandlePageChange}
                                LastPage={Userresponsed && Math.ceil(Userresponsed / 10)}
                            />
                        </div>
                    </div>

                </div>
            }

            {
                userPages === 2 &&

                <Create_user
                    setUserPages={setUserPages}
                />
            }
            {
                userPages === 3 &&
                <User_view
                    setUserPages={setUserPages}
                />
            }

        </div>
    )
}

export default User_master