import React, { useContext, useEffect, useState } from 'react';
import { E_invoice_context } from '../E_invoice_context';
import AuthService from '../Service/Authservice';
import RedirectLoader from './RedirectLoader'; // Assuming you have a RedirectLoader component

const SSOPage = () => {
//   const { } = useContext(E_invoice_context);
  const [redirecting, setRedirecting] = useState(false);

  const GetRedirectURL = () => {
    AuthService.GetServiceCall('api/Login/SSOURL')
      .then((res) => {
        
        if (res && res !== undefined && res !== null) {
          setRedirecting(true);
          window.location.href = res.ssourl;
        }
      })
      .catch((err) => console.log('Error:', err));
  };

  useEffect(() => {
    sessionStorage.clear();
    GetRedirectURL();
  }, []);

  return (
    <div className='container frm_bg'>
      {redirecting ? (
        <RedirectLoader />
      ) : (
        <div className='container m-5 p-5 frm_bg'></div>
      )}
    </div>
  );
};

export default SSOPage;
