import React, { useContext } from 'react'
import Header from './Header/Header'
import Main_content from './Main_content/Main_content'
import { useState } from "react";
import { E_invoice_context } from './E_invoice_context';
import Page_not_found from './Main_content/Tables_content/Page_not_found';


const Home = () => {
    const { pagenotfound } = useContext(E_invoice_context)
    const [toggle, setToggle] = useState(true)

    return (
        <div className='container-fluid ps-0 pe-0'>
        {
          !pagenotfound && (
            <>
              <Header
                toggle={toggle}
                setToggle={setToggle}
              />
              <Main_content
                toggle={toggle}
              />
            </>
          )
        }
        {
          pagenotfound && (
            <Page_not_found/>  
          )
        }
      </div>
    );
}

export default Home