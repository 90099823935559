import React, { useContext, useEffect, useState } from 'react'
import AuthService from '../../../../Service/Authservice'
import View_table from './View_table'
import Supplier_main_table from './Supplier_main_table'
import Create_supply from '../Supplier_configration/Create_supply'
import { E_invoice_context } from '../../../../E_invoice_context'
import { useNavigate } from 'react-router-dom'

const Supplier_config = () => {

    const { sessionEndTime, Actionbutton, suplierstatecode, setsuplierstatecode, Getstatecodeconfig, CurrentUser,
        Getinseretstatecodeconfig, decryptData } = useContext(E_invoice_context)

    useEffect(() => {
        if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
            (sessionEndTime < new Date())) {
            document.getElementById('go_to_logintime').click();
        }
    }, [])

    const Navigate = useNavigate()
    const Logout = () => {
        sessionStorage.clear()
        Navigate('/');
    }


    const [data, setdata] = useState()
    const [view, setview] = useState({
        show: false, edit: false, create_sup: true, create_sup_err: false, create_sup_gst_err: false,
        testing: false, create_pan_err: false,
    })
    const [Search, Setserch] = useState("")
    const [Response, Setresponse] = useState()
    const [Statedropdown, SetStatedropdown] = useState()
    const [Pagination, SetPagination] = useState({ Current_page: 1 });
    const [Viewdata, SetViewdata] = useState()
    const [random, setrandom] = useState()

    const [suppliertypeeditview, setsuppliertypeeditview] = useState({ Edit: true, enable: false })


    const [Ascending, setAscending] = useState(true);
    const [columnName, setcolumnName] = useState();

    const handleSUpplierSortClick = (columnName) => {
        // console.log(Ascending);
        setAscending(!Ascending);
        setcolumnName(columnName)
        Getdata();

    }

    // const handleAscendingClick = () => {
    //     setAscending(true);
    //     setcolumnName('InvoiceTypeName')
    // }

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(Response && Response / 10); i++) {
        pageNumbers.push(i);
    }
    const handlePageChange = (pageNumber) => {
        SetPagination({ ...Pagination, Current_page: Pagination.Current_page = pageNumber });
    };
    const Empt_obj = {
        supplierTradeName: "",
        supplierLegalName: "",
        supplierPANNumber: "",
        supplierBuildingName: "",
        supplierBuildingNumber: "",
        supplierGSTIN: "",
        supplierPincode: "",
        supplierDistrict: "",
        supplierLocation: "",
        supplierPincode: "",
        supplierStateCode: "",
        supplierPhone: "",
        supplierEmail: "",
        supplierIdentifier: '',
        userMasterIdentifier: CurrentUser

    }


    const [intialst, setintialst] = useState(Empt_obj)

    const Getdata = (arg) => {
        const actualColumnName = columnName || 'SupplierConfigurationAutoId';

        AuthService.GetServiceCall(`api/Supplier/SupplierData?page=${Pagination.Current_page}&pageSize=${10}&sortField=${actualColumnName}&isAscending=${Ascending}&searchTerm=${Search}`)
            .then((res) => {
                setdata(res.responseData)
                // console.log(res.responseData)
                Setresponse(res.responseCount)
            }).catch((err) => {
                console.log("Error:", err);
               
            });
        Getstatecodeconfig();
    }

    useEffect(() => {
        Getdata(1);
    }, [Pagination, random])

    const viewApi = (SupplierIdentifier) => {

        AuthService.GetServiceCall(`api/Supplier/GetSupplier?SupplierIdentifier=${SupplierIdentifier}`)
            .then((res) => {
                // console.log(res)
                if (res.responseMessage == "Success") {
                    SetViewdata(res.responseData)
                }
            }).catch((err) => {
                console.log("Error:", err);
               
            });
    }

    const view_tab_change = (SupplierIdentifier) => {
        setview({ ...setview, create_sup_err: false })
        setview({ ...view, show: !view.show })
        viewApi(SupplierIdentifier);
    }



    useEffect(() => {
        if (Viewdata) {
            setintialst({
                supplierTradeName: Viewdata.supplierTradeName,
                supplierLegalName: Viewdata.supplierLegalName,
                supplierPANNumber: Viewdata.supplierPANNumber,
                supplierBuildingName: Viewdata.supplierBuildingName,
                supplierBuildingNumber: Viewdata.supplierBuildingNumber,
                supplierGSTIN: Viewdata.supplierGSTIN,
                supplierDistrict: Viewdata.supplierDistrict,
                supplierLocation: Viewdata.supplierLocation,
                supplierPincode: Viewdata.supplierPincode,
                supplierStateCode: Viewdata.supplierStateCode,
                supplierPhone: Viewdata.supplierPhone,
                supplierEmail: Viewdata.supplierEmail,
                supplierIdentifier: Viewdata.supplierIdentifier,
            })
            validatePAN(Viewdata.supplierPANNumber);
            validateGSTIN(Viewdata.supplierGSTIN);
        }
    }, [Viewdata])

    // console.log(Viewdata)
    const arrowview = () => {
        setview({ ...view, show: view.show = false })
        setview({ ...view, edit: view.edit = false })
        setsuppliertypeeditview({ ...suppliertypeeditview, Edit: true })

    }
    const create_supply = () => {
        setview({
            ...view, create_sup: view.create_sup = false, create_sup_err: view.create_sup_err = false,
            create_sup_gst_err: view.create_sup_gst_err = false, create_pan_err: view.create_pan_err = false
        })

        setintialst(Empt_obj)
    }
    const handle_view_change = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        setintialst({ ...intialst, [name]: value })
        if (name === 'supplierPANNumber') {
            //alert(name)
            validatePAN(value);
        } else if (name === 'supplierGSTIN') {
            //alert(name)
            validateGSTIN(value);
            checkForDuplicateGSTIN(value);
        }
    };
    // const handleKeyPress = (e) => {
    //     console.log(e);
    //     console.log(e.nativeEvent.which);
    //     if ((e.nativeEvent.which >= 48 && e.nativeEvent.which <= 57)) {
    //         alert('hi');
    //         return true;
    //     }
    //     else {
    //        // alert('hi');
    //         e.preventDefault(); // Prevent input of non-numeric values
    //     }
    // };

    const validatePAN = (panNumber) => {
        const panRegex = /^[A-Za-z]{5}\d{4}[A-Za-z]$/;
        const isValidPAN = panRegex.test(panNumber);
        // console.log(isValidPAN)
        setview((prevView) => ({ ...prevView, create_pan_err: isValidPAN }));
    };

    const validateGSTIN = (gstin) => {
        const gstinRegex = /^[0-9]{2}[A-Za-z]{5}\d{4}[A-Za-z]\d[A-Za-z\d]\w$/i;
        const isValidGSTIN = gstinRegex.test(gstin);
        // console.log(isValidGSTIN)
        setview((prevView) => ({ ...prevView, create_sup_gst_err: isValidGSTIN }));
    };


    const checkForDuplicateGSTIN = (value) => {
        // AuthService.PostServiceCall(`api/Supplier/CheckGSTIN?number=${value}`)
        //     .then((res) => {
        //         console.log(res);
        //         if (res.responseCode === 500) {
        //             setview((prevView) => ({ ...prevView, create_sup_gst_err: false }))
        //         }
        //     })
    }

    // const handle_edit_save = (supplierIdentifier) => {
    //     console.log(supplierIdentifier)
    //     setview({ ...view, create_sup_err: view.create_sup_err = false })
    //     if (Viewdata.supplierGSTIN !== '' || Viewdata.supplierPANNumber !== '') {
    //         //    alert('not empty')
    //         if (intialst.supplierTradeName === "" || intialst.supplierLegalName === "" || intialst.supplierPANNumber === "" || intialst.supplierGSTIN === ""
    //             || view.create_pan_err === false || view.create_sup_gst_err === false
    //         ) {
    //             setview({ ...view, create_sup_err: view.create_sup_err = true })
    //             // alert('somthing is empty')
    //         } else {
    //             AuthService.PostServiceCallToken(`api/Supplier/SupplierUpdate?SupplierIdentifier=${supplierIdentifier}`, intialst)
    //                 .then((res) => {
    //                     //alert('coming')
    //                     console.log(res)
    //                     document.getElementById('deleteAlert').click();
    //                     const Random_num = Math.floor(Math.random() * 9000) + 1000;
    //                     setrandom(Random_num)
    //                     console.log(Random_num)
    //                     setview({ ...view, show: view.show = false })
    //                     setview({ ...view, edit: view.edit = false })
    //                     setsuppliertypeeditview({ ...suppliertypeeditview, Edit: true })
    //                 })
    //         }

    //     } else {
    //         // alert('empty')
    //         if (intialst.supplierTradeName === "" || intialst.supplierLegalName === "") {

    //             setview({ ...view, create_sup_err: view.create_sup_err = true })
    //             // alert('somthing is empty')
    //         } else {
    //             AuthService.PostServiceCallToken(`api/Supplier/SupplierUpdate?SupplierIdentifier=${supplierIdentifier}`, intialst)
    //                 .then((res) => {
    //                     //alert('coming')
    //                     console.log(res)
    //                     document.getElementById('deleteAlert').click();
    //                     const Random_num = Math.floor(Math.random() * 9000) + 1000;
    //                     setrandom(Random_num)
    //                     console.log(Random_num)
    //                     setview({ ...view, show: view.show = false })
    //                     setview({ ...view, edit: view.edit = false })
    //                     setsuppliertypeeditview({ ...suppliertypeeditview, Edit: true })
    //                 })
    //         }
    //     }
    // }


    const handle_edit_save = (supplierIdentifier) => {
        setview({ ...view, create_sup_err: view.create_sup_err = false })
        if (intialst.supplierTradeName === "" || intialst.supplierLegalName === "" || view.create_pan_err === false || view.create_sup_gst_err === false) {
            setview({ ...view, create_sup_err: view.create_sup_err = true })
        } else {
            AuthService.PostServiceCallToken(`api/Supplier/SupplierUpdate?SupplierIdentifier=${supplierIdentifier}`, intialst)
                .then((res) => {
                    // console.log(res)
                    document.getElementById('deleteAlert').click();
                    const Random_num = Math.floor(Math.random() * 9000) + 1000;
                    setrandom(Random_num)
                    // console.log(Random_num)
                    setview({ ...view, show: view.show = false })
                    setview({ ...view, edit: view.edit = false })
                    setsuppliertypeeditview({ ...suppliertypeeditview, Edit: true })
                }).catch((err) => {
                    console.log("Error:", err);
                   
                });
        }
    }


    const Model_ready = () => {
        Getdata();
        setview({ ...view, show: view.show = false, edit: view.edit = false, create_sup: view.create_sup = true, create_sup_err: view.create_sup_err = false })
        setintialst(Empt_obj)
    }

    const create_cancel_btn = () => {
        setview({ ...view, create_sup: view.create_sup = true })
        setintialst(Empt_obj)
    }
    const Create_save_btn = (e) => {
        setview({ ...view, create_sup_err: view.create_sup_err = false })
        e.preventDefault();
        if (intialst.supplierTradeName === "" || intialst.supplierLegalName === "" || view.create_pan_err === false || view.create_sup_gst_err === false) {
            setview({ ...view, create_sup_err: view.create_sup_err = true })
        }
        else {
            // console.log(intialst)
            AuthService.PostServiceCall("api/Supplier/supplierInsert", intialst).then((response) => {
                // console.log(response, "insertdata")
                if (response.responseMessage === 'Success') {
                    document.getElementById('deleteAlert').click();
                } else if (response.responseMessage === "SupplierGSTIN already exists") {
                    document.getElementById('GSTINMODEL').click();
                } else {
                    // console.log('no')
                }
            }).catch((err) => {
                console.log("Error:", err);
               
            });
        }
    }
    const Delete_supply_view = () => {
        document.getElementById('succcesdelete').click();
    }
    const Delete_model_btn = (e) => {
        e.preventDefault();
        AuthService.PostServiceCall(`api/Supplier/ManagInvoice?SupplierIdentifier=${Viewdata ? Viewdata.supplierIdentifier : ''}&action=0`)
            .then((res) => {

                if (res.responseMessage === "Supplier is already used, cannot delete") {
                    document.getElementById('already_used').click();
                } else {
                    setview({ ...view, show: view.show = false })
                    setview({ ...view, edit: view.edit = false })
                    document.getElementById('succces_go_back').click();
                    Getdata();
                }

            }).catch((err) => {
                console.log("Error:", err);
                document.getElementById('go_to_logintime').click();
            });
    }
    const successfully_deleted = () => {
        setview({ ...view, show: view.show = false })
        Getdata();
        setview({ ...view, create_sup: view.create_sup = true })
    }
    const EnableSupplierConfiguration = () => {
        setsuppliertypeeditview({ ...suppliertypeeditview, enable: true })
        AuthService.PostServiceCall(`api/Supplier/ManagInvoice?SupplierIdentifier=${Viewdata ? Viewdata.supplierIdentifier : ''}&action=${Actionbutton ? Actionbutton.enable : 'enable'}`)
            .then((res) => {

                if (res.responseMessage === "Success") {
                    viewApi(Viewdata.supplierIdentifier)
                    document.getElementById('disablethis_successfully').click();
                }
            }).catch((err) => {
                console.log("Error:", err);
               
            });
    }
    const DisEnableSupplierConfiguration = () => {
        setsuppliertypeeditview({ ...suppliertypeeditview, enable: false })
        AuthService.PostServiceCall(`api/Supplier/ManagInvoice?SupplierIdentifier=${Viewdata ? Viewdata.supplierIdentifier : ''}&action=${Actionbutton ? Actionbutton.disable : 'disable'}`)
            .then((res) => {

                if (res.responseMessage === "Success") {
                    viewApi(Viewdata.supplierIdentifier)
                    document.getElementById('enablethis_successfully').click();
                }
            }).catch((err) => {
                console.log("Error:", err);
              
            });
    }

    return (
        <div>
            <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />
            <input type='button' hidden id='deleteAlert' data-bs-toggle="modal" data-bs-target="#SuccessModal" />
            <input type='button' hidden id='succcesdelete' data-bs-toggle="modal" data-bs-target="#deleteModal" />
            <input type='button' hidden id='succces_go_back' data-bs-toggle="modal" data-bs-target="#deleteModal_go_back" />

            <input type='button' hidden id='GSTINMODEL' data-bs-toggle="modal" data-bs-target="#GSTINMODEL_BS" />
            <h4>Supplier Configuration</h4>
            {!view.create_sup ? (
                <Create_supply
                    view={view}
                    setview={setview}
                    handle_view_change={handle_view_change}
                    // handleKeyPress = {handleKeyPress}
                    intialst={intialst}
                    create_cancel_btn={create_cancel_btn}
                    Create_save_btn={Create_save_btn}
                    Statedropdown={Statedropdown}
                    suplierstatecode={suplierstatecode}
                />
            ) : (
                <>
                    {!view.show ? (
                        <Supplier_main_table
                            create_supply={create_supply}
                            data={data}
                            view_tab_change={view_tab_change}
                            Getdata={Getdata}
                            pageNumbers={pageNumbers}
                            handlePageChange={handlePageChange}
                            Pagination={Pagination}
                            LastPage={Response && Math.ceil(Response / 10)}
                            handleSUpplierSortClick={handleSUpplierSortClick}
                            Ascending={Ascending}
                            SetPagination={SetPagination}
                            Setserch={Setserch}
                            Response={Response}
                            Search={Search}
                        />
                    ) : (
                        <View_table
                            arrowview={arrowview}
                            setview={setview}
                            view={view}
                            intialst={intialst}
                            handle_view_change={handle_view_change}
                            handle_edit_save={handle_edit_save}
                            Delete_supply_view={Delete_supply_view}
                            Statedropdown={Statedropdown}
                            EnableSupplierConfiguration={EnableSupplierConfiguration}
                            Viewdata={Viewdata}
                            suplierstatecode={suplierstatecode}
                            suppliertypeeditview={suppliertypeeditview}
                            setsuppliertypeeditview={setsuppliertypeeditview}
                            DisEnableSupplierConfiguration={DisEnableSupplierConfiguration}
                            viewApi={viewApi}
                        />
                    )}
                </>
            )}
            <div className="modal fade mdl_e_fade" tabIndex="-1" id="SuccessModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                        Record updated successfully
                        </div>
                        <div className="modal-footer">
                            <button onClick={Model_ready} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Please Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade mdl_e_fade" tabIndex="-1" id="GSTINMODEL_BS" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            GSTIN Number Already Exist
                        </div>
                        <div className="modal-footer">
                            <button type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <---Dlete Model Request---> */}

            <div className="modal fade mdl_e_fade" tabIndex="-1" id="deleteModal" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Are you sure you want to delete this?
                        </div>
                        <div className="modal-footer">
                            <button onClick={Delete_model_btn} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                            <button type="button" className="btn mdl_btn_cancel" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            {/* <---Dlete Model success---> */}

            <div className="modal fade mdl_e_fade" tabIndex="-1" id="deleteModal_go_back" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Record deleted successfully.
                        </div>
                        <div className="modal-footer">
                            <button onClick={successfully_deleted} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type='button' hidden id='go_to_logintime' data-bs-toggle="modal" data-bs-target="#go_to_login" />

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type='button' hidden id='already_used' data-bs-toggle="modal" data-bs-target="#already_used_model" />
            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="already_used_model" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                        Supplier already in use cannot be deleted.
                        </div>
                        <div className="modal-footer">
                            <button type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Supplier_config