import React, { useContext, useEffect } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'

const View_table = ({ arrowview, setview, view, intialst, handle_view_change, handle_edit_save, viewApi,
    Delete_supply_view, Statedropdown, EnableSupplierConfiguration, DisEnableSupplierConfiguration, Viewdata, suplierstatecode, suppliertypeeditview, setsuppliertypeeditview }) => {
    // const identifier = Viewdata.identifier
    // console.log(identifier)
    const { Actionbutton } = useContext(E_invoice_context)
  
    const suppliercancel = () => {
        setview({ ...view, show: view.show = false, edit: view.edit = false })
        setsuppliertypeeditview({ ...suppliertypeeditview, Edit: true })
    }


    const enabletxt = () => {
        document.getElementById('enablethis').click();
    }
    const disable = () => {
        document.getElementById('disablethis').click();
    }


    return (

        <div className='view_tab_cnt'>


            <input type="text" id='disablethis' hidden data-bs-toggle="modal" data-bs-target="#enablethismdl" />

            <div className="modal fade mdl_e_fade" id="enablethismdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                           Are you sure you want to disable this?
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={DisEnableSupplierConfiguration} className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                            <button type="button" className="btn mdl_btn_cancel" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type="text" id='enablethis_successfully' hidden data-bs-toggle="modal" data-bs-target="#enablethis_successfully_mdl" />

            <div className="modal fade mdl_e_fade" id="enablethis_successfully_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                        Supplier Configuration has been disabled successfully.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>



            <input type="text" id='enablethis' hidden data-bs-toggle="modal" data-bs-target="#disablethismdl" />

            <div className="modal fade mdl_e_fade" id="disablethismdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                        Are you sure you want to enable this?
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={EnableSupplierConfiguration} className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                            <button type="button" className="btn mdl_btn_cancel" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type="text" id='disablethis_successfully' hidden data-bs-toggle="modal" data-bs-target="#disablethis_successfully_mdl" />

            <div className="modal fade mdl_e_fade" id="disablethis_successfully_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                        Supplier Configuration has been enabled successfully.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>



            <div className='top_view_tab'>

                <div className='l_view_tab'>
                    <i onClick={arrowview} className="fa-solid fa-circle-arrow-left left_arrow"></i>
                    <p className='arrow_txt'>View Supplier</p>
                </div>

                <div>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '20px', width: '82%' }}>
                        {
                            suppliertypeeditview.Edit === true &&
                            (<button onClick={() => setsuppliertypeeditview({ ...suppliertypeeditview, Edit: false })} className="grn_btn">Edit</button>)
                        }
                        {/* {
                            suppliertypeeditview.enable === false ? (
                                <button onClick={enabletxt} className='diable_btn_edit'>{Actionbutton.disable}</button>
                            ) : (
                                <button onClick={disable} className='grn_btn'>{Actionbutton.enable}</button>
                            )
                        } */}
                        {
                            Viewdata && Viewdata.activeFlag === false ? (
                                <button onClick={enabletxt} className='grn_btn'>{Actionbutton.enable}</button>
                            ) : (
                                <button onClick={disable} className='diable_btn_edit'>{Actionbutton.disable}</button>
                            )
                        }
                        <button onClick={Delete_supply_view} className='diable_btn_edit'>{Actionbutton.delete}</button>
                    </div>

                </div>

            </div>
            <div className='view_tab_innr_cnt'>
                <div className='row'>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="supplierTradeName">Supplier Trade Name <span className="mandatory_star">*</span></label>
                            <input className='view_tab_inp' type="text" id='supplierTradeName' name='supplierTradeName' disabled={suppliertypeeditview.Edit} onChange={handle_view_change} value={intialst.supplierTradeName} />
                            <div className="invalid-feedback" style={view.create_sup_err === true && intialst.supplierTradeName === '' ? { display: 'block' } : { display: 'none' }}>
                                Please provide valid information for this field.
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="supplierLegalName">Supplier Legal Name <span className="mandatory_star">*</span></label>
                            <input className='view_tab_inp' type="text" id='supplierLegalName' name='supplierLegalName' disabled={suppliertypeeditview.Edit} onChange={handle_view_change} value={intialst.supplierLegalName} />
                            <div className="invalid-feedback" style={view.create_sup_err === true && intialst.supplierLegalName === '' ? { display: 'block' } : { display: 'none' }}>
                                Please provide valid information for this field.
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="supplierPANNumber">Supplier PAN Number <span className="mandatory_star">*</span></label>
                            <input className='view_tab_inp' type="text" id='supplierPANNumber' name='supplierPANNumber' disabled={suppliertypeeditview.Edit} onChange={handle_view_change} value={intialst.supplierPANNumber} />
                            {
                                view.create_sup_err === true &&

                                <div className="invalid-feedback" style={intialst.supplierPANNumber === '' || view.create_pan_err === false ? { display: 'block' } : { display: 'none' }}>
                                    Please provide valid information for this field.
                                </div>
                            }

                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="supplierGSTIN">Supplier GSTIN <span className="mandatory_star">*</span></label>
                            <input className='view_tab_inp' type="text" id='supplierGSTIN' name='supplierGSTIN' disabled={suppliertypeeditview.Edit} onChange={handle_view_change} value={intialst.supplierGSTIN} />
                            {
                                view.create_sup_err === true &&
                                
                                <div className="invalid-feedback" style={intialst.supplierGSTIN === '' || view && view.create_sup_gst_err === false ? { display: 'block' } : { display: 'none' }}>
                                    Please provide valid information for this field.
                                </div>
                            }

                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="exampleInputPassword1">Supplier Building Number </label>
                            <input className='view_tab_inp' type="text" name='supplierBuildingNumber' disabled={suppliertypeeditview.Edit} onChange={handle_view_change} value={intialst.supplierBuildingNumber} />
                            <div className="invalid-feedback">
                                Please provide valid information for this field.
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="exampleInputPassword1">Supplier Building Name </label>
                            <input className='view_tab_inp' type="text" name='supplierBuildingName' disabled={suppliertypeeditview.Edit} onChange={handle_view_change} value={intialst.supplierBuildingName} />
                            <div className="invalid-feedback">
                                Please provide valid information for this field.
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="exampleInputPassword1">Supplier Location</label>
                            <input className='view_tab_inp' type="text" name='supplierLocation' disabled={suppliertypeeditview.Edit} onChange={handle_view_change} value={intialst.supplierLocation} />
                            <div className="invalid-feedback">
                                Please provide valid information for this field.
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="exampleInputPassword1">Supplier District</label>
                            <input className='view_tab_inp' type="text" name='supplierDistrict' disabled={suppliertypeeditview.Edit} onChange={handle_view_change} value={intialst.supplierDistrict} />
                            <div className="invalid-feedback">
                                Please provide valid information for this field.
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="exampleInputPassword1">Supplier Pincode</label>
                            <input className='view_tab_inp' type="text" name='supplierPincode' disabled={suppliertypeeditview.Edit} onChange={handle_view_change} value={intialst.supplierPincode} />
                            <div className="invalid-feedback">
                                Please provide valid information for this field.
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="exampleInputPassword1">Supplier State Code</label>
                            <select className='view_tab_select' disabled={suppliertypeeditview.Edit} name='supplierStateCode' value={intialst.supplierStateCode} onChange={handle_view_change}>
                                <option value="">Select</option>
                                {suplierstatecode.map((item) => (
                                    <option key={item.userAutoID} value={item.stateCode}>
                                        {item.stateName}
                                    </option>
                                ))}
                            </select>
                            <div className="invalid-feedback">
                                Please provide valid information for this field.
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="exampleInputPassword1">Supplier Phone</label>
                            <input className='view_tab_inp' type="text" name='supplierPhone' disabled={suppliertypeeditview.Edit} onChange={handle_view_change} value={intialst.supplierPhone} />
                            <div className="invalid-feedback">
                                Please provide valid information for this field.
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="exampleInputPassword1">Supplier Email</label>
                            <input className='view_tab_inp' type="text" name='supplierEmail' disabled={suppliertypeeditview.Edit} onChange={handle_view_change} value={intialst.supplierEmail} />
                            <div className="invalid-feedback">
                                Please provide valid information for this field.
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            
            <div className='create_sup_btn' style={suppliertypeeditview.Edit === false ? { display: 'flex' } : { display: 'none' }}>
                <button onClick={() => handle_edit_save(Viewdata.supplierIdentifier)} className='con_btn_edit'>Save</button>
                {/* <button onClick={() => setShowModal(true)} className='grn_btn'>Submit</button> */}
                <button onClick={suppliercancel} className='diable_btn_edit'>Cancel</button>
            </div>
        </div >

        

    )
}

export default View_table