import React, { useContext, useState } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'

const Unit_measurement = ({ setunitshow, unitcancel }) => {
    const { unitmeasurevald, setunitmeasurevald, handleunitchanges, unitinput, handleunitinsertsave } = useContext(E_invoice_context)
    return (
        <div>


            <input type="text" id='user_master_model_1' hidden data-bs-toggle="modal" data-bs-target="#user_master_target_bs" />
          


            <div className="modal fade mdl_e_fade" id="user_master_target_bs" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                        Record updated successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>


           


            <div className='creat_c_m c_s_c' style={{ border: '1px solid #dfdfdf', paddingTop: '10px', marginTop: '0' }}>
                <i onClick={() => setunitshow(1)} className="fa-solid fa-circle-arrow-left"></i>
                <p style={{ marginBottom: '0', color: '#c95643', fontSize: '14px' }}>Create Units of Measure</p>
            </div>
            <div style={{ border: '1px solid #dfdfdf', padding: '10px' }}>
                <div className='row mt-3 mb-3'>
                    <div className='col-lg-4'>
                        <label htmlFor="" className='label_w_s'>Units of Measure Name<span className="mandatory_star2">*</span></label>
                        <input onChange={handleunitchanges} className="search_inp_filed in_tb" value={unitinput.unitMeasurementName} type="text" name="unitMeasurementName" id="" placeholder=""></input>
                        {
                            unitmeasurevald === false &&
                            <p style={{ marginBottom: 0, fontSize: '12px', color: "red" }}>{unitinput.unitMeasurementName === "" || unitinput.unitMeasurementName.length > 255 ? ('Please Provide valid Information & Cannot exceed 255 characters') : ('')}</p>
                        }
                    </div>
                    <div className='col-lg-4'>
                        <label htmlFor="" className='label_w_s'>Symbol<span className="mandatory_star2">*</span></label>
                        <input onChange={handleunitchanges} className="search_inp_filed in_tb" value={unitinput.unitMeasurementSymbol} type="text" name="unitMeasurementSymbol" id="" placeholder="Shortform KGS"></input>
                        {
                            unitmeasurevald === false &&
                            <p style={{ marginBottom: 0, fontSize: '12px', color: "red" }}>{unitinput.unitMeasurementSymbol === "" || unitinput.unitMeasurementSymbol.length > 255 ? ('Please Provide valid Information & Cannot exceed 255 characters') : ('')}</p>
                        }
                    </div>
                </div>
                <div className='p_T_c_btn'>
                    <button onClick={handleunitinsertsave} className="grn_btn">Save</button>
                    <button className="diable_btn_edit" onClick={unitcancel}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default Unit_measurement