import React from 'react'

const Supplier_main_table = ({ create_supply, data, view_tab_change, Search_input_filed,
    Setserch,Search, Getdata, pageNumbers, handlePageChange, LastPage, Pagination, SetPagination, Response, handleSUpplierSortClick }) => {

    const searchdata = () => {
        SetPagination({ ...Pagination, Current_page: Pagination.Current_page = 1 });
        Getdata();
    }

    const configsearchitem = (e) => {
        if (e.keyCode === 13) {
            searchdata();
        }
    }
    return (
        <div className='total_outfit_fr_tab'>
            <div className='table_top_btn'>
                <div className='create_btn'>
                    <button onClick={create_supply} className='grn_btn'>Create Supplier</button>
                </div>
                <div className='search_inp_btn'>
                    <input onKeyDown={configsearchitem} className='search_inp_filed' value={Search} onChange={(e) => Setserch(e.target.value)} type="text" name="" id="" placeholder='Search' />
                    <i onClick={searchdata} className="fa-solid fa-magnifying-glass search_icon"></i>
                </div>
            </div>
            <div>
                <table className='table table_grid_dw'>
                    <thead>
                        <tr>
                            <th></th>
                            <th onClick={() => handleSUpplierSortClick('SupplierTradeName')}>Supplier Trade Name</th>
                            <th onClick={() => handleSUpplierSortClick('SupplierLegalName')}>Supplier Legal Name</th>
                            <th onClick={() => handleSUpplierSortClick('SupplierGSTIN')}>Supplier GSTIN</th>
                            <th onClick={() => handleSUpplierSortClick('SupplierStateCode')}>Supplier State Code</th>
                        </tr>
                    </thead>
                    <tbody>{
                        data && data.length ? (
                            <>
                                {
                                    data && data.map((dat, SupplyDataID) => (
                                        <tr key={SupplyDataID}>
                                            <td ><button type="button" onClick={() => view_tab_change(dat.supplierIdentifier)} className="btn btn-primary grid_inner_btn btn_mar_left">View</button></td>
                                            <td>{dat.supplierTradeName}</td>
                                            <td>{dat.supplierLegalName}</td>
                                            <td>{dat.supplierGSTIN}</td>
                                            <td>{dat.supplierStateCode}</td>
                                        </tr>
                                    ))
                                }
                            </>
                        ) : (
                            <tr><td style={{textAlign:'center'}} colSpan={5}>There is no Records In This Field</td></tr>
                        )
                    }
                    </tbody>
                </table>
            </div>
            <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                <div>
                    <p style={{ color: '#9a9a9a', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{Response}</span></p>
                </div>
                <div className='pagination_div'>
                    <ul className="pagination">
                        <li className="page-item"><button disabled={Pagination.Current_page === 1} onClick={() => handlePageChange(1)} className="page-link"><i className="fa fa-angle-double-left"></i></button></li>
                        <li className="page-item"><button disabled={Pagination.Current_page === 1} onClick={() => handlePageChange(Pagination.Current_page - 1)} className="page-link"><i className="fa fa-angle-left"></i></button></li>
                        {pageNumbers.map((pageNumber) => (
                            <li key={pageNumber} className={pageNumber === Pagination.Current_page ? `page-item active` : 'page-item'}>
                                <button
                                    onClick={() => handlePageChange(pageNumber)}
                                    className="page-link"
                                >
                                    {pageNumber}
                                </button>
                            </li>
                        ))}
                        <li className="page-item"><button disabled={Pagination.Current_page === LastPage} onClick={() => handlePageChange(Pagination.Current_page + 1)} className="page-link"><i className="fa fa-angle-right"></i></button></li>
                        <li className="page-item"><button disabled={Pagination.Current_page === LastPage} onClick={() => handlePageChange(LastPage)} className="page-link"><i className="fa fa-angle-double-right"></i></button></li>
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default Supplier_main_table