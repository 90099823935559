import React, { useContext, useEffect, useState } from 'react'
import AuthService from '../../../../Service/Authservice'
import { useNavigate } from 'react-router-dom';
import { E_invoice_context } from '../../../../E_invoice_context';

const TCS = () => {

    const { sessionEndTime, decryptData } = useContext(E_invoice_context)

    useEffect(() => {
        if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
            (sessionEndTime < new Date())) {
            document.getElementById('go_to_logintime_TCS').click();
        }
    }, [])

    const Navigate = useNavigate()

    const Logout = () => {
        sessionStorage.clear()
        Navigate('/');
    }

    const empty_obj = {
        tcsrate: Number(''),
        tcsamount: Number('')
    }
    const err_obj = {
        rateerr: '',
        amounterr: ''
    }
    const [tcsvalue, settcsvalue] = useState(empty_obj)
    const [err, seterr] = useState(err_obj)


    const handle_view_change = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        settcsvalue({ ...tcsvalue, [name]: value })
    }

    useEffect(() => {
        AuthService.GetServiceCall(`api/Tcs/GetTCSConfiguration`)
            .then((res) => {
                // console.log(res.responseData)
                settcsvalue(prevTcsValue => ({
                    ...prevTcsValue,
                    tcsamount: res && res.responseData && res.responseData !== undefined && res.responseData.tcsAmount,
                    tcsrate: res && res.responseData && res.responseData !== undefined && res.responseData.tcsRate
                }));
            })
    }, [])

    const handletcssubmit = () => {
        seterr({ rate: '', amount: '' });

        if (document.getElementById('tcsrate').value === "") {
            seterr(prevErr => ({
                ...prevErr,
                rateerr: 'Please Provide valid Information'
            }));
        } if (document.getElementById('tcsamount').value === "") {
            seterr(prevErr => ({
                ...prevErr,
                amounterr: 'Please Provide valid Information'
            }));
        } else {
            AuthService.PostServiceCall('api/Tcs/UpdateTCSConfiguration', tcsvalue)
                .then((res) => {
                    // console.log(res)
                    if (res.responseMessage === "Success") {
                        document.getElementById('model_triger_tcs').click()
                    } else {
                        alert('not_updated')
                    }

                })
        }
    }
    return (
        <div>

            <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />

            <input type="text" id='model_triger_tcs' hidden data-bs-toggle="modal" data-bs-target="#model_one_tcs" />

            <div className="modal fade mdl_e_fade" id="model_one_tcs" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            TCS Updated Successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Please Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type='button' hidden id='go_to_logintime_TCS' data-bs-toggle="modal" data-bs-target="#go_to_login_TCS" />

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_TCS" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="h4_btm_bdr"><h4>TCS</h4></div>
            <div className='total_outfit_fr_tab'>
                <label htmlFor="" className='label_w_s'>TCS Rate (%)<span className="mandatory_star2">*</span></label>
                <input className="search_inp_filed " name='tcsrate' onChange={handle_view_change} type="number" id="tcsrate" value={tcsvalue.tcsrate} placeholder=""></input>
                <p style={{ color: 'red', fontSize: '12px' }}>{err.rateerr}</p>
                <label htmlFor="" className='label_w_s mt-5'>TCS Applicable Above Amount<span className="mandatory_star2">*</span></label>
                <input className="search_inp_filed " type="number" name="tcsamount" onChange={handle_view_change} id="tcsamount" value={tcsvalue.tcsamount} placeholder=""></input>
                <p style={{ color: 'red', fontSize: '12px' }}>{err.amounterr}</p>
                <div className='p_T_c_btn'>
                    <button className="grn_btn" onClick={handletcssubmit}>Submit</button>
                </div>
            </div>

        </div>
    )
}

export default TCS