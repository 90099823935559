import React, { useContext, useEffect } from 'react'
import { E_invoice_context } from '../../../E_invoice_context';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';



const Reports = () => {
    const { sessionEndTime, InvoiceTypesRecent, usemaster_config, isAdmin, proformaAccess, invoiceAccess, InvoiceDataMaster, enddate, startdate, handle_invoice_download_data,
        handleenddate, handlestartdate, handle_download_data, InvoiceFileData, Getinvoicetype, decryptData, itemdropdown } = useContext(E_invoice_context);

    useEffect(() => {
        if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
            (sessionEndTime < new Date())) {
            document.getElementById('go_to_logintime').click();
        }
        InvoiceTypesRecent();
        Getinvoicetype();
    }, [])

    const Navigate = useNavigate()

    const Logout = () => {
        sessionStorage.clear()
        Navigate('/');
    }

    // console.log(proformaAccess)
    // console.log(invoiceAccess)


    var uniqueList = [];
    var invoiceTypeDown = [];
    if (!isAdmin) {

        if (proformaAccess) {
            const mergedList = proformaAccess.concat(invoiceAccess);
            uniqueList = [...new Set(mergedList)];
        }
        else {
            uniqueList = invoiceAccess
        }


        if (uniqueList && uniqueList.length !== 0) {
            invoiceTypeDown = itemdropdown
                .filter(item => uniqueList.some(rec => item.InvoiceTypeConfigurationAutoId === rec.InvoiceTypeConfigurationAutoId && rec.reportsFlag === true));
        } else {
            invoiceTypeDown = itemdropdown;
        }

    }
    else {
        invoiceTypeDown = itemdropdown;
    }


    return (
        <div>
            <div className="h4_btm_bdr"><h4>Report</h4></div>
            <div className='create_itm'>
                <div className='row'>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">Invoice Type</label>
                        <select style={{ width: '100%' }} className="select_inp_filed" value={InvoiceDataMaster.invoiceTypeIdentifier} aria-label="Default select example" name='Invoice_id' onChange={handle_invoice_download_data} id='invoice_type'>
                            <option value="">Invoice type</option>
                            {
                                invoiceTypeDown && invoiceTypeDown.map((item, item_id) => (
                                    <option key={item_id} value={item.invoiceTypeConfigurationIdentifier}>{item.invoiceTypeName}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">From date</label>
                        {/* <input className="search_inp_filed in_tb" type="text" name="" placeholder=""></input> */}
                        <DatePicker
                            className="search_inp_filed in_tb"
                            selected={startdate}
                            onChange={handlestartdate}
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Select Start date"
                            id='startdate'
                            showTimeSelect={false}
                            //onChangeRaw = {handle_invoice_download_data}
                            name='startDate'
                            style={{ width: '100%' }}
                        />
                    </div>
                    <div className='col-lg-3 col-12'>
                        <label className="label_w_s" htmlFor="a_c_i_pan">To date</label>
                        {/* <input className="search_inp_filed in_tb" type="text" name="" placeholder=""></input> */}
                        <DatePicker
                            className="search_inp_filed in_tb"
                            selected={enddate}
                            onChange={handleenddate}
                            dateFormat="dd-MMM-yyyy"
                            placeholderText="Select End Date"
                            id='enddate'
                            showTimeSelect={false}
                            // onChangeRaw = {handle_invoice_download_data}
                            name='endDate'
                        />
                    </div>
                    <div className='col-lg-3 col-12'>
                        <i className="fa-solid fa-file-export exp_file" onClick={handle_download_data}></i>
                    </div>
                </div>
            </div>

            <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />

            <input type='button' hidden id='go_to_logintime_report' data-bs-toggle="modal" data-bs-target="#go_to_login_report" />

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Please Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Please Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type="text" id='no_records' hidden data-bs-toggle="modal" data-bs-target="#no_records_mdl" />

            <div className="modal fade mdl_e_fade" id="no_records_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            No Records Found
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Reports