import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import "./Login_form.css"
import AuthService from '../Service/Authservice'
import { E_invoice_context } from '../E_invoice_context';

const Login_form = () => {
    const { username, setusername, password, setpassword, Err, isAuthorized, handlesingin, Seterr, setisAuthorized } = useContext(E_invoice_context)
    var Head_img_frm = require('../Login_form/images/MBFSLogo_login.png');
    var Video_frm = require('../Login_form/images/login_bg_video.mp4')
    useEffect(() => {
        sessionStorage.clear()
        Seterr('')
    }, [])
    // console.log(isAuthorized)
    return (
        <div className='container-fluid frm_bg'>
            <div className='video_overlay'></div>
            <video className='Login_bg_video' autoPlay loop muted>
                <source src={Video_frm} type='video/mp4' />
            </video>
            <div className='form_start'>
                <img src={Head_img_frm} alt="" />
                <h2>e-invoicing</h2>
                <form onSubmit={handlesingin}>
                    <div className='user_inpu mb-4'>
                        <input type="text" required autoFocus className='inpt_login' onChange={(e) => setusername(e.target.value)} placeholder='username' name="" id="" />
                        <i className="fa-regular fa-user"></i>
                    </div>
                    <div className='user_inpu'>
                        <input type="Password" required autoFocus className='inpt_login' onChange={(e) => setpassword(e.target.value)} placeholder='password' name="" id="" />
                        <i className="fa-solid fa-key"></i>
                    </div>
                    <div className='frg_password'>
                        <p style={{ color: 'red', fontWeight: '700' }}>{isAuthorized === false ? (<i style={{ color: '#ffcc00', marginRight: '10px' }} className="fa-solid fa-triangle-exclamation"></i>) : ('')}{Err && Err}</p>
                        <p>Forget Password ?</p>
                    </div>
                    <div className='Login_btn'>
                        <button type='submit'>LOGIN</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Login_form