import React, { useContext, useEffect } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'
import Unit_measurement from './Unit_measurement'
import Pagination from '../../Pagination'
import Unit_measurement_edit from './Unit_measurement_edit'
import { useNavigate } from 'react-router-dom'

const Unit_measurement_main_table = () => {
    const { sessionEndTime, setunitshow, unitsshow, unitdata, setunitsearch, Getunitdata, unithandlePageChange, SetunitPaginations,unitsearch,
        unitpageNumbers, unitresponse, unitPaginations, handleunitinputedit, setunitinpt, unitinput, handleClick, Ascending, decryptData } = useContext(E_invoice_context)

    useEffect(() => {
        if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
            (sessionEndTime < new Date())) {
            document.getElementById('go_to_logintime_unit').click();
        }
    }, [])

    const Navigate = useNavigate()

    const Logout = () => {
        sessionStorage.clear()
        Navigate('/');
    }

    useEffect(() => {
        Getunitdata();
    }, [unitPaginations, Ascending])


    const create_unit = () => {
        setunitshow(2)
        if (setunitinpt && setunitinpt) {
            setunitinpt({ ...unitinput, unitMeasurementName: '', unitMeasurementSymbol: '', unitMeasurementConfigurationIdentifier: '' });
        } else {
            // console.log(setunitinpt)
            setunitinpt({ unitMeasurementName: '', unitMeasurementSymbol: '', unitMeasurementConfigurationIdentifier: '' });
            alert('no')
        }
    }

    const unitcancel = () => {
        setunitshow(1)
        setunitinpt({ ...unitinput, unitMeasurementName: '', unitMeasurementSymbol: '', unitMeasurementConfigurationIdentifier: '' });
    }

    const Searchunitbox = () => {
        SetunitPaginations({ ...unitPaginations, Current_page: unitPaginations.Current_page = 1 });
        Getunitdata();
    }

    const unitserachenter = (e) => {
        if (e.keyCode === 13) {
            Searchunitbox()
        }
    }

    return (
        <div>
  <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />
            <input type='button' hidden id='go_to_logintime_unit' data-bs-toggle="modal" data-bs-target="#go_to_login_unit" />

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_unit" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Please Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Please Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="h4_btm_bdr"><h4>Units of Measurement</h4></div>
            {unitsshow === 1 &&
                <div className='total_outfit_fr_tab'>
                    <div className="create_btn_top">
                        <div>
                            <button onClick={create_unit} className="grn_btn">Create Units Of Measure</button>
                        </div>
                        <div className='create_right_aligh'>
                            <input onKeyDown={unitserachenter} className="search_inp_filed" value={unitsearch} onChange={(e) => setunitsearch(e.target.value)} type="text" name="" id="" placeholder="Search"></input>
                            <i onClick={Searchunitbox} className="fa-solid fa-magnifying-glass search_icon"></i>
                        </div>
                    </div>
                    <table className='table_c__M'>
                        <thead>
                            <tr>
                                <th><span style={{ opacity: '0' }}>Hidden Text</span></th>
                                <th onClick={() => handleClick('UnitMeasurementName')}>Units of Measure Name</th>
                                <th onClick={() => handleClick('UnitMeasurementSymbol')}>Symbol</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                unitdata && unitdata.map((unit, iq) => (
                                    <tr key={iq}>
                                        <td><button onClick={() => handleunitinputedit(unit.unitMeasurementConfigurationIdentifier)} className='c_m_btn'>View</button></td>
                                        <td>{unit.unitMeasurementName}</td>
                                        <td>{unit.unitMeasurementSymbol}</td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <div>
                            <p style={{ color: '#9a9a9a', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{unitresponse}</span></p>
                        </div>
                        <div>
                            <Pagination
                                pageNumbers={unitpageNumbers}
                                Paginations={unitPaginations}
                                handlePageChange={unithandlePageChange}
                                LastPage={unitresponse && Math.ceil(unitresponse / 10)}
                            />
                        </div>
                    </div>
                </div>
            }
            {
                unitsshow === 2 &&

                <Unit_measurement
                    setunitshow={setunitshow}
                    unitcancel={unitcancel}
                />
            }

            {
                unitsshow === 3 &&

                <Unit_measurement_edit
                    unitcancel={unitcancel}
                />
            }

        </div>
    )
}

export default Unit_measurement_main_table