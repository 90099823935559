import React from 'react'

const Invoice_bulk = () => {
  return (
    <div>
      <div className="h4_btm_bdr">
        <h4>Invoice Bulk Upload</h4>
      </div>
      <div className='total_outfit_fr_tab'>
        <div className='row invoice_b_u'>
          <div className='col-lg-3 col-12'>
            <label className="label_w_s" for="a_c_i_pan">Invoice Type</label>
            <select className="select_inp_filed ful" aria-label="Default select example">
              <option selected>Invoice Type</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div className='col-lg-3 col-12'>
            <label className="label_w_s" for="a_c_i_pan">Item Master</label>
            <select className="select_inp_filed ful" aria-label="Default select example">
              <option selected>Select</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div className='col-lg-3 col-12'>
            <label className="label_w_s" for="a_c_i_pan">Unit of Measurement</label>
            <select className="select_inp_filed ful" aria-label="Default select example">
              <option selected>Select</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
          <div className='col-lg-3 col-12'>
            <label className="label_w_s" for="a_c_i_pan">Tax Is Payable On Reverse Charge</label>
            <select className="select_inp_filed ful" aria-label="Default select example">
              <option selected>No</option>
              <option value="1">One</option>
              <option value="2">Two</option>
              <option value="3">Three</option>
            </select>
          </div>
        </div>
        <div className='invoice_dwnlod'>
          <span>Download Template</span>
          <button className='grn_btn'>Upload List</button>
        </div>
        <div className='table_c_m invoi'>
          <table className='table_c__M'>
            <thead>
              <tr>
                <th>Contract Number</th>
                <th>Description</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>

              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}

export default Invoice_bulk