import React, { useContext, useEffect } from "react";
import { E_invoice_context } from "../../../../E_invoice_context";


const Credit_Approved_View = () => {

    const { setapprovalcreditviewdata, invoiceAccess, handlemailCheckMark, setapprovalcreditshow, isAdmin, handleDownloadCreditData, approvalcreditviewdata, approvalcreditgstflag, approvalcreditcalculatedata, approvalcreditinvoicedata } = useContext(E_invoice_context);
    const converter = require('number-to-words');
    const totalInvoiceValue = approvalcreditviewdata?.invoiceTotalValue || 0;
    const totalInvoiceWords = converter.toWords(totalInvoiceValue);
    const invoiceDate = new Date(approvalcreditviewdata?.invoiceDate);
    // const formattedInvoiceDate = invoiceDate.toLocaleDateString('en-GB', {
    //     day: '2-digit',
    //     month: 'short',
    //     year: 'numeric',
    // });
    var CheckerFlag = false;
    if (isAdmin) {
        CheckerFlag = true
    }
    else {
        if (invoiceAccess && invoiceAccess.length === 0) {
            CheckerFlag = true
        }
        else {
            CheckerFlag = invoiceAccess && invoiceAccess.filter(rec => (rec.InvoiceTypeConfigurationAutoId === approvalcreditinvoicedata && approvalcreditinvoicedata.InvoiceTypeConfigurationAutoId))[0] && invoiceAccess.filter(rec => (rec.InvoiceTypeConfigurationAutoId === approvalcreditinvoicedata && approvalcreditinvoicedata.InvoiceTypeConfigurationAutoId))[0].checkerFlag;
        }
    }




    return (
        <div>
            {approvalcreditviewdata?.invoiceNoteStatus == 2 ? (
                <div>
                    <div className="top_hd_aci mb-2 mt-1">
                        <button className='mx-2 grn_btn blue' onClick={() => { handleDownloadCreditData(approvalcreditviewdata?.invoiceIdentifier, "1") }}>Print</button>
                        <button className='mx-2 grn_btn blue' onClick={() => handlemailCheckMark(approvalcreditviewdata?.invoiceIdentifier)}>Send Email</button>
                        <button className='mx-2 grn_btn blue' onClick={() => { handleDownloadCreditData(approvalcreditviewdata?.invoiceIdentifier, "1") }}>Download</button>

                    </div>
                    <div className='credit_note_vi'>
                        <p>Supplier GST: {approvalcreditviewdata?.supplierGstin}</p>
                        <p>Invoice No: {approvalcreditviewdata?.invoiceSerialNumber}</p>
                        <p>Invoice Date: {approvalcreditviewdata?.invoiceDate}</p>
                        <p>Invoice Credit Note No: {approvalcreditviewdata?.invoiceNoteSerialNumber}</p>
                        <p>Invoice Credit Note Date: {approvalcreditviewdata?.invoiceNoteDate}</p>
                    </div>
                </div>
            ) : (
                <div></div>
            )}

            {/* <div className='total_outfit_fr_tab'>
                <div className='top_hd_aci'>
                    <p><b>Invoice No: </b> <span>{approvalcreditviewdata?.invoiceSerialNumber}</span></p>
                    <p><b>Invoice Date: </b><span>{approvalcreditviewdata?.invoiceDate}</span></p>
                </div>
            </div> */}
            <div className="accordion accordion-flush" id="accordionFlushExample">
                <div className="accordion-item acc_item">
                    <button className="accordion-button All_c_i_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                        <span>Supplier Details</span><i className="fa-solid fa-angle-down"></i>
                    </button>
                    <div id="flush-collapseOne" className="accordion-collapse collapse show" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body All_c_i_acc_bd">
                            <label className='label_w_s' htmlFor="a_c_i_sel">Invoice Type</label>
                            {approvalcreditviewdata?.invoiceType !== null || approvalcreditviewdata?.invoiceType !== "" ? (
                                <select
                                    style={{ width: "200px" }}
                                    id="a_c_i_sel"
                                    className="select_inp_filed"
                                    aria-label="Default select example"
                                    disabled
                                >
                                    <option selected>{approvalcreditviewdata?.invoiceType}</option>
                                </select>
                            ) : (
                                <select
                                    style={{ width: "200px" }}
                                    id="a_c_i_sel"
                                    className="select_inp_filed"
                                    aria-label="Default select example"
                                >
                                    <option selected>Select</option>
                                </select>)}
                        </div>
                        <div className='supplier_details'>
                            <h6>Supplier Details</h6>
                            <div className='row'>
                                <div className='col-lg-3 col-12'>
                                    <label htmlFor="">Supplier Name</label>
                                    <p>{approvalcreditviewdata?.invoiceType}</p>
                                </div>
                                <div className='col-lg-3 col-12'>
                                    <label htmlFor="">Supplier Address</label>
                                    <p>{approvalcreditviewdata?.supplierBuildingNumber},{approvalcreditviewdata?.supplierBuildingName} {approvalcreditviewdata?.supplierLocation} {approvalcreditviewdata?.supplierDistrict}-{approvalcreditviewdata?.supplierPincode}</p>
                                </div>
                                <div className='col-lg-3 col-12'>
                                    <label htmlFor="">Supplier Pan Number</label>
                                    <p>{approvalcreditviewdata?.supplierPan}</p>
                                </div>
                                <div className='col-lg-3 col-12'>
                                    <label htmlFor="">Supplier GST</label>
                                    <p>{approvalcreditviewdata?.supplierGstin}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="accordion-item acc_item">
                    <button className="accordion-button All_c_i_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                        <span>Customer Details</span><i className="fa-solid fa-angle-down"></i>
                    </button>
                    <div id="flush-collapseTwo" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body All_c_i_acc_bd">
                            <div className='row'>
                                <div className='col-lg-6 col-12 '>
                                    <label className='label_w_s' htmlFor="a_c_i_sel">Search Customer Details</label>
                                    <div className='c_d_sept'>
                                        <input id='' disabled className="search_inp_filed in_tb" value={approvalcreditviewdata?.customerMasterTradeName} type="text" name="" placeholder=""></input>
                                    </div>
                                </div>
                            </div>
                            <div className='row c_d_invoice'>
                                <div className='col-lg-5 col-12'>
                                    <span>Bill To Address</span>
                                    <div className='row c_d_inoice_on'>
                                        <div className='col-lg-5 col-12'>
                                            <label htmlFor="">Customer Legal Name</label>
                                            <p>{approvalcreditviewdata?.customerMasterTradeName}</p>

                                            <label htmlFor="">Customer Phone</label>
                                            <p>{approvalcreditviewdata?.customerPhone}</p>
                                        </div>
                                        <div className='col-lg-7 col-12'>
                                            <label htmlFor="">Customer GST</label>
                                            <p>{approvalcreditviewdata?.customerGst}</p>

                                            <label htmlFor="">Customer Email</label>
                                            <p>{approvalcreditviewdata?.customerEmail}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-5 col-12 ship_add_col'>
                                    <span>Ship To Address</span>
                                    <div className='row c_d_inoice_tw'>
                                        <div className='col-lg-6 col-12'>
                                            <label htmlFor="">GST</label>
                                            <p>{approvalcreditviewdata?.shipGst}</p>

                                            <label htmlFor="">Email</label>
                                            <p>{approvalcreditviewdata?.shipEmail}</p>
                                        </div>
                                        <div className='col-lg-6 col-12'>
                                            <label htmlFor="">Phone</label>
                                            <p>{approvalcreditviewdata?.shipPhone}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-2 col-12 c_d_inoice_tr'>
                                    <div>
                                        <label htmlFor="">{approvalcreditviewdata && approvalcreditviewdata.shipGst === null || approvalcreditviewdata && approvalcreditviewdata.shipGst === "" || approvalcreditviewdata && approvalcreditviewdata.shipGst === undefined ? "B2C" : "B2B"}</label>
                                        <p>{approvalcreditviewdata && approvalcreditviewdata.shipGst === null || approvalcreditviewdata && approvalcreditviewdata.shipGst === "" || approvalcreditviewdata && approvalcreditviewdata.shipGst === undefined ? "UnRegisterd" : "Registerd"}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    approvalcreditinvoicedata && approvalcreditinvoicedata[0].invRegUnreg && (
                        <div className="accordion accordion-flush" id="accordionFlushExample_3_0n">
                            <div className="accordion-item acc_item">
                                <button className="accordion-button All_c_i_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapsefour_on" aria-expanded="false" aria-controls="flush-collapseTwo">
                                    <span>Customer Contract Details</span><i className="fa-solid fa-angle-down"></i>
                                </button>
                                <div id="collapsefour_on" className="accordion-collapse collapse show" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample_3_0n">
                                    <div className="accordion-body All_c_i_acc_bd">
                                        <div className='customer_conract'>
                                            <div className='customer_conract_1'>
                                                <div className=''>
                                                    <button className='grn_btn' disabled>Customer Contract</button>
                                                </div>
                                                <div className='' style={{ display: 'flex', gap: '10px' }}>
                                                    <input className='search_inp_filed' type="text" name="" id="" placeholder='Enter Contract No.' disabled />
                                                    <i className="fa-solid fa-magnifying-glass search_icon" disabled></i>
                                                </div>
                                                <div className=''>
                                                    <label htmlFor="">Customer Name</label>
                                                    <p>{approvalcreditviewdata?.remarketingCustomerName}</p>
                                                </div>
                                                <div className=''>
                                                    <label htmlFor="">CMS Contract Number</label>
                                                    <p>{approvalcreditviewdata?.remarketingCustomerId}</p>
                                                </div>
                                                <div className=''>
                                                    <label htmlFor="">GST NO.</label>
                                                    <p>{approvalcreditviewdata?.remarketingCustomerGst}</p>
                                                </div>
                                            </div>
                                            <div className='customer_conract_1 on'>

                                                <div className=''>
                                                    <label htmlFor="">VIN NO.</label>
                                                    <p>{approvalcreditviewdata?.reMarkVinno}</p>
                                                </div>
                                                <div className='' style={{ display: 'flex', justifyContent: 'start', gap: "20px" }}>
                                                    <div>
                                                        <label htmlFor="">Registration  No.</label>
                                                        <p>{approvalcreditviewdata?.vehicleNumber}</p>
                                                    </div>
                                                    <div>
                                                        <label htmlFor="">Model</label>
                                                        <p>{approvalcreditviewdata?.remarkModel}</p>
                                                    </div>
                                                </div>
                                                <div className=''>
                                                    <label htmlFor="">PAN Number</label>
                                                    <p>{approvalcreditviewdata?.reMarkPanNumber}</p>
                                                </div>
                                                <div className=''>
                                                    <label htmlFor="">Start Date</label>
                                                    <p>{approvalcreditviewdata?.reMarkContractStartDate}</p>
                                                </div>
                                                <div className=''>
                                                    <label htmlFor="">Total Cost</label>
                                                    <p>{approvalcreditviewdata?.reMarkTotalCostAmount}</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='customer_contact_blr'>
                                            <div className='cus_cont_top'>
                                                <div className='custom_cont_div'>
                                                    <label htmlFor="">Sales Amount</label>
                                                    <input className='search_inp_filed' type="text" name="" id="" value={approvalcreditviewdata?.remarkSalesAmount} disabled />
                                                </div>
                                                <div className='custom_cont_div'>
                                                    <input type="radio" name="gst" placeholder='10000' checked={approvalcreditgstflag === 1} id="" disabled />
                                                    <label htmlFor="">GST Registered</label>
                                                </div>
                                                <div className='custom_cont_div'>
                                                    <input type="radio" name="gst" placeholder='10000' id="" checked={approvalcreditgstflag === 2} disabled />
                                                    <label htmlFor="">GST Unregistered</label>
                                                </div>
                                            </div>
                                            {approvalcreditgstflag === 2 && (<div className='cus_cont_top tw'>
                                                <div className='custom_cont_div_2'>
                                                    <label htmlFor="">Number of Quaters</label>
                                                    <p>{approvalcreditcalculatedata?.noOfQuaters}</p>
                                                </div>
                                                <div className='custom_cont_div_2'>
                                                    <label htmlFor="">Depreciation per quater</label>
                                                    <p>{approvalcreditcalculatedata?.dep_per_Qua}</p>
                                                </div>
                                                <div className='custom_cont_div_2'>
                                                    <label htmlFor="">Total Depreciation</label>
                                                    <p>{approvalcreditcalculatedata?.totalDep}</p>
                                                </div>
                                                <div className='custom_cont_div_2'>
                                                    <label htmlFor="">Assessed Amount</label>
                                                    <p>{approvalcreditcalculatedata?.assessed_Amount}</p>
                                                </div>
                                            </div>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)}

                <div className="accordion-item acc_item">
                    <button className="accordion-button All_c_i_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                        <span>Invoice Details</span><i className="fa-solid fa-angle-down"></i>
                    </button>
                    <div id="flush-collapseThree" className="accordion-collapse collapse show" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                        <div className="accordion-body All_c_i_acc_bd">
                            <div className='row rw_all_ci_bd'>
                                <div className='col-lg-3 col-12'>
                                    <p className='i_d_head'>Invoice Item</p>
                                    {approvalcreditviewdata?.invoiceItemName !== null || approvalcreditviewdata?.invoiceItemName !== "" ? (
                                        <select
                                            style={{ width: "200px" }}
                                            id="a_c_i_sel"
                                            className="select_inp_filed"
                                            aria-label="Default select example"
                                            disabled
                                        >
                                            <option value={approvalcreditviewdata?.invoiceItemMasterIdentifier} selected>{approvalcreditviewdata?.invoiceItemName}</option>
                                        </select>
                                    ) : (
                                        <select
                                            style={{ width: "200px" }}
                                            id="a_c_i_sel"
                                            className="select_inp_filed"
                                            aria-label="Default select example"
                                        >
                                            <option selected>Select</option>
                                        </select>)}
                                </div>
                                <div className='col-lg-9 col-12'>
                                    <p className='i_d_head'>Description of Goods / Service</p>
                                    <textarea disabled className='input_txt_are' value={approvalcreditviewdata?.invoiceItemDesctiption}></textarea>
                                </div>
                            </div>
                            <div className='row rw_all_ci_tw'>
                                {/* <div className='col-lg-3 col-12 amt_serch'>
                                    <label htmlFor="amount">Amount</label>
                                    <input style={{ width: '90px' }} id='amount' className="search_inp_filed in_tb" type="text" name="" placeholder="" value={approvalcreditviewdata?.amountInput} disabled></input>
                                </div> */}
                                {/* <div className='row rw_all_ci_tw'> */}
                                <div className='col-lg-3 col-12 amt_serch'>
                                    <label htmlFor="amount">Amount</label>
                                    <input
                                        style={{ width: '90px' }}
                                        id='amount'
                                        className="search_inp_filed in_tb"
                                        type="text"
                                        name=""
                                        placeholder=""
                                        value={approvalcreditviewdata?.amountInput}
                                        disabled
                                    />
                                </div>
                                <div className='col-lg-9 col-12 amt_radio'>
                                    <div>
                                        <input
                                            disabled
                                            id='tax_val'
                                            className="radio_all_c"
                                            type="radio"
                                            name=""
                                            placeholder=""
                                            checked={approvalcreditviewdata?.inputType === 1}
                                        />
                                        <label htmlFor="tax_val">Taxable Value</label>
                                    </div>
                                    <div>
                                        <input
                                            disabled
                                            id='amt_val'
                                            className="radio_all_c"
                                            type="radio"
                                            name=""
                                            placeholder=""
                                            checked={approvalcreditviewdata?.inputType === 3}
                                        />
                                        <label htmlFor="amt_val">Amount(Incl. GST & TCS)</label>
                                    </div>
                                    <div>
                                        <input
                                            disabled
                                            id='amt_inc'
                                            className="radio_all_c"
                                            type="radio"
                                            name=""
                                            placeholder=""
                                            checked={approvalcreditviewdata?.inputType === 2}
                                        />
                                        <label htmlFor="amt_inc">Amount(Incl. GST & No TCS)</label>
                                    </div>
                                    <div>
                                        <input
                                            disabled
                                            id='amt_exc'
                                            className="radio_all_c"
                                            type="radio"
                                            name=""
                                            placeholder=""
                                            checked={approvalcreditviewdata?.inputType === 4}
                                        />
                                        <label htmlFor="amt_exc">Amount(Incl. GST & Excl. TCS)</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row rw_all_ci_tr'>
                            <div className='col-lg-10 col-12'>
                                <table className='inc_amt_table'>
                                    <tr>
                                        <th>HSN/SAC <br /> Code</th>
                                        <th>Taxable Value <br /> (INR)</th>
                                        <th>Unit of <br /> Measurement</th>
                                        <th>Unit <br /> Price</th>
                                        <th>Value <br /> (INR)</th>
                                    </tr>
                                    <tr>
                                        <td><input id='' disabled className="search_inp_filed in_tb" value={approvalcreditviewdata?.invoiceHsncode} type="text" name="" placeholder=""></input></td>
                                        <td><input id='' disabled className="search_inp_filed in_tb" value={approvalcreditcalculatedata?.taxableValue} type="text" name="" placeholder=""></input></td>
                                        <td> {approvalcreditviewdata?.unitMeasurementName !== null || approvalcreditviewdata?.unitMeasurementName !== "" ? (
                                            <select
                                                style={{ width: "150px" }}
                                                id="a_c_i_sel"
                                                className="select_inp_filed"
                                                aria-label="Default select example"
                                                disabled
                                            >
                                                <option selected>{approvalcreditviewdata?.unitMeasurementName}</option>
                                            </select>
                                        ) : (
                                            <select
                                                style={{ width: "200px" }}
                                                id="a_c_i_sel"
                                                className="select_inp_filed"
                                                aria-label="Default select example"
                                            >
                                                <option selected>Select</option>
                                            </select>)}
                                        </td>
                                        <td><input id='' disabled className="search_inp_filed in_tb" value={approvalcreditviewdata?.invoiceUnitPrice} type="text" name="" placeholder=""></input></td>
                                        <td><input id='' disabled className="search_inp_filed in_tb" value={approvalcreditcalculatedata?.value} type="text" name="" placeholder=""></input></td>
                                    </tr>
                                </table>
                                <table className='inc_amt_table_tw'>
                                    <thead>
                                        <tr className='top_te_head'>
                                            <th colspan="2">CGST</th>
                                            <th colspan="2">SGST</th>
                                            <th colspan="2">IGST</th>
                                            <th colspan="2">Cess</th>
                                        </tr>
                                        <tr className='top_tw_head'>
                                            <th>Rate %</th>
                                            <th>Amount</th>
                                            <th>Rate %</th>
                                            <th>Amount</th>
                                            <th>Rate %</th>
                                            <th>Amount</th>
                                            <th>Rate %</th>
                                            <th>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td><input id='' disabled className="search_inp_filed in_tb" value={approvalcreditcalculatedata?.cgstRate} type="text" name="" placeholder=""></input></td>
                                            <td><input id='' disabled className="search_inp_filed in_tb" value={approvalcreditcalculatedata?.cgstAmt} type="text" name="" placeholder=""></input></td>
                                            <td><input id='' disabled className="search_inp_filed in_tb" value={approvalcreditcalculatedata?.sgstRate} type="text" name="" placeholder=""></input></td>
                                            <td><input id='' disabled className="search_inp_filed in_tb" value={approvalcreditcalculatedata?.sgstAmt} type="text" name="" placeholder=""></input></td>
                                            <td><input id='' disabled className="search_inp_filed in_tb" value={approvalcreditcalculatedata?.igstRate} type="text" name="" placeholder=""></input></td>
                                            <td><input id='' disabled className="search_inp_filed in_tb" value={approvalcreditcalculatedata?.igstAmt} type="text" name="" placeholder=""></input></td>
                                            <td><input id='' disabled className="search_inp_filed in_tb" value={approvalcreditcalculatedata?.cessRate} type="text" name="" placeholder=""></input></td>
                                            <td><input id='' disabled className="search_inp_filed in_tb" value={approvalcreditcalculatedata?.cessAmt} type="text" name="" placeholder=""></input></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className='col-lg-2 col-12 table_amt_ttl'>
                                <div>
                                    <p className='amt_wht'>Total Value</p>
                                    <p className='amt_dig'>{approvalcreditcalculatedata?.totalValue}</p>
                                </div>
                                <div>
                                    <p className='amt_wht'>TCS</p>
                                    <p className='amt_dig'>{approvalcreditcalculatedata?.tcsAmt}</p>
                                </div>
                                <div>
                                    <p className='amt_wht'>Total Invoice Value</p>
                                    <p className='amt_dig'>{approvalcreditcalculatedata?.totalInvoiceValue}</p>
                                </div>
                            </div>
                        </div>
                        <div className='amt_last_para'>
                            <p>Tax Is Payable On Reverse Charge</p>
                            <select
                                id=''
                                style={{ width: '60px' }}
                                className="select_inp_filed"
                                aria-label="Default select example" disabled>
                                <option selected>Select</option>
                                {
                                    approvalcreditviewdata?.reverseCharge == true ? (
                                        <option value="1" selected >Yes</option>
                                    ) : (
                                        <option value="2" selected >No</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className='amt_last_para_tw'>
                            <p >Total Invoice Value (In Figures) : <span><b>{approvalcreditcalculatedata?.totalInvoiceValue}</b></span></p>
                        </div>
                        <div className='amt_last_para_tr'>
                            <p >Total Invoice Value (In Words) : <span><b>{approvalcreditcalculatedata?.totalInvoiceValueInWords}</b></span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <button className="grn_btn" onClick={() => setapprovalcreditshow(1)}>Go Back</button>
            </div>
        </div>
    )
}


export default Credit_Approved_View