import React, { useContext, useEffect, useState } from 'react'
import Create_state_code from './Create_state_code'
import { E_invoice_context } from '../../../../E_invoice_context'
import AuthService from '../../../../Service/Authservice'
import Pagination from '../../Pagination'
import Create_state_code_view from './Create_state_code_view'
import { useNavigate } from 'react-router-dom'

const State_code_config = () => {
    const { sessionEndTime, GetStateconfigdata, setstatenamecode, handle_config_search, staterespone, Stateconfigdata, setstateconfig, stateconfig, Ascending, SetStatePaginations,
        StatePaginations, StatepageNumbers, StatehandlePageChange, statenamecode, handleClick, CurrentUser, setstatesearch, decryptData, statesearch } = useContext(E_invoice_context)

    useEffect(() => {
        if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
            (sessionEndTime < new Date())) {
            document.getElementById('go_to_logintime_STATE').click();
        }
    }, [])

    const Navigate = useNavigate()

    const Logout = () => {
        sessionStorage.clear()
        Navigate('/');
    }


    const [stateviewdata, setstateviewdata] = useState({ stateCode: '', stateName: '', stateIdentifier: '' })


    useEffect(() => {
        if (CurrentUser)
            GetStateconfigdata();
    }, [StatePaginations, Ascending])

    const searchstatecode = () => {
        GetStateconfigdata();
        SetStatePaginations({ ...StatePaginations, Current_page: StatePaginations.Current_page = 1 });
    }

    const handel_state_edit_view = (id) => {
        setstateconfig(3)
        AuthService.GetServiceCall(`api/StateCodes/GetStatecode?StateIdentifier=${id}`)
            .then((res) => {
                // console.log(res)
                setstateviewdata({ ...stateviewdata, stateName: res.responseData[0].stateName, stateCode: res.responseData[0].stateCode, stateIdentifier: res.responseData[0].stateIdentifier })
            })
    }

    useEffect(() => {
        // console.log(stateviewdata)
        setstatenamecode({
            ...statenamecode,
            stateName: stateviewdata.stateName,
            stateCode: stateviewdata.stateCode,

        })
    }, [stateviewdata])

    const creatbtn = () => {
        setstateconfig(2)
        setstatenamecode({
            ...statenamecode,
            stateName: '',
            stateCode: '',
            userMasterIdentifier: CurrentUser
        })
    }

    const Searchstateenter = (e) => {
        if (e.keyCode === 13) {
            searchstatecode();
            SetStatePaginations({ ...StatePaginations, Current_page: StatePaginations.Current_page = 1 });
        }
    }

    return (
        <div>

            <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />

            <input type="text" id='model_triger_tw' hidden data-bs-toggle="modal" data-bs-target="#model_two" />
            <input type="text" id='model_triger_three' hidden data-bs-toggle="modal" data-bs-target="#model_two_ins" />

            <div className="modal fade mdl_e_fade" id="model_two" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            State code already exists.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Please Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade mdl_e_fade" id="model_two_ins" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                        Record updated successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type="text" id='model_triger_four' hidden data-bs-toggle="modal" data-bs-target="#model_two_update" />
            <div className="modal fade mdl_e_fade" id="model_two_update" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Record updated SuccessFully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type='button' hidden id='go_to_logintime_STATE' data-bs-toggle="modal" data-bs-target="#go_to_login_STATE" />

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_STATE" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="h4_btm_bdr"><h4>State Code Configuration</h4></div>

            {stateconfig === 1 &&

                <div>
                    <div className='total_outfit_fr_tab'>
                        <div className='over_all_div'>
                            <div className="create_btn_top">
                                <div>
                                    <button onClick={creatbtn} className="grn_btn">State Code Configuration</button>
                                </div>
                                <div className='create_right_aligh'>
                                    <input onKeyDown={Searchstateenter} value={statesearch} onChange={(e) => setstatesearch(e.target.value)} className="search_inp_filed" type="text" name="" id="" placeholder="Search"></input>
                                    <i onClick={searchstatecode} className="fa-solid fa-magnifying-glass search_icon"></i>
                                </div>
                            </div>
                        </div>
                        <table className='table_c__M'>
                            <thead>
                                <tr>
                                    <th><span style={{ opacity: '0' }}>HideText</span></th>
                                    <th onClick={() => handleClick('StateName')}>State Name</th>
                                    <th onClick={() => handleClick('StateCode')}>State Code</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    Stateconfigdata && Stateconfigdata.map((Sc, uq) => (
                                        <tr key={uq}>
                                            <td><button onClick={() => handel_state_edit_view(Sc.stateIdentifier)} className='c_m_btn'>View</button></td>
                                            <td>{Sc.stateName}</td>
                                            <td>{Sc.stateCode}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                    <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <div>
                            <p style={{ color: '#9a9a9a', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{staterespone}</span></p>
                        </div>
                        <div>
                            <Pagination
                                pageNumbers={StatepageNumbers}
                                Paginations={StatePaginations}
                                handlePageChange={StatehandlePageChange}
                                LastPage={staterespone && Math.ceil(staterespone / 10)}
                            />
                        </div>
                    </div>
                </div>
            }

            {
                stateconfig === 2 &&
                <Create_state_code
                    setstateconfig={setstateconfig}
                    GetStateconfigdata={GetStateconfigdata}
                />
            }

            {
                stateconfig === 3 &&
                <Create_state_code_view
                    setstateconfig={setstateconfig}
                    GetStateconfigdata={GetStateconfigdata}
                    stateconfig={stateconfig}
                    stateviewdata={stateviewdata}
                />
            }

        </div>
    )
}

export default State_code_config