import React, { useContext, useEffect } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'
import Credit_view_data from './All_credit_view'
import Pagination from '../../Pagination'
import All_credit_note_table from '../../Credit_note/All_credit_note/All_credit_note_table'
import { useNavigate } from 'react-router-dom'

const All_credit_note = () => {


  const { sessionEndTime, setCreditShow, creditviewdata, creditresponsedata, invoicing, invoiceAccess, creditsearchvalue, handlecreditAllInvoiceSearch,
    creditshow, getcreditInvoicedata, creditinvoicerecord, setcreditinvoicerecord, itemdropdown, invoiceviewdropdown, setinvoicedropdownvalue,
    setcreditsupplierDropdown, setcreditinvoicetypedropdown, setcreditsearchvalue, itemresponse, credithandlePageChange, creditPagination, setCreditPagination,
    creditpagenumbers, handlecredit_enable_value, decryptData } = useContext(E_invoice_context)

  useEffect(() => {
    if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
      (sessionEndTime < new Date())) {
      document.getElementById('go_to_logintime_creadit_note').click();
    }
  }, [])

  const Navigate = useNavigate()

  const Logout = () => {
    sessionStorage.clear()
    Navigate('/');
  }


  var invoiceTypeDown = []

  if (invoicing && invoicing.length !== 0) {
    invoiceTypeDown = itemdropdown && itemdropdown
      .filter(item => invoicing.includes(item.invoiceTypeConfigurationIdentifier))
      .filter(item => invoiceAccess && invoiceAccess.some(rec => (item.invoiceTypeConfigurationAutoId === rec.invoiceTypeConfigurationAutoId) && (rec.makerFlag === true || rec.viewFlag === true)));
  }
  else {
    invoiceTypeDown = itemdropdown
  }

  useEffect(() => {
    getcreditInvoicedata();
  }, [creditinvoicerecord, setcreditinvoicerecord, creditPagination]);
  const invoiceDate = new Date(creditviewdata?.invoiceDate);
  const formattedInvoiceDate = invoiceDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });



  const credit_invoice_search = (e) => {
    if (e.keyCode === 13) {
      handlecreditAllInvoiceSearch();
    }
  }
  const credit_invoice_search_no = (e) => {
    setcreditsearchvalue(e.target.value)
  }



  return (
    <div>
      <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />
      <input type='button' hidden id='go_to_logintime_creadit_note' data-bs-toggle="modal" data-bs-target="#go_to_login_credit_note" />

      <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_credit_note" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
          <div className="modal-content mdl_cont">
            <div className="modal-header mdl_header">
              <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
            </div>
            <div className="modal-body mdl_bdy">
              Something Went Wrong.Please Login Again
            </div>
            <div className="modal-footer">
              <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
          <div className="modal-content mdl_cont">
            <div className="modal-header mdl_header">
              <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
            </div>
            <div className="modal-body mdl_bdy">
              Something Went Wrong.Please Login Again
            </div>
            <div className="modal-footer">
              <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
            </div>
          </div>
        </div>
      </div>

      <div className='h4_btm_bdr'>
        {
          creditshow && creditshow === 1 ? ('') : (<i onClick={() => setCreditShow(1)} className="fa-solid fa-circle-arrow-left"></i>)
        }
        <h4>Credit Note</h4>
      </div>
      {
        creditshow === 1 &&

        <div className='total_outfit_fr_tab'>
          <div className='invoice_em_top_head'>
            <select value={creditinvoicerecord.invoiceTypeIdentifier} className="select_inp_filed" id='credit_one' aria-label="Default select example" onChange={(e) => setcreditinvoicerecord({ ...creditinvoicerecord, invoiceTypeIdentifier: e.target.value, currentPage: creditPagination.Current_page = 1 })}>
              <option  value={""}>Invoice Type</option>
              {
                invoiceTypeDown && invoiceTypeDown.map((item, item_id) => (
                  <option key={item_id} value={item.invoiceTypeConfigurationIdentifier}>{item.invoiceTypeName}</option>
                ))
              }
            </select>
            <select value={creditinvoicerecord.supplierIdentifier} className="select_inp_filed" aria-label="Default select example" onChange={(e) => setcreditinvoicerecord({ ...creditinvoicerecord, supplierIdentifier: e.target.value, currentPage: creditPagination.Current_page = 1 })}>
              <option  value={""}>Supplier Name</option>
              {
                invoiceviewdropdown && invoiceviewdropdown.map((invoicedrop, uq) => (
                  <option key={uq} value={invoicedrop.supplierIdentifier}>{invoicedrop.supplierTradeName}</option>
                ))
              }
            </select>
            <select value={creditinvoicerecord.statusFlag} className="select_inp_filed" aria-label="Default select example" onChange={(e) => setcreditinvoicerecord({ ...creditinvoicerecord, statusFlag: e.target.value, currentPage: creditPagination.Current_page = 1 })}>
              <option  value="3">All</option>
              <option value="0">Pending Approval</option>
              <option value="1">Approved</option>
              {/* <option value="2">Rejected</option> */}
            </select>
            <input onKeyDown={credit_invoice_search} value={creditsearchvalue} className="search_inp_filed" type="text" name="" id="" placeholder="Search" onChange={credit_invoice_search_no}></input>
            <i className="fa-solid fa-magnifying-glass search_icon" onClick={handlecreditAllInvoiceSearch}></i>
          </div>

          <All_credit_note_table
            setCreditShow={setCreditShow}
          />

          <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
            <div>
              <p style={{ color: '#9a9a9a', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{creditresponsedata}</span></p>
            </div>
            <div>
              {creditresponsedata != 0 && <Pagination
                pageNumbers={creditpagenumbers}
                Paginations={creditPagination}
                handlePageChange={credithandlePageChange}
                LastPage={creditresponsedata && Math.ceil(creditresponsedata / 10)}
              />}
            </div>
          </div>
        </div>
      }
      {
        creditshow === 2 &&
        <div>
          <Credit_view_data invoiceTypeDown={invoiceTypeDown} />
        </div>

      }

    </div>
  )
}

export default All_credit_note