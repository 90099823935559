import React, { useContext, useEffect } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'
import Item_master_input from './Item_master_input'
import Pagination from '../../Pagination'
import Item_master_edit from './Item_master_edit'
import { useNavigate } from 'react-router-dom'

const Item_master_main_table = () => {
    const { sessionEndTime, itemshow, setitemshow, itemdata, setitemsearch, Getitemdata, setitemdropdownvalue, itemdropdownvalue, itemenabledvalue, itemcreateObj, seterrfieldone,
        itempageNumbers, itemPaginations, itemhandlePageChange, itemresponse, itemdropdown, handleitemDropdownChange, handleedititem, Getinvoicetype,
        setcreateitemmaster, handleClick, SetitemPaginations, Ascending, CurrentUser, handledeleteitemmaster, decryptData ,itemsearch} = useContext(E_invoice_context)


    useEffect(() => {
        if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
            (sessionEndTime < new Date())) {
            document.getElementById('go_to_logintime_item').click();
        }
    }, [])

    const Navigate = useNavigate()

    const Logout = () => {
        sessionStorage.clear()
        Navigate('/');
    }


    useEffect(() => {
        if (CurrentUser) {
            Getitemdata();
            Getinvoicetype();
        }
    }, [itemPaginations, itemdropdownvalue, itemenabledvalue, Ascending])

    const create_fieldempty = () => {
        setitemshow(2)
        setcreateitemmaster(itemcreateObj)
        seterrfieldone(true)
    }
    const searchitem = () => {
        SetitemPaginations({ ...itemPaginations, Current_page: itemPaginations.Current_page = 1 });
        Getitemdata();
    }

    const searchenteritem = (e) => {
        if (e.keyCode === 13) {
            searchitem();
        }
    }
    const item_master_drop = (e) => {
        setitemdropdownvalue(e.target.value)
        SetitemPaginations({ ...itemPaginations, Current_page: itemPaginations.Current_page = 1 });
    }

    const dis_enab = (e) => {
        handleitemDropdownChange(e);
        SetitemPaginations({ ...itemPaginations, Current_page: itemPaginations.Current_page = 1 });
    }
    return (
        <div>


            <input type="text" id='model_triger_item_master' hidden data-bs-toggle="modal" data-bs-target="#model_one_item_master" />
            <input type="text" id='model_triger_item_delete' hidden data-bs-toggle="modal" data-bs-target="#model_one_item_delete" />


            <div className="modal fade mdl_e_fade" id="model_one_item_master" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                        Record updated successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>


            <input type='button' hidden id='succces_go_back' data-bs-toggle="modal" data-bs-target="#deleteModal_itemMaster" />
            <div className="modal fade mdl_e_fade" tabIndex="-1" id="deleteModal_itemMaster" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Are you sure you want to delete this?
                        </div>
                        <div className="modal-footer">
                            <button onClick={handledeleteitemmaster} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                            <button type="button" className="btn mdl_btn_cancel" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade mdl_e_fade" id="model_one_item_delete" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Records Deleted Successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type='button' hidden id='go_to_logintime_item' data-bs-toggle="modal" data-bs-target="#go_to_login_time" />

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>


            <input type='button' hidden id='invoice_typ_exist' data-bs-toggle="modal" data-bs-target="#invoice_typ_exist_model" />

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="invoice_typ_exist_model" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                        Item Master already in use cannot be deleted.
                        </div>
                        <div className="modal-footer">
                            <button type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Please Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="h4_btm_bdr"><h4>Item Master</h4></div>
            {
                itemshow === 1 &&
                <div className='total_outfit_fr_tab'>
                    <div className="create_btn_top">
                        <div>
                            <button onClick={create_fieldempty} className="grn_btn">Create Item</button>
                        </div>
                        <div className='create_right_aligh'>
                            <select className="select_inp_filed" value={itemenabledvalue} onChange={dis_enab} on aria-label="Default select example">
                                <option selected value="">All</option>
                                <option value="1">Enabled</option>
                                <option value="2">Disabled</option>
                            </select>
                            <select value={itemdropdownvalue} className="select_inp_filed" onChange={item_master_drop} aria-label="Default select example">
                                <option selected value=''>Invoice Type</option>
                                {
                                    itemdropdown && itemdropdown.map((itemdrop, uq) => (
                                        <option key={uq} value={itemdrop.invoiceTypeConfigurationIdentifier}>
                                            {itemdrop.invoiceTypeName}
                                        </option>
                                    ))
                                }
                            </select>
                            <input onKeyDown={searchenteritem} value={itemsearch} onChange={(e) => setitemsearch(e.target.value)} className="search_inp_filed" type="text" name="" id="" placeholder="Search"></input>
                            <i onClick={searchitem} className="fa-solid fa-magnifying-glass search_icon"></i>
                        </div>
                    </div>
                    <div>

                        <table className='table_c__M'>
                            <thead>
                                <tr>
                                    <th><span style={{ opacity: '0' }}>Hidden</span></th>
                                    <th onClick={() => handleClick('ItemName')}>Item Name</th>
                                    <th onClick={() => handleClick('ItemCode')}>Item Code</th>
                                    <th onClick={() => handleClick('InvoiceType')}>Invoice Type</th>
                                    <th onClick={() => handleClick('ItemDescription')}>Item Description</th>
                                    <th onClick={() => handleClick('Cgstrate')}>CGST</th>
                                    <th onClick={() => handleClick('Sgstrate')}>SGST</th>
                                    <th onClick={() => handleClick('Igstrate')}>IGST</th>
                                    <th onClick={() => handleClick('Status')}>Status</th>
                                </tr>
                            </thead>
                            {itemdata && itemdata.length > 0 ? (
                                <tbody>
                                    {
                                        itemdata && itemdata.map((item, iq) => (
                                            <tr key={iq}>
                                                <td><button onClick={() => handleedititem(item.itemMasterIdentifier)} className='c_m_btn'>View</button></td>
                                                <td>{item.itemName}</td>
                                                <td>{item.itemCode}</td>
                                                <td >{item.invoiceType}</td>
                                                <td className='invoice_trunk'><div>{item.itemDescription}</div></td>
                                                <td>{item.cgstrate}%</td>
                                                <td>{item.sgstrate}%</td>
                                                <td>{item.igstrate}%</td>
                                                <td>{item.activeFlag === true ? ('Enabled') : ('Disabled')}</td>
                                            </tr>
                                        ))
                                    }
                                </tbody>
                            ) : (
                                <tbody>
                                    <tr>
                                        <td colSpan="9" style={{ fontSize: '15px', textAlign: 'center' }}>
                                            No records found.
                                        </td>
                                    </tr>
                                </tbody>
                            )}
                        </table>


                    </div>
                    <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                        <div>
                            <p style={{ color: '#9a9a9a', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{itemresponse}</span></p>
                        </div>
                        <div>
                            <Pagination
                                pageNumbers={itempageNumbers}
                                Paginations={itemPaginations}
                                handlePageChange={itemhandlePageChange}
                                LastPage={itemresponse && Math.ceil(itemresponse / 10)}
                            />
                        </div>
                    </div>
                </div>
            }{
                itemshow === 2 &&

                <Item_master_input
                    create_fieldempty={create_fieldempty}
                />
            }
            {
                itemshow === 3 &&

                <Item_master_edit

                />
            }

        </div>
    )
}

export default Item_master_main_table