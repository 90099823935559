import React, { useContext, useState } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
const Invoice_create_config = ({ Invoice_icon_bk }) => {
    const { invoicetypeshow, setinvoicetypeshow, handleinvoiceiputchange,
        invoiceviewdropdown, handleinvoiceiflagchange, invoiceinput, handleQuillChange, handleinvoiceinsert, suplierinserstatecode, invoicecofigerror, setinvoicecofigerror } = useContext(E_invoice_context)

    return (
        <div>

            <input type="text" id='invoice_type_insert' hidden data-bs-toggle="modal" data-bs-target="#invoice_type_insert_mdl" />

            <div className="modal fade mdl_e_fade" id="invoice_type_insert_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Record updated successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>




            <div className='creat_c_m c_s_c' style={{ borderLeft: '1px solid #dfdfdf', marginTop: '0', paddingTop: "10px", borderRight: '1px solid #dfdfdf' }}>
                <i onClick={Invoice_icon_bk} className="fa-solid fa-circle-arrow-left"></i>
                <p style={{ marginBottom: '0', color: '#c95643', fontSize: '14px' }}>Create Invoice Type</p>
            </div>
            <div style={{ padding: '10px', border: '1px solid #dfdfdf' }}>
                <div className='create_in_fild mt-4'>
                    <div className='row'>
                        <div className='col-lg-3 col-12'>
                            <label htmlFor="" className='label_w_s'>Invoice Type Name<span className="mandatory_star2">*</span></label>
                            <input onChange={handleinvoiceiputchange} className="search_inp_filed in_tb" value={invoiceinput.invoiceTypeName} type="text" name="invoiceTypeName" id="" placeholder=""></input>
                            {
                                invoicecofigerror === false &&
                                <p style={{ marginBottom: 0, fontSize: '12px', color: "red" }}>{invoiceinput.invoiceTypeName === "" ? ('Please Provide valid Information') : ('')}</p>
                            }

                        </div>
                        <div className='col-lg-3 col-12'>
                            <label htmlFor="" className='label_w_s'>Invoice Type Serial Code<span className="mandatory_star2">*</span></label>
                            <input onChange={handleinvoiceiputchange} className="search_inp_filed in_tb" value={invoiceinput.invoiceTypeSerialCode} type="text" name="invoiceTypeSerialCode" id="invoiceTypeSerialCode" placeholder=""></input>
                            {
                                invoicecofigerror === false &&
                                <p style={{ marginBottom: 0, fontSize: '12px', color: "red" }}>{invoiceinput.invoiceTypeSerialCode === "" ? ('Please Provide valid Information') : ('')}</p>
                            }
                        </div>
                        <div className='col-lg-6 col-12'>
                            <label htmlFor="" className='label_w_s'>Supplier Name<span className="mandatory_star2">*</span></label>
                            <select onChange={handleinvoiceiputchange} style={{ width: '100%' }} name='supplierTradeName' value={invoiceinput.supplierTradeName} className="select_inp_filed" aria-label="Default select example">
                                <option selected value=''></option>
                                {
                                    suplierinserstatecode && suplierinserstatecode !== undefined && suplierinserstatecode.map((invoicedrop, uq) => (
                                        <option key={uq} value={invoicedrop.supplierIdentifier}>
                                            {invoicedrop.supplierTradeName}
                                        </option>
                                    ))
                                }
                            </select>
                            {
                                invoicecofigerror === false &&
                                <p style={{ marginBottom: 0, fontSize: '12px', color: "red" }}>{invoiceinput.supplierTradeName === "" ? ('Please Provide valid Information') : ('')}</p>
                            }
                        </div>
                    </div>
                    <div className='create_check_filed row'>
                        <div className='col-lg-3 col-12 check_cit'>
                            <input onChange={handleinvoiceiflagchange} type="checkbox" checked={invoiceinput.marginFlag} id="taxcalcuation" name="marginFlag"></input>
                            <label className="filter_checkbox_white" htmlFor="taxcalcuation" >Tax Calculation on Margin</label>
                        </div>
                        <div className='col-lg-3 col-12 check_cit'>
                            <input onChange={handleinvoiceiflagchange} type="checkbox" checked={invoiceinput.tcsflag} id="taxcalcuation_1" name="tcsflag"></input>
                            <label className="filter_checkbox_white" htmlFor="taxcalcuation_1" >TCS</label>
                        </div>
                        <div className='col-lg-3 col-12 check_cit'>
                            <input onChange={handleinvoiceiflagchange} type="checkbox" checked={invoiceinput.autoApproveFlag} id="taxcalcuation_2" name="autoApproveFlag"></input>
                            <label className="filter_checkbox_white" htmlFor="taxcalcuation_2" >Auto Approve</label>
                        </div>
                    </div>
                    <div className='create_check_filed row'>
                        <div className='col-lg-3 col-12 check_cit'>
                            <input onChange={handleinvoiceiflagchange} type="checkbox" checked={invoiceinput.regUnregFlg} name="regUnregFlg" id="useregister" placeholder=""></input>
                            <label htmlFor="useregister" className='filter_checkbox_white'>Use Registered/Unregistered Logic</label>
                        </div>
                    </div>
                </div>
                <div className='creat_c_m c_s_c mt-5'>
                    <p style={{ marginBottom: '0', color: '#c95643', fontSize: '14px' }}>Invoice Type Mail Template</p>
                </div>
                <div>
                    <label htmlFor="" className='label_w_s mt-4'>CC</label>
                    <input onChange={handleinvoiceiputchange} className="search_inp_filed in_tb" value={invoiceinput.invoiceTypeCcemail} type="text" name="invoiceTypeCcemail" id="" placeholder=""></input>

                    <label htmlFor="" className='label_w_s mt-4'>Subject</label>
                    <input onChange={handleinvoiceiputchange} className="search_inp_filed in_tb" value={invoiceinput.invoiceTypeMailSubject} type="text" name="invoiceTypeMailSubject" id="" placeholder=""></input>

                    <label htmlFor="" className='label_w_s mt-4'>Body</label>
                    <ReactQuill onChange={handleQuillChange} theme="snow" />

                </div>
                <div style={{ display: 'flex', justifyContent: 'end', gap: '10px' }} className='mt-3'>
                    <button onClick={handleinvoiceinsert} className='grn_btn'>Save</button>
                    <button onClick={Invoice_icon_bk} className='diable_btn_edit'>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default Invoice_create_config