import React, { useContext } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'
import Pagination from '../../Pagination'


const Invoice_email_table = ({ handleView, SetInvoiceEmailShow, InvoiceEmailshow, Emailinvoicedata, Emailinvoiceresponse, invoiceEmailpageNumbers, EmailinvoicePaginations, invoicehEmailandlePageChange, handleEmailSortClick }) => {

    const { handleViewInvoice } = useContext(E_invoice_context)



    return (
        <div>
            <div className='table_str'>
                <table className='table table_grid_dw'>
                    <thead>
                        <tr>
                            <th><span style={{ opacity: '0' }}>Hidden Text</span></th>
                            <th onClick={() => handleEmailSortClick('invoiceNumber')}>Invoice Number</th>
                            <th onClick={() => handleEmailSortClick('invoiceDate')}>Invoice Date</th>
                            <th onClick={() => handleEmailSortClick('customerName')}>Customer Name</th>
                            <th onClick={() => handleEmailSortClick('customerType')}>Customer Type</th>
                            <th onClick={() => handleEmailSortClick('invoiceType')}>Invoice Type</th>
                            <th onClick={() => handleEmailSortClick('supplierGST')}>Supplier GST</th>
                            <th onClick={() => handleEmailSortClick('contractNumber')}>Contract Number</th>
                            <th onClick={() => handleEmailSortClick('panNumber')}>PAN Number</th>
                            <th onClick={() => handleEmailSortClick('invstatus')}>Status</th>
                            <th onClick={() => handleEmailSortClick('mailFlag')}>Mail Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Emailinvoicedata && Emailinvoicedata.map((invoice, index) => (
                            <tr key={index}>
                                <td><button onClick={() => handleViewInvoice(invoice.invoiceIdentifier)} className='c_m_btn'>View</button></td>
                                <td>{invoice.invoiceSerialNumber}</td>
                                <td>{invoice.invoiceDate}</td>
                                <td>{invoice.customerName}</td>
                                <td>{invoice.customerType == '1' ? ('Non CMS') : ('CMS')}</td>
                                <td>{invoice.invoiceType}</td>
                                <td>{invoice.supplierGST}</td>
                                <td>{invoice.contractNumber}</td>
                                <td>{invoice.panNumber}</td>
                                <td>{invoice.invstatus == '0' ? ('Pending') : ('Approved')}</td>
                                <td>{invoice.mailFlag == 'False' ? ('Pending') : ('sent')}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                <div>
                    <p style={{ color: '#9a9a9a', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{Emailinvoiceresponse}</span></p>
                </div>
                <div>
                    <Pagination
                        pageNumbers={invoiceEmailpageNumbers}
                        Paginations={EmailinvoicePaginations}
                        handlePageChange={invoicehEmailandlePageChange}
                        LastPage={Emailinvoiceresponse && Math.ceil(Emailinvoiceresponse / 10)}
                    />
                </div>
            </div>
        </div>
    )
}

export default Invoice_email_table