import React, { useContext } from 'react';
import { E_invoice_context } from '../../../../E_invoice_context';

const InvoiceRejectedTable = () => {
    const { InvoiceRec } = useContext(E_invoice_context);
    // console.log(InvoiceRec.tblGstapiinvoiceRejects)


    return (
        <div>
            <div className='table_str'>
                <div className='rejection'>
                    <h3>Reason For Rejection</h3>
                </div>
                <table className='table table_grid_dw'>
                    <thead>
                        <tr>
                            <th>UserName</th>
                            <th>Reason</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {InvoiceRec && InvoiceRec.tblGstapiinvoiceRejects && (
                            InvoiceRec.tblGstapiinvoiceRejects.map((inv, index) => (
                                <tr key={index}>
                                    <td>{inv.invoiceSerialNumber}</td>
                                    <td>{inv.reason}</td>
                                    <td>
                                        {inv.rejectionDate ?
                                            new Date(inv.rejectionDate).toLocaleDateString('en-GB', {
                                                day: '2-digit',
                                                month: 'short',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                hour12: true,
                                            }) :
                                            ''}
                                    </td>
                                </tr>
                            ))
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default InvoiceRejectedTable;
