import React, { useContext, useEffect, useState } from 'react'
import Customer_master from '../Invoice/All_invoice/Customer_master'
import Customer_master_cms from '../Invoice/All_invoice/Customer_master_cms'
import { E_invoice_context } from '../../../E_invoice_context'
import Custom_master_cms from './Custom_master_cms'
import { useNavigate } from 'react-router-dom'

const Customer_master_main_table = () => {
    const { sessionEndTime, GetCustomer, Allinvoicepagination, decryptData, EditViewFlag,GetCMSCustomerDataObj,CMSCustomerPagination,SetCMSCustomerPagination,
         setEditViewFlag, Getstatecodeconfig, setcustomviewshow, nonCMS, customviewshow ,setCMSCustomerSearch,CMSCustomerSearch,handleNON_CMSCustomerPaginationNumber,setnoncmssearch,setnonCMS} = useContext(E_invoice_context)


    useEffect(() => {
        if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
            (sessionEndTime < new Date())) {
            document.getElementById('go_to_logintime_custom').click();
        }
    }, [])

    const Navigate = useNavigate()

    const Logout = () => {
        sessionStorage.clear()
        Navigate('/');
    }

    useEffect(() => {
        GetCustomer(1, "")
    }, [])

    useEffect(() => {
        Getstatecodeconfig();
        GetCustomer(1, '');
    }, [nonCMS, Allinvoicepagination])

    const handleNOncustomerMasterClick = () => {
        setnonCMS({ ...nonCMS, billingStatesCode:nonCMS.billingStatesCode = "",shippingStatesCode:nonCMS.shippingStatesCode = "",search:nonCMS.search="",regUnRegFlag:nonCMS.regUnRegFlag = "0"})
        setnoncmssearch('')
        handleNON_CMSCustomerPaginationNumber(1)
        setEditViewFlag("0")
        GetCustomer(1, "")
    }
    const handleCMScustomerMasterClick = () => {
      
        setCMSCustomerSearch({ ...CMSCustomerSearch, cms:CMSCustomerSearch.cms ="" })
        setcustomviewshow(1)
        GetCustomer(1, "")
        searchcmscustomer();
    }
    const searchcmscustomer = () => {
        SetCMSCustomerPagination({ ...CMSCustomerPagination, Current_page: CMSCustomerPagination.Current_page = 1 });
        GetCMSCustomerDataObj();
    }

    return (
        <div>
            <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />
            <input type='button' hidden id='go_to_logintime_custom' data-bs-toggle="modal" data-bs-target="#go_to_login_custom" />

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_custom" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Please Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Please Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <ul className="nav nav-pills nav_itm mb-3" id="pills-tab" role="tablist">
                    <li className="nav-item nav_itm" role="presentation">
                        <button className="nav-link nav_itm active" id="pills-home-tab" onClick={handleNOncustomerMasterClick} data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Customer Master - Non CMS</button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link nav_itm" id="pills-profile-tab" onClick={handleCMScustomerMasterClick} data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Customer Master - CMS</button>
                    </li>
                </ul>
                <div>

                    <div className="tab-content tab_nav_content" id="pills-tabContent">
                        <div className="tab-pane tab_pan fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                            <Customer_master isHide={true} />
                        </div>
                        <div className="tab-pane tab_pan fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <Customer_master_cms
                                isHide={true}
                                view={true}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Customer_master_main_table