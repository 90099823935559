
import "./Fontstyle.css"
import Home from "./Home";
import { Routes, Route } from "react-router-dom";
import Login_form from "./Login_form/Login_form";
import Unauthorized from "./Main_content/Tables_content/Unauthorized/Unauthorized";
import SSOPage from "./SSO_Login/SSOPage";
import SSO_CallBack from "./SSO_Login/SSO_CallBack";
function App() {
  return (
    <div className="container-fluid ps-0 pe-0">
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/" element={<SSOPage />} />
        <Route path="/login" element={<Login_form />} />
        <Route path="/unauthorized" element={<Unauthorized />} />
        <Route path="/callback" element={<SSO_CallBack />} />
      </Routes>
    </div>
  );
}

export default App;
