import React, { useContext, useEffect, useState } from 'react'
import All_invoice_main_table from './All_invoice_main_table'
import All_create_invoice from './All_create_invoice'
import All_invoice_view from './All_invoice_view'

import Customer_master_cms from './Customer_master_cms'
import { E_invoice_context } from '../../../../E_invoice_context'
import Non_CMS_Customer_Create from './Non_CMS_Customer_Create'
import Customer_master from './Customer_master'
import { useNavigate } from 'react-router-dom'

const All_invoice = () => {
  const { sessionEndTime, SetShow, setIsProfoma, billaddressbox, itemdropdown, invoiceAccess, invoicing, setContractCustomer,
    Editboxaddressbill, setAllinvoicepagination, setPanValue, InvReqObj, setInvReqObj, show, Getstatecodeconfig,
    nonCMS, GetCustomer, Allinvoicepagination, SubmitInvoice, decryptData, Getinvoicetype } = useContext(E_invoice_context)


  useEffect(() => {
    if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
      (sessionEndTime < new Date())) {
      document.getElementById('go_to_logintime_all_inovice').click();
    }
    Getinvoicetype();
  }, [])

  const Navigate = useNavigate()

  const Logout = () => {
    sessionStorage.clear()
    Navigate('/');
  }


  var invoiceTypeDown = []
  var ViewTypeDown = []
  var MakerTypeDown = []

  if (invoicing && invoicing.length !== 0) {
    invoiceTypeDown = itemdropdown && itemdropdown
      .filter(item => invoicing.includes(item.invoiceTypeConfigurationIdentifier))
      .filter(item => invoiceAccess && invoiceAccess.some(rec => (item.invoiceTypeConfigurationAutoId === rec.invoiceTypeConfigurationAutoId) && (rec.makerFlag === true || rec.viewFlag === true)));

    ViewTypeDown = itemdropdown && itemdropdown
      .filter(item => invoicing.includes(item.invoiceTypeConfigurationIdentifier))
      .filter(item => invoiceAccess && invoiceAccess.some(rec => (item.invoiceTypeConfigurationAutoId === rec.invoiceTypeConfigurationAutoId) && (rec.viewFlag === true || rec.makerFlag === true)));

    MakerTypeDown = itemdropdown && itemdropdown
      .filter(item => invoicing.includes(item.invoiceTypeConfigurationIdentifier))
      .filter(item => invoiceAccess && invoiceAccess.some(rec => (item.invoiceTypeConfigurationAutoId === rec.invoiceTypeConfigurationAutoId) && (rec.makerFlag === true)));

    // console.log('itemdropdown', itemdropdown)
    // console.log('invoiceTypeDown', invoiceTypeDown)
    // console.log('ViewTypeDown', ViewTypeDown)
    // console.log('MakerTypeDown', MakerTypeDown)
  }
  else {
    invoiceTypeDown = itemdropdown
    ViewTypeDown = itemdropdown
    MakerTypeDown = itemdropdown
  }

  // console.log(invoicing)

  useEffect(() => {
    setInvReqObj({
      ...InvReqObj,
      "search": "",
      "currentPage": 1,
      "pageSize": 10,
      "invoiceTypeIdentifier": "",
      "supplierIdentifier": "",
      "customerType": "",
      "customerIdentifier": "",
      "statusFlag": 3,
      "isMail": false,
      "isCreditNote": false,
      "isProforma": false,
      "sortField": "invoiceAutoId",
      "descFlag": true
    })
    SetShow(1)
    setAllinvoicepagination({ ...Allinvoicepagination, Current_page: Allinvoicepagination.Current_page = 1 })
    setPanValue('')
    setIsProfoma(false)
    setContractCustomer({})
  }, [])

  useEffect(() => {
    Getstatecodeconfig();
    GetCustomer(1, '');

  }, [nonCMS, Allinvoicepagination])


  return (
    <div >
      <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />

      <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
          <div className="modal-content mdl_cont">
            <div className="modal-header mdl_header">
              <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
            </div>
            <div className="modal-body mdl_bdy">
              Something Went Wrong.Please Login Again
            </div>
            <div className="modal-footer">
              <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        {
          show && show === 1 ? (<>
            <div className='h4_btm_bdr'>
              {
                show && show === 1 ? ('') : (<i onClick={() => { SetShow(1); setPanValue(''); }} className="fa-solid fa-circle-arrow-left"></i>)
              }
              <h4>All Invoices</h4>
            </div>
            <All_invoice_main_table
              isAll={true}
              isCredit={false}
              isApproved={false}
              isMail={false}
              invoiceTypeDown={invoiceTypeDown}
            /></>
          ) : (
            <>
              {
                show === 2 ? (<>
                  <div className='h4_btm_bdr'>
                    {
                      show && show === 1 ? ('') : (<i onClick={() => { SetShow(1); setPanValue(''); }} className="fa-solid fa-circle-arrow-left"></i>)
                    }
                    <h4>All Invoices</h4>
                  </div>
                  <All_create_invoice MakerTypeDown={MakerTypeDown} /></>
                ) : (
                  <>
                    {
                      show === 3 ? (
                        <Customer_master />
                      ) : (
                        <>
                          {
                            show === 4 ? (<>
                              <div className='h4_btm_bdr'>
                                {
                                  show && show === 1 ? ('') : (<i onClick={() => { SetShow(1); setPanValue(''); }} className="fa-solid fa-circle-arrow-left"></i>)
                                }
                                <h4>All Invoices</h4>
                              </div>
                              <All_invoice_view ViewTypeDown={ViewTypeDown} MakerTypeDown={MakerTypeDown} /></>
                            ) : (
                              <>
                                {
                                  show === 5 ? (
                                    <Non_CMS_Customer_Create />
                                  ) : (
                                    <>
                                      {
                                        show === 6 ? (
                                          <Customer_master_cms />
                                        ) : ('')
                                      }
                                    </>
                                  )
                                }
                              </>
                            )
                          }
                        </>
                      )
                    }
                  </>
                )
              }
            </>
          )
        }
      </div>
      <input type="text" id='invoice_creating' hidden data-bs-toggle="modal" data-bs-target="#invoice_creating_mdl" />

      <div className="modal fade mdl_e_fade" id="invoice_creating_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mdl_dialogu">
          <div className="modal-content mdl_cont">
            <div className="modal-header mdl_header">
              <h5 className="modal-title mdl_title">Alert</h5>
              <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body mdl_bdy">
              Are you sure you want to Generate the Invoice, You cannot edit the invoice once invoice is Generated.
            </div>
            <div className="modal-footer">
              <button onClick={() => SubmitInvoice("")} className='grn_btn'>Yes</button>
              <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">No</button>
            </div>
          </div>
        </div>
      </div>


      <input type="text" id='Invoice_success_model' hidden data-bs-toggle="modal" data-bs-target="#invoice_Success_mdl" />
      <div className="modal fade mdl_e_fade" id="invoice_Success_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered mdl_dialogu">
          <div className="modal-content mdl_cont">
            <div className="modal-header mdl_header">
              <h5 className="modal-title mdl_title">Alert</h5>
              <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body mdl_bdy">
              Invoice has been created successfully
            </div>
            <div className="modal-footer">
              <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
            </div>
          </div>
        </div>
      </div>


      <div>
        <input type="text" id='alert_delete_customer' hidden data-bs-toggle="modal" data-bs-target="#alert_delete_customer_model" />

        <div className="modal fade mdl_e_fade" id="alert_delete_customer_model" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered mdl_dialogu">
            <div className="modal-content mdl_cont">
              <div className="modal-header mdl_header">
                <h5 className="modal-title mdl_title">Alert</h5>
                <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body mdl_bdy">
                Customer is already in use and cannot be deleted.
              </div>
              <div className="modal-footer">
                <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

  )
}

export default All_invoice