import React from 'react'
import './Button_cnt.css'

const Buttons = ({innertxt,innerclr}) => {
  return (
    <div>
          <div className='main_btns' style={{backgroundColor:`#${innerclr}`}}>
              {innertxt}
          </div>
    </div>
  )
}

export default Buttons