import React, { useContext, useEffect, useState } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { tr } from 'date-fns/locale';

const Invoice_type_edit = ({ Invoice_icon_bk, invoicetypecancel, setinvoicetypeeditview, invoicetypeeditview }) => {
    const { invoicetypeshow, setinvoicetypeshow, handleinvoiceiputchange, handledeleteinvoicetypemaster, handledisabletext, Activeflag, Edittypeapi,
        invoiceviewdropdown, handleinvoiceiflagchange, handleenabletext, invoiceinput, handleQuillChange, suplierinserstatecode, invoicecofigerror, handleinvoicetypeeditsaveinput, Actionbutton, invoicetypegst } = useContext(E_invoice_context)

    // console.log(Activeflag)

    const enable_mdl = () => {
        document.getElementById('invoice_type_alert_enable').click();
    }
    const disable_mdl = () => {
        document.getElementById('invoice_type_alert_disable').click();
    }
    const delete_mdl = () => {
        document.getElementById('invoice_type_alert_delete').click();
    }

    // useEffect(()=>{
    //     Edittypeapi();
    // },[Activeflag])

    // console.log(suplierinserstatecode)
    // console.log(suplierinserstatecode && suplierinserstatecode.supplierTradeName)

    return (
        <div>


            <input type="text" id='invoice_type_insert' hidden data-bs-toggle="modal" data-bs-target="#invoice_type_insert_mdl" />

            <div className="modal fade mdl_e_fade" id="invoice_type_insert_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Record updated successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type="text" id='invoice_type_delete' hidden data-bs-toggle="modal" data-bs-target="#invoice_type_insert_delete" />

            <div className="modal fade mdl_e_fade" id="invoice_type_insert_delete" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Record Deleted Successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>


            <input type="text" id='invoice_type_alert_delete' hidden data-bs-toggle="modal" data-bs-target="#invoice_type_alert_delete_mdl" />

            <div className="modal fade mdl_e_fade" id="invoice_type_alert_delete_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Are you sure you want to delete this?
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={handledeleteinvoicetypemaster} className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                            <button type="button" className="btn mdl_btn_cancel" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>



            <input type="text" id='invoice_type_alert_Successful' hidden data-bs-toggle="modal" data-bs-target="#invoice_type_alert_Successful_mdl" />

            <div className="modal fade mdl_e_fade" id="invoice_type_alert_Successful_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Invoice type has been enabled successfully.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>



            <input type="text" id='invoice_type_alert_enable' hidden data-bs-toggle="modal" data-bs-target="#invoice_type_alert_enable_mdl" />

            <div className="modal fade mdl_e_fade" id="invoice_type_alert_enable_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Are you sure you want to enable this?
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={handleenabletext} className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                            <button type="button" className="btn mdl_btn_cancel" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>



            <input type="text" id='invoice_type_alert_Successful_dis' hidden data-bs-toggle="modal" data-bs-target="#invoice_type_alert_Successful_mdl_dis" />

            <div className="modal fade mdl_e_fade" id="invoice_type_alert_Successful_mdl_dis" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Invoice type has been disabled successfully.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>



            <input type="text" id='invoice_type_alert_disable' hidden data-bs-toggle="modal" data-bs-target="#invoice_type_alert_disable_mdl" />

            <div className="modal fade mdl_e_fade" id="invoice_type_alert_disable_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Are you sure you want to disable this?
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={handledisabletext} className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                            <button type="button" className="btn mdl_btn_cancel" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type="text" id='invoice_type_exist' hidden data-bs-toggle="modal" data-bs-target="#invoice_type_exist_model" />

            <div className="modal fade mdl_e_fade" id="invoice_type_exist_model" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                        Invoice Type already in use cannot be deleted.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='creat_c_m c_s_c' style={{ borderLeft: '1px solid #dfdfdf', marginTop: '0', paddingTop: "10px", borderRight: '1px solid #dfdfdf' }}>
                <i onClick={Invoice_icon_bk} className="fa-solid fa-circle-arrow-left"></i>
                <p style={{ marginBottom: '0', color: '#c95643', fontSize: '14px' }}>Create Invoice Type</p>
                <div style={{ display: 'flex', justifyContent: 'end', gap: '20px', width: '82%' }}>
                    {
                        invoicetypeeditview.Edit === true &&
                        (<button onClick={() => setinvoicetypeeditview({ ...invoicetypeeditview, Edit: false })} className="grn_btn">Edit</button>)
                    }
                    {/* {
                        invoicetypeeditview.enable === false ? (
                            <button onClick={disable_mdl} className='diable_btn_edit'>{Actionbutton.disable}</button>
                        ) : (
                            <button onClick={enable_mdl} className='grn_btn'>{Actionbutton.enable}</button>
                        )
                    } */}

                    {
                        Activeflag === false ? (
                            <button onClick={enable_mdl} className='grn_btn'>{Actionbutton.enable}</button>
                        ) : (
                            <button onClick={disable_mdl} className='diable_btn_edit'>{Actionbutton.disable}</button>
                        )
                    }

                    <button onClick={delete_mdl} className='diable_btn_edit'>{Actionbutton.delete}</button>
                </div>
            </div>
            <div style={{ padding: '10px', border: '1px solid #dfdfdf' }}>
                <div className='create_in_fild mt-4'>
                    <div className='row'>
                        <div className='col-lg-3 col-12'>
                            <label htmlFor="" className='label_w_s'>Invoice Type Name<span className="mandatory_star2">*</span></label>
                            <input disabled={invoicetypeeditview.Edit === true} onChange={handleinvoiceiputchange} className="search_inp_filed in_tb" value={invoiceinput.invoiceTypeName} type="text" name="invoiceTypeName" id="" placeholder=""></input>

                            {
                                invoicecofigerror === false &&
                                <p style={{ marginBottom: 0, fontSize: '12px', color: "red" }}>{invoiceinput.invoiceTypeName === "" ? ('Please Provide valid Information') : ('')}</p>
                            }
                        </div>
                        <div className='col-lg-3 col-12'>
                            <label htmlFor="" className='label_w_s'>Invoice Type Serial Code<span className="mandatory_star2">*</span></label>
                            <input disabled={invoicetypeeditview.Edit === true} onChange={handleinvoiceiputchange} className="search_inp_filed in_tb" value={invoiceinput.invoiceTypeSerialCode} type="text" name="invoiceTypeSerialCode" id="invoiceTypeSerialCode" placeholder=""></input>
                            {
                                invoicecofigerror === false &&
                                <p style={{ marginBottom: 0, fontSize: '12px', color: "red" }}>{invoiceinput.invoiceTypeSerialCode === "" ? ('Please Provide valid Information') : ('')}</p>
                            }
                        </div>
                        <div className='col-lg-6 col-12'>
                            <label htmlFor="" className='label_w_s'>Supplier Name<span className="mandatory_star2">*</span></label>
                            <select disabled={invoicetypeeditview.Edit === true} onChange={handleinvoiceiputchange} style={{ width: '100%' }} name='supplierTradeName' value={invoiceinput.supplierTradeName} className="select_inp_filed" aria-label="Default select example">
                                <option value=''>select</option>
                                {
                                    suplierinserstatecode && suplierinserstatecode !== undefined && suplierinserstatecode.map((invoicedrop, uq) => (
                                        <option key={uq} value={invoicedrop.supplierIdentifier}>
                                            {invoicedrop.supplierTradeName}
                                        </option>
                                    ))
                                }
                                {
                                    invoicecofigerror === false &&
                                    <p style={{ marginBottom: 0, fontSize: '12px', color: "red" }}>{invoiceinput.supplierTradeName === "" ? ('Please Provide valid Information') : ('')}</p>
                                }
                            </select>
                        </div>
                    </div>
                    <div className='create_check_filed row'>
                        <div className='col-lg-3 col-12 check_cit'>
                            <input disabled={invoicetypeeditview.Edit === true} onChange={handleinvoiceiflagchange} type="checkbox" checked={invoiceinput.marginFlag} id="taxcalcuation" name="marginFlag"></input>
                            <label className="filter_checkbox_white" htmlFor="taxcalcuation" >Tax Calculation on Margin</label>
                        </div>
                        <div className='col-lg-3 col-12 check_cit'>
                            <input disabled={invoicetypeeditview.Edit === true} onChange={handleinvoiceiflagchange} type="checkbox" name="tcsflag" checked={invoiceinput.tcsflag} id="tcs" placeholder=""></input>
                            <label htmlFor="tcs" className='filter_checkbox_white'>TCS</label>
                        </div>
                        <div className='col-lg-6 col-12 check_cit'>
                            <input disabled={invoicetypeeditview.Edit === true} onChange={handleinvoiceiflagchange} type="checkbox" name="autoApproveFlag" checked={invoiceinput.autoApproveFlag} id="autoapprove" placeholder=""></input>
                            <label htmlFor="autoapprove" className='filter_checkbox_white'>Auto Approve</label>
                        </div>
                    </div>
                    <div className='create_check_filed row'>
                        <div className='col-lg-3 col-12 check_cit'>
                            <input disabled={invoicetypeeditview.Edit === true} onChange={handleinvoiceiflagchange} type="checkbox" checked={invoiceinput.regUnregFlg} name="regUnregFlg" id="useregister" placeholder=""></input>
                            <label htmlFor="useregister" className='filter_checkbox_white'>Use Registered/Unregistered Logic</label>
                        </div>
                    </div>
                </div>
                <div className='creat_c_m c_s_c mt-5'>
                    <p style={{ marginBottom: '0', color: '#c95643', fontSize: '14px' }}>Invoice Type Mail Template</p>
                </div>
                <div>
                    <label htmlFor="" className='label_w_s mt-4'>CC</label>
                    <input disabled={invoicetypeeditview.Edit === true} onChange={handleinvoiceiputchange} className="search_inp_filed in_tb" value={invoiceinput.invoiceTypeCcemail} type="text" name="invoiceTypeCcemail" id="" placeholder=""></input>

                    <label htmlFor="" className='label_w_s mt-4'>Subject</label>
                    <input disabled={invoicetypeeditview.Edit === true} onChange={handleinvoiceiputchange} className="search_inp_filed in_tb" value={invoiceinput.invoiceTypeMailSubject} type="text" name="invoiceTypeMailSubject" id="" placeholder=""></input>

                    <label htmlFor="" className='label_w_s mt-4'>Body</label>
                    <ReactQuill onChange={handleQuillChange} disabled={invoicetypeeditview.Edit === true} value={invoiceinput.invoiceTypeMailBody} theme="snow" />

                </div>
                {
                    invoicetypeeditview.Edit === false &&

                    <div className="p_T_c_btn mt-4 mb-4"><button className="grn_btn" onClick={handleinvoicetypeeditsaveinput}>Save</button><button onClick={invoicetypecancel} className="diable_btn_edit">Cancel</button></div>
                }
            </div>

            <div style={{ padding: '10px' }}>
                <table className='table_c__M'>
                    <thead>
                        <th>S.No</th>
                        <th>Financial Year</th>
                        <th>Last Used Invoice Number</th>
                        <th>Last Used Credit Note Number</th>

                    </thead>
                    <tbody>
                        {
                            invoicetypegst && invoicetypegst.map((invoice, invoiceuq) => (
                                <tr key={invoiceuq}>
                                    <td>{invoiceuq + 1}</td>
                                    <td>{invoice.financialYear}</td>
                                    <td>{invoice.invCurrentNo}</td>
                                    <td>{invoice.invNoteCurrentNo}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Invoice_type_edit