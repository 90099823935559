import React, { useContext, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
// import Pending_approvel from './Invoice/Pending_approvel/Pending_approvel'
import Invoice_approved from './Invoice/Invoice_Approved/Invoice_approved'
import Invoice_rejected from './Invoice/Invoice_Rejected/Invoice_rejected'
import Invoice_bulk from './Invoice/Invoice_Bulk/Invoice_bulk'
import All_invoice from './Invoice/All_invoice/All_invoice'
import Credit_note_p_a from './Credit_note/Credit_Note_pending.jsx/Credit_note_p_a'
import Credit_note_approved from './Credit_note/Credit_note_approved/Credit_note_approved'
import Credit_note_E_L from './Credit_note/Credit_note_email_list/Credit_note_E_L'
import Supplier_config from './Configuration_content/Supplier_configration/Supplier_config'
import Payment_Trems_config from './Configuration_content/Payment_Trean_config/Payment_Trems_config'
import State_code_config from './Configuration_content/State_code_config/State_code_config'
import TCS from './Configuration_content/TCS/TCS'
import Item_master_main_table from './Configuration_content/Item_master/Item_master_main_table'
import Unit_measurement_main_table from './Configuration_content/Unit_measurement/Unit_measurement_main_table'
import Invoice_type_config from './Configuration_content/Invoice_type_config/Invoice_type_config'
import User_master from './User_Master/User_master'
import Reports from './Reports/Reports'
import Invoice_email_list from './Invoice/Invoice_Email_list/Invoice_email_list'
import All_credit_note from './Credit_note/All_credit_note/All_credit_note'
import Customer_master_main_table from './Customer_master/Customer_master_main_table'
import All_proforma_invoice from './Proforma_invoices/All_proforma_invoice/All_proforma_invoice'
import Performa_Email_list from './Proforma_invoices/Performa_Email_list/Performa_Email_list'
import Performa_invoice_approved from './Proforma_invoices/Performa_invoice_approved/Performa_invoice_approved'
import Performa_invoice_rejected from './Proforma_invoices/Proforma_invoice_rejected/Performa_invoice_rejected'
import Performa_pending_approvel from './Proforma_invoices/Performa_pending_approvel/Performa_pending_approvel'
import Pending_approvel_new from './Invoice/Pending_approvel/Pending_approvel_new'
import { E_invoice_context } from '../../E_invoice_context'
import Page_not_found from './Page_not_found'



const Table_route = () => {
    const { Modules,InvalidRouteHandler } = useContext(E_invoice_context)
   

    return (
        <div>
            <Routes>
                {
                    // Invoicing
                    (Modules && Modules.includes(6) || Modules && Modules.includes(0)) &&
                    <>
                        {/* All Invoice */}
                        <Route exact path="All_invoice" element={<All_invoice />} />
                        <Route exact path="Pending_approvel" element={<Pending_approvel_new />} />
                        <Route exact path="Invoice_approved" element={<Invoice_approved />} />
                        <Route exact path="Invoice_rejected" element={<Invoice_rejected />} />
                        <Route exact path="Invoice_email_ls" element={<Invoice_email_list />} />
                        <Route exact path="Invoice_bulk" element={<Invoice_bulk />} />

                        {/* credit note */}
                        <Route exact path="All_credit_n" element={<All_credit_note />} />
                        <Route exact path="Credit_note_p_a" element={<Credit_note_p_a />} />
                        <Route exact path="Credit_note_approved" element={<Credit_note_approved />} />
                        <Route exact path="Credit_note_E_L" element={<Credit_note_E_L />} />
                    </>
                }

                {
                    (Modules && Modules.includes(7) || Modules && Modules.includes(0)) &&
                    <>
                        <Route exact path="All_proforma_invoice" element={<All_proforma_invoice />} />
                        <Route exact path="Performa_Email_list" element={<Performa_Email_list />} />
                        <Route exact path="Performa_invoice_approved" element={<Performa_invoice_approved />} />
                        <Route exact path="Performa_invoice_rejected" element={<Performa_invoice_rejected />} />
                        <Route exact path="Performa_pending_approvel" element={<Performa_pending_approvel />} />
                    </>
                }

                {
                    // Supplier Configuration
                    (Modules && Modules.includes(2) || Modules && Modules.includes(0)) &&
                    <>
                        <Route exact path="Supplier_config" element={<Supplier_config />} />
                    </>
                }

                {
                    // Admin
                    Modules && Modules.includes(0) &&
                    <>
                        <Route exact path="Payment_Trems_config" element={<Payment_Trems_config />} />
                        <Route exact path="State_code_config" element={<State_code_config />} />
                        <Route exact path="TCS" element={<TCS />} />
                    </>
                }

                {
                    // Item Master
                    (Modules && Modules.includes(4) || Modules && Modules.includes(0)) &&
                    <>
                        <Route exact path="Item_master_main_table" element={<Item_master_main_table />} />
                    </>
                }

                {
                    // Unit MeasureMent Configuration
                    (Modules && Modules.includes(5) || Modules && Modules.includes(0)) &&
                    <>
                        <Route exact path="Unit_measurement_main_table" element={<Unit_measurement_main_table />} />
                    </>
                }

                {
                    // Invoice Type Configuration
                    (Modules && Modules.includes(3) || Modules && Modules.includes(0)) &&
                    <>
                        <Route exact path="Invoice_type_config" element={<Invoice_type_config />} />
                    </>
                }

                {
                    // Admin
                    Modules && Modules.includes(0) &&
                    <>
                        <Route exact path="User_master" element={<User_master />} />
                        <Route exact path="Reports" element={<Reports />} />
                    </>
                }

                {
                    // Customer Master
                    (Modules && Modules.includes(1) || Modules && Modules.includes(0)) &&
                    <>
                        <Route exact path="Customer_master" element={<Customer_master_main_table />} />
                    </>
                }
                <Route path="*" element={<InvalidRouteHandler />} />
            </Routes>
        </div >
    )
}
export default Table_route