import React, { useContext, useEffect } from 'react'
import All_invoice_main_table from '../../Invoice/All_invoice/All_invoice_main_table'
import { E_invoice_context } from '../../../../E_invoice_context'
import All_create_invoice from '../../Invoice/All_invoice/All_create_invoice'
import All_invoice_view from '../../Invoice/All_invoice/All_invoice_view'
import Customer_master_cms from '../../Invoice/All_invoice/Customer_master_cms'
import Customer_master from '../../Invoice/All_invoice/Customer_master'
import Non_CMS_Customer_Create from '../../Invoice/All_invoice/Non_CMS_Customer_Create'
import { useNavigate } from 'react-router-dom'

const All_proforma_invoice = () => {
    const { sessionEndTime, SetShow, setIsProfoma, proformaAccess, proforma, itemdropdown, setMailCheckMark, noncmsbilladdress, setContractCustomer,
        Editboxaddressbill, setAllinvoicepagination, setPanValue, InvReqObj, setInvReqObj, show, Getstatecodeconfig, nonCMS, GetCustomer,
        Allinvoicepagination, setAllCheckMark, decryptData, Getinvoicetype } = useContext(E_invoice_context)


    useEffect(() => {
        if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
            (sessionEndTime < new Date())) {
            document.getElementById('go_to_logintime_approved').click();
        }
        Getinvoicetype();
    }, [])

    const Navigate = useNavigate()

    const Logout = () => {
        sessionStorage.clear()
        Navigate('/');
    }

    var invoiceTypeDown = []
    var ViewTypeDown = []
    var MakerTypeDown = []

    if (proforma && proforma.length !== 0) {
        invoiceTypeDown = itemdropdown && itemdropdown
            .filter(item => proforma.includes(item.invoiceTypeConfigurationIdentifier))
            .filter(item => proformaAccess && proformaAccess.some(rec => item.InvoiceTypeConfigurationAutoId === rec.InvoiceTypeConfigurationAutoId && (rec.makerFlag === true || rec.viewFlag === true)));

        ViewTypeDown = itemdropdown && itemdropdown
            .filter(item => proforma.includes(item.invoiceTypeConfigurationIdentifier))
            .filter(item => proformaAccess && proformaAccess.some(rec => item.InvoiceTypeConfigurationAutoId === rec.InvoiceTypeConfigurationAutoId && (rec.viewFlag === true)));

        MakerTypeDown = itemdropdown && itemdropdown
            .filter(item => proforma.includes(item.invoiceTypeConfigurationIdentifier))
            .filter(item => proformaAccess && proformaAccess.some(rec => item.InvoiceTypeConfigurationAutoId === rec.InvoiceTypeConfigurationAutoId && (rec.makerFlag === true)));
    }
    else {
        invoiceTypeDown = itemdropdown
        ViewTypeDown = itemdropdown
        MakerTypeDown = itemdropdown
    }

    useEffect(() => {
        setInvReqObj({
            ...InvReqObj,
            "search": "",
            "currentPage": 1,
            "pageSize": 10,
            "invoiceTypeIdentifier": "",
            "supplierIdentifier": "",
            "customerType": "",
            "customerIdentifier": "",
            "statusFlag": 1,
            "isMail": false,
            "isCreditNote": false,
            "isProforma": true,
            "sortField": "invoiceAutoId",
            "descFlag": true
        })
        setAllCheckMark(false)
        setMailCheckMark([])
        setIsProfoma(true)
        setAllinvoicepagination({ ...Allinvoicepagination, Current_page: Allinvoicepagination.Current_page = 1 })
        setPanValue('')
        setContractCustomer({})
    }, [])

    useEffect(() => {
        Getstatecodeconfig();
        GetCustomer(1, '');
        noncmsbilladdress();
    }, [nonCMS, Allinvoicepagination, Editboxaddressbill])

    return (
        <div>
            <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />
            <input type='button' hidden id='go_to_logintime_approved' data-bs-toggle="modal" data-bs-target="#go_to_login_approved" />

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_approved" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Please Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Please Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className=''>
                {
                    show && show === 1 ? (<>
                        <div className='h4_btm_bdr'>
                            {
                                show && show === 1 ? ('') : (<i onClick={() => { SetShow(1); setPanValue(''); }} className="fa-solid fa-circle-arrow-left"></i>)
                            }
                            <h4>Proforma Approved</h4>
                        </div>
                        <All_invoice_main_table
                            isAll={false}
                            isCredit={false}
                            isApproved={true}
                            isMail={true}
                            invoiceTypeDown={invoiceTypeDown} /></>
                    ) : (
                        <>
                            {
                                show === 2 ? (
                                    <>
                                        <div className='h4_btm_bdr'>
                                            {
                                                show && show === 1 ? ('') : (<i onClick={() => { SetShow(1); setPanValue(''); }} className="fa-solid fa-circle-arrow-left"></i>)
                                            }
                                            <h4>Proforma Approved</h4>
                                        </div>
                                        <All_create_invoice MakerTypeDown={MakerTypeDown} /></>
                                ) : (
                                    <>
                                        {
                                            show === 3 ? (
                                                <Customer_master />
                                            ) : (
                                                <>
                                                    {
                                                        show === 4 ? (<>
                                                            <div className='h4_btm_bdr'>
                                                                {
                                                                    show && show === 1 ? ('') : (<i onClick={() => { SetShow(1); setPanValue(''); }} className="fa-solid fa-circle-arrow-left"></i>)
                                                                }
                                                                <h4>Proforma Approved</h4>
                                                            </div>
                                                            <All_invoice_view ViewTypeDown={ViewTypeDown} MakerTypeDown={MakerTypeDown} />
                                                        </>
                                                        ) : (
                                                            <>
                                                                {
                                                                    show === 5 ? (
                                                                        <Non_CMS_Customer_Create />
                                                                    ) : (
                                                                        <>
                                                                            {
                                                                                show === 6 ? (
                                                                                    <Customer_master_cms />
                                                                                ) : ('')
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </>
                                                        )
                                                    }
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default All_proforma_invoice