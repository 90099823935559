import React from 'react'

const Create_supply = ({ view, Statedropdown, setview, handle_view_change, intialst, handleKeyPress, create_cancel_btn, Create_save_btn, suplierstatecode }) => {
    // console.log(intialst.supplierGSTIN, view.create_sup_gst_err)
    return (
        <div className='create_content'>
            <div className='top_view_tab'>
                <div className='l_view_tab'>
                    <i onClick={() => setview({ ...view, create_sup: view.create_sup = true })} className="fa-solid fa-circle-arrow-left left_arrow"></i>
                    <p className='arrow_txt'>Create Supplier</p>
                </div>
            </div>
            <div className='view_tab_innr_cnt'>
                <div className='row'>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="supplierTradeName">Supplier Trade Name <span className="mandatory_star">*</span></label>
                            <input className='view_tab_inp' type="text" name='supplierTradeName' id='supplierTradeName' onChange={handle_view_change} value={intialst.supplierTradeName} />
                            <div className="invalid-feedback" style={view.create_sup_err === true && intialst.supplierTradeName === '' ? { display: 'block' } : { display: 'none' }}>
                                Please provide valid information for this field.
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="supplierLegalName">Supplier Legal Name <span className="mandatory_star">*</span></label>
                            <input className='view_tab_inp' type="text" id='supplierLegalName' name='supplierLegalName' onChange={handle_view_change} value={intialst.supplierLegalName} />
                            <div className="invalid-feedback" style={view.create_sup_err === true && intialst.supplierLegalName === '' ? { display: 'block' } : { display: 'none' }}>
                                Please provide valid information for this field.
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="supplierPANNumber">Supplier PAN Number <span className="mandatory_star">*</span></label>
                            <input className='view_tab_inp' type="text" id='supplierPANNumber' name='supplierPANNumber' onChange={handle_view_change} value={intialst.supplierPANNumber} />
                            {
                                view.create_sup_err === true &&

                                <div className="invalid-feedback" style={intialst.supplierPANNumber === '' || view.create_pan_err === false ? { display: 'block' } : { display: 'none' }}>
                                    Please provide valid information for this field.
                                </div>
                            }

                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="supplierGSTIN">Supplier GSTIN <span className="mandatory_star">*</span></label>
                            <input className='view_tab_inp' type="text" name='supplierGSTIN' id='supplierGSTIN' onChange={handle_view_change} value={intialst.supplierGSTIN} />
                            {
                                view.create_sup_err === true &&
                                
                                <div className="invalid-feedback" style={intialst.supplierGSTIN === '' || view && view.create_sup_gst_err === false ? { display: 'block' } : { display: 'none' }}>
                                    Please provide valid information for this field.
                                </div>
                            }

                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="supplierBuildingNumber">Supplier Building Number</label>
                            <input className='view_tab_inp' id='supplierBuildingNumber' name='supplierBuildingNumber' type="text" onChange={handle_view_change} value={intialst.supplierBuildingNumber} />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="supplierBuildingName">Supplier Building Name</label>
                            <input className='view_tab_inp' type="text" id='supplierBuildingName' name='supplierBuildingName' onChange={handle_view_change} value={intialst.supplierBuildingName} />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="supplierLocation">Supplier Location</label>
                            <input className='view_tab_inp' type="text" id='supplierLocation' name='supplierLocation' onChange={handle_view_change} value={intialst.supplierLocation} />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="supplierDistrict">Supplier District</label>
                            <input className='view_tab_inp' type="text" name='supplierDistrict' id='supplierDistrict' onChange={handle_view_change} value={intialst.supplierDistrict} />
                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="supplierPincode">Supplier Pincode</label>
                            <input className='view_tab_inp' type="text" id='supplierPincode' name='supplierPincode' onChange={handle_view_change} value={intialst.supplierPincode} />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="supplierStateCode">Supplier State Code</label>
                            <select className='view_tab_select' name='supplierStateCode' value={intialst.supplierStateCode} onChange={handle_view_change}>
                                <option value="">Select</option>
                                {suplierstatecode.map((item) => (
                                    <option key={item.userAutoID} value={item.stateIdentifier}>
                                        {item.stateName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="supplierPhone">Supplier Phone</label>
                            <input className='view_tab_inp' type="text" id='supplierPhone' name='supplierPhone' onChange={handle_view_change} value={intialst.supplierPhone} />
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-2 col-12'>
                        <div className="form_group">
                            <label className='view_tab_label' htmlFor="supplierEmail">Supplier Email</label>
                            <input className='view_tab_inp' type="text" id='supplierEmail' name='supplierEmail' onChange={handle_view_change} value={intialst.supplierEmail} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='create_sup_btn'>
                <button onClick={Create_save_btn} className='con_btn_edit'>Save</button>
                <button onClick={create_cancel_btn} className='diable_btn_edit'>Cancel</button>
            </div>
        </div>
    )
}

export default Create_supply