import React, { useState } from 'react'

const Pagination = ({ Paginations, handlePageChange, LastPage }) => {

    const pageNumbers = [];
    const maxPageDisplay = 5; // Set the maximum number of pages to display

    for (let i = Math.max(1, Paginations.Current_page - 1); i <= Math.min(LastPage, Paginations.Current_page + 4); i++) {
        pageNumbers.push(i);
    }

    return (
        <div>
            <div className='pagination_div'>
                <ul className="pagination">
                    <li className="page-item"><button disabled={Paginations.Current_page === 1} onClick={() => handlePageChange(1)} className="page-link"><i className="fa fa-angle-double-left"></i></button></li>
                    <li className="page-item"><button disabled={Paginations.Current_page === 1} onClick={() => handlePageChange(Paginations.Current_page - 1)} className="page-link"><i className="fa fa-angle-left"></i></button></li>
                    {pageNumbers.map((pageNumber) => (
                        <li key={pageNumber} className={pageNumber === Paginations.Current_page ? `page-item active` : 'page-item'}>
                            <button
                                onClick={() => handlePageChange(pageNumber)}
                                className="page-link"
                            >
                                {pageNumber}
                            </button>
                        </li>
                    ))}
                    <li className="page-item"><button disabled={Paginations.Current_page === LastPage} onClick={() => handlePageChange(Paginations.Current_page + 1)} className="page-link"><i className="fa fa-angle-right"></i></button></li>
                    <li className="page-item"><button disabled={Paginations.Current_page === LastPage} onClick={() => handlePageChange(LastPage)} className="page-link"><i className="fa fa-angle-double-right"></i></button></li>
                </ul>
            </div>
        </div>
    )
}

export default Pagination