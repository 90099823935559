import React, { useContext, useState } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'
import AuthService from '../../../../Service/Authservice'

const Create_state_code_view = ({ stateviewdata }) => {
    const { statecodevald, setstatecodevald, setstateconfig, statenamecode, handlestatenamecodechange, GetStateconfigdata } = useContext(E_invoice_context)
    const [stateedit, setstateedit] = useState(true)
    const identi = stateviewdata.stateIdentifier
    const handle_edit_submitstate = () => {
        if (statenamecode.stateName.length > 100 || statenamecode.stateName == "") {

            setstatecodevald(false);
        }
        if (statenamecode.stateCode.length > 100 || statenamecode.stateCode == "") {

            setstatecodevald(false);
        }
        else {
            AuthService.PostServiceCall(`api/StateCodes/StateCodeUpdate?StateIdentifier=${identi}`, statenamecode)
                .then((res) => {
                    // console.log(res)
                    setstateconfig(1)
                    GetStateconfigdata();
                    setstatecodevald(true);
                    document.getElementById('model_triger_four').click();
                })
        }
    }
    const handlestateconfifdelete = () => {
        AuthService.PostServiceCall(`api/StateCodes/StateCodeDelete?StateIdentifier=${identi}`)
            .then((res) => {
                // console.log(res)
                if (res.responseMessage === 'State Code is already used, cannot delete') {
                    document.getElementById('state_code_already').click();
                } else{
                    setstateconfig(1)
                    GetStateconfigdata();
                }
             
            })
    }

    const DeleteModal = () => {
        document.getElementById('model_triger_delete').click();
    }
    return (
        <div>
            <div className='creat_c_m c_s_c'>
                <i onClick={() => setstateconfig(1)} className="fa-solid fa-circle-arrow-left"></i>
                <p style={{ marginBottom: '0', color: '#c95643', fontSize: '14px' }}>State Code Configuration</p>
                <div style={{ display: 'flex', justifyContent: 'end', gap: '10px', width: "79%" }}>
                    {
                        stateedit === true &&
                        <button className="grn_btn" onClick={() => setstateedit(false)}>Edit</button>
                    }

                    <button className="diable_btn_edit" onClick={DeleteModal}>Delete</button>
                </div>
            </div>
            <div className='row mt-3 mb-3'>
                <div className='col-lg-4'>
                    <label htmlFor="" className='label_w_s'>State Name<span className="mandatory_star2">*</span></label>
                    <input disabled={stateedit === true} className="search_inp_filed in_tb" type="text" name="stateName" value={statenamecode.stateName} onChange={handlestatenamecodechange} id="stateName" placeholder="" ></input>
                    {
                        statecodevald === false &&
                        <p style={{ marginBottom: 0, fontSize: '12px', color: "red" }}>{statenamecode.stateName === "" || statenamecode.stateName.length > 0 ? ('Please Provide valid Information & Cannot exceed 100 characters') : ('')}</p>
                    }
                </div>
                <div className='col-lg-4'>
                    <label htmlFor="" className='label_w_s'>State Code<span className="mandatory_star2">*</span></label>
                    <input disabled={stateedit === true} className="search_inp_filed in_tb" type="text" name="stateCode" value={statenamecode.stateCode} onChange={handlestatenamecodechange} id="stateCode" placeholder=""></input>
                    {
                        statecodevald === false &&
                        <p style={{ marginBottom: 0, fontSize: '12px', color: "red" }}>{statenamecode.stateCode === "" || statenamecode.stateCode.length > 0 ? ('Please Provide valid Information & Cannot exceed 100 characters') : ('')}</p>
                    }
                </div>
            </div>
            <input type="text" id='model_triger_delete' hidden data-bs-toggle="modal" data-bs-target="#model_one_delete" />
            <div className="modal fade mdl_e_fade" id="model_one_delete" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Are you sure you want to delete this?
                        </div>
                        <div className="modal-footer">
                            <button type="button" onClick={handlestateconfifdelete} className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                            <button type="button" className="btn mdl_btn_cancel" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type="text" id='state_code_already' hidden data-bs-toggle="modal" data-bs-target="#state_code_already_model" />
            <div className="modal fade mdl_e_fade" id="state_code_already_model" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                        State code already in use cannot be deleted.
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className='p_T_c_btn'>
                {
                    stateedit === false &&
                    <button className="grn_btn" onClick={handle_edit_submitstate}>Save</button>
                }

                <button className="diable_btn_edit" onClick={() => setstateconfig(1)}>Cancel</button>
            </div>
        </div>
    )
}

export default Create_state_code_view