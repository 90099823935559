import React from 'react'
const Invoice_email_view = ({ EmailViewData }) => {
    return (
        <div>
            <div className='total_outfit_fr_tab'>
                <div className='top_hd_aci'>
                    <p><b>Invoice No: </b> <span>{EmailViewData && EmailViewData.invoiceSerialNumber}</span></p>
                    <p><b>Invoice Date: </b><span>{EmailViewData && EmailViewData.invoiceDate}</span></p>
                </div>
                <div className="accordion accordion-flush" id="accordionFlushExample">
                    <div className="accordion-item acc_item">
                        <button className="accordion-button All_c_i_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                            <span>Supplier Details</span><i className="fa-solid fa-angle-down"></i>
                        </button>
                        <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body All_c_i_acc_bd">
                                <label className='label_w_s' htmlFor="a_c_i_sel">Invoice Type</label>
                                <select disabled style={{ width: '200px' }} id='a_c_i_sel' className="select_inp_filed" aria-label="Default select example">
                                    <option value=''>{EmailViewData && EmailViewData.invoiceType}</option>
                                    <option value="1">One</option>
                                    <option value="2">Two</option>
                                    <option value="3">Three</option>
                                </select>
                            </div>
                            <div className='supplier_details'>
                                <h6>Supplier Details</h6>
                                <div className='row'>
                                    <div className='col-lg-3 col-12'>
                                        <label htmlFor="">Supplier Name</label>
                                        <p>{EmailViewData && EmailViewData.supplierLegalName}</p>
                                    </div>
                                    <div className='col-lg-3 col-12'>
                                        <label htmlFor="">Supplier Address</label>
                                        <p>{EmailViewData && EmailViewData.supplierLocation}</p>
                                    </div>
                                    <div className='col-lg-3 col-12'>
                                        <label htmlFor="">Supplier Pan Number</label>
                                        <p>{EmailViewData && EmailViewData.supplierPan}</p>
                                    </div>
                                    <div className='col-lg-3 col-12'>
                                        <label htmlFor="">Supplier GST</label>
                                        <p>{EmailViewData && EmailViewData.supplierGstin}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="accordion-item acc_item">
                        <button className="accordion-button All_c_i_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                            <span>Customer Details</span><i className="fa-solid fa-angle-down"></i>
                        </button>
                        <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body All_c_i_acc_bd">
                                <div className='row'>
                                    <div className='col-lg-6 col-12 '>
                                        <label className='label_w_s' htmlFor="a_c_i_sel">Search Customer Details</label>
                                        <div className='c_d_sept'>
                                            <input id='' disabled className="search_inp_filed" value={EmailViewData && EmailViewData.customerMasterTradeName} type="text" name="" placeholder=""></input>
                                        </div>
                                    </div>
                                </div>
                                <div className='row c_d_invoice'>
                                    <div className='col-lg-5 col-12'>
                                        <span>Bill To Address</span>
                                        <div className='row c_d_inoice_on'>
                                            <div className='col-lg-5 col-12'>
                                                <label htmlFor="">Customer Legal Name</label>
                                                <p>{EmailViewData && EmailViewData.customerMasterTradeName}</p>

                                                <label htmlFor="">Customer Phone</label>
                                                <p>{EmailViewData && EmailViewData.customerPhone}</p>
                                            </div>
                                            <div className='col-lg-7 col-12'>
                                                <label htmlFor="">Customer GST</label>
                                                <p>{EmailViewData && EmailViewData.customerGst}</p>

                                                <label htmlFor="">Customer Email</label>
                                                <p>{EmailViewData && EmailViewData.customerEmail}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-5 col-12 ship_add_col'>
                                        <span>Ship To Address</span>
                                        <div className='row c_d_inoice_tw'>
                                            <div className='col-lg-6 col-12'>
                                                <label htmlFor="">GST</label>
                                                <p>{EmailViewData && EmailViewData.shipGst}</p>

                                                <label htmlFor="">Email</label>
                                                <p>{EmailViewData && EmailViewData.shipEmail}</p>
                                            </div>
                                            <div className='col-lg-6 col-12'>
                                                <label htmlFor="">Phone</label>
                                                <p>{EmailViewData && EmailViewData.shipPhone}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-12 c_d_inoice_tr'>
                                        <div>
                                            <label htmlFor="">
                                                {EmailViewData && EmailViewData.shipGst === null || EmailViewData && EmailViewData.shipGst === "" || EmailViewData && EmailViewData.shipGst === undefined ? "B2C" : "B2B"}</label>
                                            <p>{EmailViewData && EmailViewData.shipGst === null || EmailViewData && EmailViewData.shipGst === "" || EmailViewData && EmailViewData.shipGst === undefined ? "UnRegisterd" : "Registerd"}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="accordion-item acc_item">
                        <button className="accordion-button All_c_i_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseTwo">
                            <span>Customer Contract Details</span><i className="fa-solid fa-angle-down"></i>
                        </button>
                        <div id="flush-collapsefour" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body All_c_i_acc_bd">
                                <div className='row'>
                                    <div className='col-lg-6 col-12 '>
                                        <label className='label_w_s' htmlFor="a_c_i_sel">Search Customer Details</label>
                                        <div className='c_d_sept'>
                                            <input id='' disabled className="search_inp_filed" value='Sreenivas K' type="text" name="" placeholder=""></input>
                                        </div>
                                    </div>
                                </div>
                                <div className='row c_d_invoice'>
                                    <div className='col-lg-5 col-12'>
                                        <span>Bill To Address</span>
                                        <div className='row c_d_inoice_on'>
                                            <div className='col-lg-5 col-12'>
                                                <label htmlFor="">Customer Legal Name</label>
                                                <p>Sreenivas K</p>

                                                <label htmlFor="">Customer Phone</label>
                                                <p>99402942193</p>
                                            </div>
                                            <div className='col-lg-7 col-12'>
                                                <label htmlFor="">Customer GST</label>
                                                <p>WFSD453T4FSD34</p>

                                                <label htmlFor="">Customer Email</label>
                                                <p>sreenivas.k@mercedes-benz.com</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-5 col-12 ship_add_col'>
                                        <span>Ship To Address</span>
                                        <div className='row c_d_inoice_tw'>
                                            <div className='col-lg-6 col-12'>
                                                <label htmlFor="">GST</label>
                                                <p>WFSD453T4FSD34</p>

                                                <label htmlFor="">Email</label>
                                                <p>sreenivas.k@mercedes-benz.com</p>
                                            </div>
                                            <div className='col-lg-6 col-12'>
                                                <label htmlFor="">Phone</label>
                                                <p>9940294923</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-2 col-12 c_d_inoice_tr'>
                                        <div>
                                            <span>B2B</span>
                                            <p>Registered</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="accordion-item acc_item">
                        <button className="accordion-button All_c_i_acc_btn collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                            <span>Invoice Details</span><i className="fa-solid fa-angle-down"></i>
                        </button>
                        <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                            <div className="accordion-body All_c_i_acc_bd">
                                <div className='row rw_all_ci_bd'>
                                    <div className='col-lg-3 col-12'>
                                        <p className='i_d_head'>Invoice Item</p>
                                        <select disabled style={{ width: '100%' }} className="select_inp_filed" aria-label="Default select example">
                                            <option selected>{EmailViewData && EmailViewData.invoiceItemName}</option>
                                            {/* <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option> */}
                                        </select>
                                    </div>
                                    <div className='col-lg-9 col-12'>
                                        <p className='i_d_head'>Description of Goods / Service</p>
                                        <textarea disabled className='input_txt_are' value={EmailViewData && EmailViewData.invoiceItemDesctiption}></textarea>
                                    </div>
                                </div>
                                <div className='row rw_all_ci_tw'>
                                    <div className='col-lg-3 col-12 amt_serch'>
                                        <label htmlFor="amount">Amount</label>
                                        <input style={{ width: '90px' }} id='amount' className="search_inp_filed" type="text" name="" placeholder="" disabled value={EmailViewData && EmailViewData.amountInput}></input>
                                    </div>
                                    <div className='col-lg-9 col-12 amt_radio'>
                                        <div>
                                            <input disabled id='tax_val' className="radio_all_c" type="radio" name="" placeholder=""></input>
                                            <label htmlFor="tax_val">Taxable Value</label>
                                        </div>
                                        <div>
                                            <input disabled id='amt_val' className="radio_all_c" type="radio" name="" placeholder=""></input>
                                            <label htmlFor="amt_val">Amount(Incl. GST & TCS)</label>
                                        </div>
                                        <div>
                                            <input disabled id='amt_inc' className="radio_all_c" type="radio" name="" placeholder=""></input>
                                            <label htmlFor="amt_inc">Amount(Incl. GST & No TCS)</label>
                                        </div>
                                        <div>
                                            <input disabled id='amt_exc' className="radio_all_c" type="radio" name="" placeholder=""></input>
                                            <label htmlFor="amt_exc">Amount(Incl. GST & Excl. TCS)</label>
                                        </div>
                                    </div>
                                </div>
                                <div className='row rw_all_ci_tr'>
                                    <div className='col-lg-10 col-12'>
                                        <table className='inc_amt_table'>
                                            <tr>
                                                <th>HSN/SAC <br /> Code</th>
                                                <th>Taxable Value <br /> (INR)</th>
                                                <th>Unit of <br /> Measurement</th>
                                                <th>Unit <br /> Price</th>
                                                <th>Value <br /> (INR)</th>
                                            </tr>
                                            <tr>
                                                <td><input id='' disabled className="search_inp_filed in_tb" value={EmailViewData && EmailViewData.invoiceHsncode} type="text" name="" placeholder=""></input></td>
                                                <td><input id='' disabled className="search_inp_filed in_tb" value='444' type="text" name="" placeholder=""></input></td>
                                                <td> <select disabled id='' className="select_inp_filed in_tb" aria-label="Default select example">
                                                    <option selected>{EmailViewData && EmailViewData.unitMeasurementName}</option>
                                                    <option value="1">One</option>
                                                    <option value="2">Two</option>
                                                    <option value="3">Three</option>
                                                </select>
                                                </td>
                                                <td><input id='' disabled className="search_inp_filed in_tb" value={EmailViewData && EmailViewData.invoiceUnitPrice} type="text" name="" placeholder=""></input></td>
                                                <td><input id='' disabled className="search_inp_filed in_tb" value={EmailViewData && EmailViewData.invoiceValue} type="text" name="" placeholder=""></input></td>
                                            </tr>
                                        </table>
                                        <table className='inc_amt_table_tw'>
                                            <thead>
                                                <tr className='top_te_head'>
                                                    <th colspan="2">CGST</th>
                                                    <th colspan="2">SGST</th>
                                                    <th colspan="2">IGST</th>
                                                    <th colspan="2">Cess</th>
                                                </tr>
                                                <tr className='top_tw_head'>
                                                    <th>Rate %</th>
                                                    <th>Amount</th>
                                                    <th>Rate %</th>
                                                    <th>Amount</th>
                                                    <th>Rate %</th>
                                                    <th>Amount</th>
                                                    <th>Rate %</th>
                                                    <th>Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><input id='' disabled className="search_inp_filed in_tb" value={EmailViewData && EmailViewData.invoiceCgstrate} type="text" name="" placeholder=""></input></td>
                                                    <td><input id='' disabled className="search_inp_filed in_tb" value={EmailViewData && EmailViewData} type="text" name="" placeholder=""></input></td>
                                                    <td><input id='' disabled className="search_inp_filed in_tb" value={EmailViewData && EmailViewData} type="text" name="" placeholder=""></input></td>
                                                    <td><input id='' disabled className="search_inp_filed in_tb" value={EmailViewData && EmailViewData} type="text" name="" placeholder=""></input></td>
                                                    <td><input id='' disabled className="search_inp_filed in_tb" value={EmailViewData && EmailViewData} type="text" name="" placeholder=""></input></td>
                                                    <td><input id='' disabled className="search_inp_filed in_tb" value={EmailViewData && EmailViewData} type="text" name="" placeholder=""></input></td>
                                                    <td><input id='' disabled className="search_inp_filed in_tb" value={EmailViewData && EmailViewData} type="text" name="" placeholder=""></input></td>
                                                    <td><input id='' disabled className="search_inp_filed in_tb" value={EmailViewData && EmailViewData} type="text" name="" placeholder=""></input></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className='col-lg-2 col-12 table_amt_ttl'>
                                        <div>
                                            <p className='amt_wht'>Total Value</p>
                                            <p className='amt_dig'>{EmailViewData && EmailViewData.invoiceTotalValue}</p>
                                        </div>
                                        <div>
                                            <p className='amt_wht'>TCS</p>
                                            <p className='amt_dig'>0</p>
                                        </div>
                                        <div>
                                            <p className='amt_wht'>Total Value</p>
                                            <p className='amt_dig'>{EmailViewData && EmailViewData.invoiceTotalValue}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='amt_last_para'>
                                    <p >Tax Is Payable On Reverse Charge</p>
                                    <select id='' style={{ width: '60px' }} className="select_inp_filed" aria-label="Default select example">
                                        <option selected>No</option>
                                        <option value="1">Yes</option>
                                    </select>
                                </div>
                                <div className='amt_last_para_tw'>
                                    <p >Total Invoice Value (In Figures) : <span><b>{EmailViewData && EmailViewData.invoiceTotalValue}</b></span></p>
                                </div>
                                <div className='amt_last_para_tr'>
                                    <p >Total Invoice Value (In Words) : <span><b>Three Thousand Three Hundred Twenty</b></span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='a_c_i_btm_btn'>
                    <div>
                        <button className='grn_btn'>Go Back</button>
                    </div>

                </div>
            </div>
        </div>
    )

}
export default Invoice_email_view
