import React, { useEffect } from 'react'
import { useContext } from 'react';
import { useState } from "react";
import { E_invoice_context } from '../../../E_invoice_context';
import AuthService from '../../../Service/Authservice';
import { useNavigate } from 'react-router-dom';
const User_view = ({ setUserPages }) => {
    const { usemaster_config, InvoiceTypes, setInvoiceTypes, ProfomaTypes, setProfomaTypes, UserView,
        GetUserRecords, setedituser, Edituser, validateemail, userfielderr, setuserfielderr } = useContext(E_invoice_context)
    const navigate = useNavigate();
    // console.log(UserView);
    // console.log(usemaster_config)

    var newdata = {
        "1": {
            type: false,
            Setnumber: 1
        },
        "2": {
            type: false,
            Setnumber: 2
        },
        "3": {
            type: false,
            Setnumber: 3
        },
        "4": {
            type: false,
            Setnumber: 4
        },
        "5": {
            type: false,
            Setnumber: 5
        },
        "6": {
            type: false,
            Setnumber: 6
        },
        "7": {
            type: false,
            Setnumber: 7
        }
    };

    // console.log(UserView.invoiceModuleAccess)
    UserView.invoiceModuleAccess.forEach((access, i) => {
        // console.log(`newdata[${access}]`, newdata[access]);
        if (newdata[access]) {
            newdata[access].type = true;
        }
    });

    const [isChecked, setIsChecked] = useState(newdata);
    const handleCheckboxChange = (key) => {
        // console.log(key);
        setIsChecked({
            ...isChecked,
            [key]: {
                ...isChecked[key],
                type: !isChecked[key].type
            }
        });
    };

    var InvoiceTypeAccess = []
    var ProfomaTypeAccess = []

    const trueTypeSetnumbers = Object.keys(isChecked)
        .filter((key) => isChecked[key].type)
        .map((key) => isChecked[key].Setnumber);

    const [Usermaster, Setusermaster] = useState(UserView)

    const handle_view_change_user_master = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        Setusermaster({ ...Usermaster, [name]: value })
        if (name === 'emailId') {
            validateemail(value);
        }
    }

    const handle_user_master = () => {
        setuserfielderr({ ...userfielderr, fielderr: false })
        if (Usermaster.userName === '' || Usermaster.emailId === '' || Usermaster.departmentName === '' || userfielderr.emailerr === false) {
            setuserfielderr({ ...userfielderr, fielderr: true })
        } else {
            for (var key in InvoiceTypes) {
                if (Object.values(InvoiceTypes[key]).some(flag => flag === true)) {
                    InvoiceTypeAccess = [...InvoiceTypeAccess, InvoiceTypes[key]];
                }
            }
            for (var key in ProfomaTypes) {
                if (Object.values(ProfomaTypes[key]).some(flag => flag === true)) {
                    ProfomaTypeAccess = [...ProfomaTypeAccess, ProfomaTypes[key]];
                }
            }
            var UserObj = {
                "identifier": Usermaster.identifier,
                "userName": Usermaster.userName,
                "emailId": Usermaster.emailId,
                "userPassword": Usermaster.userPassword,
                "departmentName": Usermaster.departmentName,
                "invoiceTypeAccess": InvoiceTypeAccess,
                "profomaInvoice": ProfomaTypeAccess,
                "invoiceModuleAccess": trueTypeSetnumbers
            }

            AuthService.PostServiceCallToken('api/UserMasters/InsertUpdateUserMasters', UserObj)
                .then((res) => {
                    // console.log(res)
                    if (res.responseCode = "200") {
                        document.getElementById('usermaster_successfully').click();
                        setUserPages(1);
                        GetUserRecords();
                    }
                }).catch((err) => console.log("Error:", err));
        }
    }

    const handleInvoiceTypeAccess = (identifier, Type) => {
        setInvoiceTypes({
            ...InvoiceTypes,
            [identifier]: {
                ...InvoiceTypes[identifier],
                [Type]: !InvoiceTypes[identifier][Type]
            }
        })
    };

    const handleProfomaTypeAccess = (identifier, Type) => {
        setProfomaTypes({
            ...ProfomaTypes,
            [identifier]: {
                ...ProfomaTypes[identifier],
                [Type]: !ProfomaTypes[identifier][Type]
            }
        })
    };

    const handleDeleteUser = (identifier) => {
        AuthService.PostServiceCallToken(`api/UserMasters/DeleteUserMasterDetails?identifier=${identifier}`)
            .then((res) => {
                if (res.responseMessage == "success") {
                    document.getElementById('userDelete_successfully').click();
                    setUserPages(1);
                    GetUserRecords();
                }
            }).catch((err) => console.log("Error:", err));
    }
    const handle_delete_msg = () =>{
        document.getElementById('userDelete_are_u_want_msg').click();
    }
    return (
        <div>
            <div style={{ border: '1px solid #eaeaea', overflow: 'hidden' }}>
                <div className="creat_c_m c_s_c">
                    <i onClick={() => setUserPages(1)} className="fa-solid fa-circle-arrow-left"></i>
                    <p style={{ marginBottom: '0', fontSize: '14px', color: '#c95643' }}>View User</p>
                    <div style={{ display: 'flex', justifyContent: 'end', gap: '20px', width: '83%' }}>

                        {Edituser && Edituser === true &&
                            <button className="grn_btn" onClick={() => setedituser(false)}>Edit</button>
                        }

                        {/* <button onClick={enable_mdl} className='grn_btn'>{Actionbutton.enable}</button> */}
                        <button className='diable_btn_edit' onClick={handle_delete_msg}>Delete</button>
                    </div>
                </div>
                <div className='create_itm'>
                    <div className='row'>
                        <div className='col-lg-3 col-12'>
                            <label className="label_w_s" htmlFor="a_c_i_pan">Username</label>
                            <input disabled={Edituser && Edituser === true} className="search_inp_filed in_tb" type="text" onChange={handle_view_change_user_master} id='userName' value={Usermaster.userName} name="userName" placeholder=""></input>
                            {
                                userfielderr.fielderr === true &&
                                <p style={{ marginBottom: '0', fontSize: '12px', color: 'red' }}>{Usermaster.userName === '' ? 'Please Provide Vaild information' : ''}</p>
                            }
                        </div>
                        <div className='col-lg-3 col-12'>
                            <label className="label_w_s" htmlFor="a_c_i_pan">User Id</label>
                            <input disabled={Edituser && Edituser === true} className="search_inp_filed in_tb" type="text" onChange={handle_view_change_user_master} id='emailId' value={Usermaster.emailId} name="emailId" placeholder=""></input>
                            {
                                userfielderr.fielderr === true &&
                                <p style={{ marginBottom: '0', fontSize: '12px', color: 'red' }}>{Usermaster.emailId === '' || userfielderr.emailerr === false ? 'Please Provide Vaild information' : ''}</p>
                            }
                        </div>
                        <div className='col-lg-3 col-12'>
                            <label className="label_w_s" htmlFor="a_c_i_pan">User Password</label>
                            <input disabled className="search_inp_filed in_tb" type="text" onChange={handle_view_change_user_master} id='userPassword' value={Usermaster.userPassword} name="userPassword" placeholder=""></input>
                            {/* {
                                userfielderr.fielderr === true &&
                                <p style={{ marginBottom: '0', fontSize: '12px', color: 'red' }}>{Usermaster.userPassword === '' ? 'Please Provide Vaild information' : ''}</p>
                            } */}
                        </div>
                        <div className='col-lg-3 col-12'>
                            <label className="label_w_s" htmlFor="a_c_i_pan">Department</label>
                            <input disabled={Edituser && Edituser === true} className="search_inp_filed in_tb" type="text" onChange={handle_view_change_user_master} id='departmentName' value={Usermaster.departmentName} name="departmentName" placeholder=""></input>
                            {
                                userfielderr.fielderr === true &&
                                <p style={{ marginBottom: '0', fontSize: '12px', color: 'red' }}>{Usermaster.departmentName === '' ? 'Please Provide Vaild information' : ''}</p>
                            }
                        </div>
                    </div>
                </div>
                <div className='creat_c_m c_s_c'>
                    <p style={{ marginBottom: '0', fontSize: '14px', color: '#c95643' }}>Access Control</p>
                </div>
                <div className='create_itm check'>
                    <div className='row mt-4'>
                        <div className='col-lg-3 col-12 user_check'>
                            <div>
                                <input disabled={Edituser && Edituser === true} checked={isChecked["1"] && isChecked["1"]["type"] === true} onChange={() => handleCheckboxChange("1")} type="checkbox" id="Customer_Master" name="Customer_Master" />
                                <label className="filter_checkbox_white" htmlFor="Customer_Master" >Customer Master</label>
                            </div>
                        </div>
                        <div className='col-lg-3 col-12 user_check'>
                            <input disabled={Edituser && Edituser === true} type="checkbox" checked={isChecked["2"] && isChecked["2"]["type"] === true} onChange={() => handleCheckboxChange("2")} id="Supplier_Configuration" name="Supplier_Configuration" />
                            <label className="filter_checkbox_white" htmlFor="Supplier_Configuration" >Supplier Configuration</label>
                        </div>
                        <div className='col-lg-3 col-12 user_check'>
                            <input disabled={Edituser && Edituser === true} type="checkbox" checked={isChecked["3"] && isChecked["3"]["type"] === true} onChange={() => handleCheckboxChange("3")} id="Invoice_Type_Configuration" name="Invoice_Type_Configuration" />
                            <label className="filter_checkbox_white" htmlFor="Invoice_Type_Configuration" >Invoice Type Configuration</label>
                        </div>
                        <div className='col-lg-3 col-12 user_check'>
                            <input disabled={Edituser && Edituser === true} type="checkbox" checked={isChecked["4"] && isChecked["4"]["type"] === true} onChange={() => handleCheckboxChange("4")} id="Item_Master_Configuration" name="Item_Master_Configuration" />
                            <label className="filter_checkbox_white" htmlFor="Item_Master_Configuration" >Item Master Configuration</label>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-lg-3 col-12 user_check'>
                            <input disabled={Edituser && Edituser === true} type="checkbox" checked={isChecked["5"] && isChecked["5"]["type"] === true} onChange={() => handleCheckboxChange("5")} id="Units_of_Measure" name="Units_of_Measure" />
                            <label className="filter_checkbox_white" htmlFor="Units_of_Measure" >Units of Measure</label>
                        </div>
                        <div className='col-lg-3 col-12 user_check'>
                            <input disabled={Edituser && Edituser === true} checked={isChecked["6"] && isChecked["6"]["type"] === true} onChange={() => handleCheckboxChange("6")} type="checkbox" id="Invoicing" name="Invoicing" />
                            <label className="filter_checkbox_white" htmlFor="Invoicing" >Invoicing</label>
                        </div>
                        <div className='col-lg-3 col-12 user_check'>
                            <input disabled={Edituser && Edituser === true} checked={isChecked["7"] && isChecked["7"]["type"] === true} onChange={() => handleCheckboxChange("7")} type="checkbox" id="Proforma_invoice" name="Proforma_invoice" />
                            <label className="filter_checkbox_white" htmlFor="Proforma_invoice" >Proforma invoice</label>
                        </div>
                    </div>
                </div>
                {
                    isChecked && isChecked["6"] && isChecked["6"]["type"] &&
                    <div>
                        <div className="creat_c_m c_s_c"><p style={{ marginBottom: '0', fontSize: "14px", color: '#c95643' }}>Invoicing Type</p></div>
                        <div style={{ padding: '10px' }}>
                            {
                                <table className='user_table_perf'>
                                    <thead>
                                        <tr>
                                            <th>Invoice Type Name</th>
                                            <th>Invoice Type Serial Code</th>
                                            <th>Status</th>
                                            <th>View</th>
                                            <th>Maker</th>
                                            <th>Checker</th>
                                            <th>Reports</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            usemaster_config && usemaster_config.map((usermaster, usermaster_uq) => (
                                                <tr key={usermaster_uq}>
                                                    <td>{usermaster.invoiceTypeName}</td>
                                                    <td>{usermaster.invoiceTypeSerialCode}</td>
                                                    <td>{usermaster.activeFlag == true ? "Enable" : "Disable"}</td>
                                                    <td>
                                                        <input
                                                            onChange={() => handleInvoiceTypeAccess(usermaster.invoiceTypeConfigurationIdentifier, "viewFlag")}
                                                            type="checkbox"
                                                            id={"view_I_" + usermaster.invoiceTypeConfigurationIdentifier}
                                                            name="Proforma_invoice"
                                                            checked={InvoiceTypes[usermaster.invoiceTypeConfigurationIdentifier]["viewFlag"]}
                                                            disabled={Edituser && Edituser === true}
                                                        />
                                                        <label className="filter_checkbox_white" htmlFor={"view_I_" + usermaster.invoiceTypeConfigurationIdentifier}></label>
                                                    </td>
                                                    <td>
                                                        <input disabled={Edituser && Edituser === true} onChange={() => handleInvoiceTypeAccess(usermaster.invoiceTypeConfigurationIdentifier, "markerFlag")}
                                                            checked={InvoiceTypes[usermaster.invoiceTypeConfigurationIdentifier]["markerFlag"]} type="checkbox" id={"Maker_I_" + usermaster.invoiceTypeConfigurationIdentifier} name="Proforma_invoice" />
                                                        <label className="filter_checkbox_white" htmlFor={"Maker_I_" + usermaster.invoiceTypeConfigurationIdentifier} ></label>
                                                    </td>
                                                    <td>
                                                        <input disabled={Edituser && Edituser === true} onChange={() => handleInvoiceTypeAccess(usermaster.invoiceTypeConfigurationIdentifier, "checkerFlag")}
                                                            checked={InvoiceTypes[usermaster.invoiceTypeConfigurationIdentifier]["checkerFlag"]} type="checkbox" id={"Checker_I_" + usermaster.invoiceTypeConfigurationIdentifier} name="Proforma_invoice" />
                                                        <label className="filter_checkbox_white" htmlFor={"Checker_I_" + usermaster.invoiceTypeConfigurationIdentifier} ></label>
                                                    </td>
                                                    <td>
                                                        <input disabled={Edituser && Edituser === true} onChange={() => handleInvoiceTypeAccess(usermaster.invoiceTypeConfigurationIdentifier, "reportFlag")}
                                                            checked={InvoiceTypes[usermaster.invoiceTypeConfigurationIdentifier]["reportFlag"]} type="checkbox" id={"Reports_I_" + usermaster.invoiceTypeConfigurationIdentifier} name="Proforma_invoice" />
                                                        <label className="filter_checkbox_white" htmlFor={"Reports_I_" + usermaster.invoiceTypeConfigurationIdentifier}  ></label>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            }

                        </div>
                    </div>
                }
                {
                    isChecked && isChecked["7"] && isChecked["7"]["type"] &&
                    <div>
                        <div className="creat_c_m c_s_c"><p style={{ marginBottom: '0', fontSize: "14px", color: '#c95643' }}>Proforma Invoice Type</p></div>
                        <div style={{ padding: '10px' }}>

                            {
                                <table className='user_table_perf'>
                                    <thead>
                                        <tr>
                                            <th>Invoice Type Name</th>
                                            <th>Invoice Type Serial Code</th>
                                            <th>Status</th>
                                            <th>View</th>
                                            <th>Maker</th>
                                            <th>Checker</th>
                                            <th>Reports</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            usemaster_config && usemaster_config.map((usermaster, usermaster_uq) => (
                                                <tr key={usermaster_uq}>
                                                    <td>{usermaster.invoiceTypeName}</td>
                                                    <td>{usermaster.invoiceTypeSerialCode}</td>
                                                    <td>{usermaster.activeFlag == 1 ? "Enable" : "Disable"}</td>
                                                    <td>
                                                        <input disabled={Edituser && Edituser === true} onChange={() => handleProfomaTypeAccess(usermaster.invoiceTypeConfigurationIdentifier, "viewFlag")}
                                                            checked={ProfomaTypes[usermaster.invoiceTypeConfigurationIdentifier]["viewFlag"]} type="checkbox" id={"view_P_" + usermaster.invoiceTypeConfigurationIdentifier} name="Proforma_invoice" />
                                                        <label className="filter_checkbox_white" htmlFor={"view_P_" + usermaster.invoiceTypeConfigurationIdentifier}></label>
                                                    </td>
                                                    <td>
                                                        <input disabled={Edituser && Edituser === true} onChange={() => handleProfomaTypeAccess(usermaster.invoiceTypeConfigurationIdentifier, "markerFlag")}
                                                            checked={ProfomaTypes[usermaster.invoiceTypeConfigurationIdentifier]["markerFlag"]} type="checkbox" id={"Maker_P_" + usermaster.invoiceTypeConfigurationIdentifier} name="Proforma_invoice" />
                                                        <label className="filter_checkbox_white" htmlFor={"Maker_P_" + usermaster.invoiceTypeConfigurationIdentifier} ></label>
                                                    </td>
                                                    <td>
                                                        <input disabled={Edituser && Edituser === true} onChange={() => handleProfomaTypeAccess(usermaster.invoiceTypeConfigurationIdentifier, "checkerFlag")}
                                                            checked={ProfomaTypes[usermaster.invoiceTypeConfigurationIdentifier]["checkerFlag"]} type="checkbox" id={"Checker_P_" + usermaster.invoiceTypeConfigurationIdentifier} name="Proforma_invoice" />
                                                        <label className="filter_checkbox_white" htmlFor={"Checker_P_" + usermaster.invoiceTypeConfigurationIdentifier} ></label>
                                                    </td>
                                                    <td>
                                                        <input disabled={Edituser && Edituser === true} onChange={() => handleProfomaTypeAccess(usermaster.invoiceTypeConfigurationIdentifier, "reportFlag")}
                                                            checked={ProfomaTypes[usermaster.invoiceTypeConfigurationIdentifier]["reportFlag"]} type="checkbox" id={"Reports_P_" + usermaster.invoiceTypeConfigurationIdentifier} name="Proforma_invoice" />
                                                        <label className="filter_checkbox_white" htmlFor={"Reports_P_" + usermaster.invoiceTypeConfigurationIdentifier}  ></label>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            }

                        </div>
                    </div>
                }
                {
                    Edituser === false &&

                    <div className="p_T_c_btn mt-4 mb-4 me-3">
                        <button className="grn_btn" onClick={handle_user_master}>Submit</button>
                        <button onClick={() => setUserPages(1)} className="diable_btn_edit">Cancel</button>
                    </div>
                }

            </div>
            <input type="text" id='usermaster_successfully' hidden data-bs-toggle="modal" data-bs-target="#usermaster_successfully_mdl" />
            <input type="text" id='userDelete_successfully' hidden data-bs-toggle="modal" data-bs-target="#userDelete_successfully_mdl" />
            <input type="text" id='userDelete_are_u_want_msg' hidden data-bs-toggle="modal" data-bs-target="#userDelete_are_u_want" />

            <div className="modal fade mdl_e_fade" id="usermaster_successfully_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            User Created Successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade mdl_e_fade" id="userDelete_successfully_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            User Delete Successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade mdl_e_fade" id="userDelete_are_u_want" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria- labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Are you Sure want to Delete User Record
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" onClick={() => {handleDeleteUser(Usermaster.identifier)}}  data-bs-dismiss="modal">Yes</button>
                            <button type="button" className="btn mdl_btn_cancel" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default User_view