import React from 'react'
import Main_table_invoice from './Main_table_invoice'
import { useContext } from 'react'
import { E_invoice_context } from '../../../../E_invoice_context'
import Pagination from '../../Pagination'

const All_invoice_main_table = ({ isAll, isMail, isCredit, isApproved, invoiceTypeDown }) => {
    const { SetShow, InvReqObj, setInvReqObj, invoiceviewdropdown, handleCreateInvoice, Search, setSearch, setAllinvoicepagination,
        handleAllInvoiceSearch, AllinvoicetypepageNumbers, Allinvoicepagination, AllinvoiceTypehandlePageChange, Invoicecmsnomcms, handleSendmailapprovalcredit, handleSendmail, handlsingleemail } = useContext(E_invoice_context)

    const Searchallinvoice = () => {
        handleAllInvoiceSearch();
        setAllinvoicepagination({ ...Allinvoicepagination, Current_page: Allinvoicepagination.Current_page = 1 });
    }




    const allinvoice_enter_search = (e) => {
        if (e.keyCode === 13) {
            Searchallinvoice();
        }
    }

    return (
        <div className='total_outfit_fr_tab'>
            <div className='over_all_div'>
                <div className="create_btn_top">
                    <div>
                        <button onClick={handleCreateInvoice} className="grn_btn">Create Invoice</button>

                        {isMail ? (
                            <button className='mx-2 grn_btn' onClick={handleSendmail}>Send Email</button>
                        ) : null}
                        {/* {isMail 
                        <button className='mx-2 grn_btn' onClick={() =>  handlemailCheckMark() }>Send Email</button>
                       } */}
                    </div>
                    <div className='create_right_aligh'>
                        <select value={InvReqObj.invoiceTypeIdentifier} onChange={(e) => { setInvReqObj({ ...InvReqObj, invoiceTypeIdentifier: e.target.value, currentPage: 1 }); setAllinvoicepagination({ ...Allinvoicepagination, Current_page: Allinvoicepagination.Current_page = 1 }); }} style={{ width: '300px' }} className="select_inp_filed" aria-label="Default select example">
                            <option  value=''>Invoice Type</option>
                            {
                                invoiceTypeDown && invoiceTypeDown.map((itemdrop, uq) => (
                                    <option key={uq} value={itemdrop.invoiceTypeConfigurationIdentifier}>
                                        {itemdrop.invoiceTypeName}
                                    </option>
                                ))
                            }
                        </select>
                        <select value={InvReqObj.supplierIdentifier} className="select_inp_filed" onChange={(e) => { setInvReqObj({ ...InvReqObj, supplierIdentifier: e.target.value, currentPage: 1 }); setAllinvoicepagination({ ...Allinvoicepagination, Current_page: Allinvoicepagination.Current_page = 1 }); }} aria-label="Default select example">
                            <option  value=''>Supplier Name</option>
                            {
                                invoiceviewdropdown && invoiceviewdropdown.map((invoicedrop, uq) => (
                                    <option key={uq} value={invoicedrop.supplierIdentifier}>
                                        {invoicedrop.supplierTradeName}
                                    </option>
                                ))
                            }
                        </select>
                        {isAll && <select value={InvReqObj.statusFlag} className="select_inp_filed" onChange={(e) => { setInvReqObj({ ...InvReqObj, statusFlag: e.target.value, currentPage: 1 }); setAllinvoicepagination({ ...Allinvoicepagination, Current_page: Allinvoicepagination.Current_page = 1 }); }} aria-label="Default select example">
                            {!isCredit ?
                                <>
                                    <option  value="3" >All</option>
                                    <option value="0">Pending</option>
                                    <option value="1">Approved</option>
                                    <option value="2">Rejected</option>
                                </> :
                                <>
                                    <option value="3" >All</option>
                                    <option value="0">Pending</option>
                                    <option value="1">Approved</option>
                                </>
                            }
                        </select>}
                        <input onKeyDown={allinvoice_enter_search} className="search_inp_filed" type="text" value={Search} onChange={(e) => setSearch(e.target.value)} name="" id="" placeholder="Search"></input>
                        <i onClick={Searchallinvoice} className="fa-solid fa-magnifying-glass search_icon"></i>
                    </div>
                </div>
                <Main_table_invoice
                    SetShow={SetShow}
                    isApproved={isApproved}
                />
            </div>

            <input type="text" id='Email_null_Model' hidden data-bs-toggle="modal" data-bs-target="#Email_mdl" />
            <div className="modal fade mdl_e_fade" id="Email_mdl" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1}  labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Please select Customer Name
                        </div>
                        <div className="modal-footer">

                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>



            <div className='mt-4 ps-1 pe-1' style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between' }}>
                <div>
                    <p style={{ color: '#9a9a9a', fontSize: '14px', marginBottom: '0px' }}>Total Record Count : <span style={{ fontWeight: '600' }}>{Invoicecmsnomcms.All_invoice}</span></p>
                </div>
                <div>
                    <Pagination
                        pageNumbers={AllinvoicetypepageNumbers}
                        Paginations={Allinvoicepagination}
                        handlePageChange={AllinvoiceTypehandlePageChange}
                        LastPage={Invoicecmsnomcms.All_invoice && Math.ceil(Invoicecmsnomcms.All_invoice / 10)}
                    />
                </div>
            </div>
        </div>
    )
}

export default All_invoice_main_table