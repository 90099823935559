import React, { useContext, useEffect, useState } from 'react'
import AuthService from '../../../../Service/Authservice'
import { useNavigate } from 'react-router-dom';
import { E_invoice_context } from '../../../../E_invoice_context';

const Payment_Trems_config = () => {

    const { sessionEndTime, decryptData } = useContext(E_invoice_context)

    useEffect(() => {
        if ((decryptData('Jwt_Token') === null || decryptData('Jwt_Token') === undefined || decryptData('Jwt_Token') === '') ||
            (sessionEndTime < new Date())) {
            document.getElementById('go_to_login_payment_time').click();
        }
    }, [])

    const Navigate = useNavigate()

    const Logout = () => {
        sessionStorage.clear()
        Navigate('/');
    }


    const [payment, setpayment] = useState({ payment: '' });
    const [identifier, setidentifier] = useState('')
    const [payenterr, setpaymenterr] = useState('')
    const [paymentedit, setpaymentedit] = useState(true)
    const [paymentvald, setpaymentvald] = useState(true);
    const handle_payment_change = (e) => {
        e.preventDefault()
        const { name, value } = e.target
        setpayment({ ...payment, [name]: value })
    }

    const handle_Cancel = () => {
        // console.log("hit")
        handle_payment_cancel();
        setpaymentedit(true);
    }


    // console.log(payment.payment)

    useEffect(() => {
        AuthService.GetServiceCall(`api/PaymentTerms/GetPaymentTerms`)
            .then((res) => {
                // console.log(res)
                setpayment({ payment: res.responseData.paymentTermsConditions });
                setidentifier(res.responseData.paymentIdentifier)
            })
    }, [])

    const setobj = {
        paymentTermsConditions: payment.payment,
        paymentIdentifier: identifier
    }



    const handle_payment_submit = () => {
        if (payment.payment === "") {
            setpaymentvald(false);
        }
        else {
            AuthService.PostServiceCall('api/PaymentTerms/UpdateTerms', setobj)
                .then((res) => {
                    // console.log(res)
                    if (res.responseMessage === "Success") {
                        document.getElementById('model_triger').click();
                        setpaymentedit(true)
                        setpaymentvald(true);
                    } else {
                        // alert('not Changed')
                    }

                })
        }
    }

    const handle_payment_cancel = () => {
        AuthService.GetServiceCall(`api/PaymentTerms/GetPaymentTerms`)
            .then((res) => {
                // console.log(res)
                setpayment({ payment: res.responseData.paymentTermsConditions });
                setidentifier(res.responseData.paymentIdentifier)
            })
    }

    return (
        <div>

            <input type='button' hidden id='go_to_logintime_all_inovice' data-bs-toggle="modal" data-bs-target="#go_to_login_all_inovice" />

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_all_inovice" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Please Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <input type="text" id='model_triger' hidden data-bs-toggle="modal" data-bs-target="#model_one" />

            <div className="modal fade mdl_e_fade" id="model_one" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title">Alert</h5>
                            <button type="button" className="btn-close mdl_btn_cls" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Record updated successfully
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn mdl_btn_dwn" data-bs-dismiss="modal">OK</button>
                        </div>
                    </div>
                </div>
            </div>


            <input type='button' hidden id='go_to_login_payment_time' data-bs-toggle="modal" data-bs-target="#go_to_login_payment" />

            <div className="modal fade mdl_e_fade" tabIndex="-1" data-bs-backdrop="static" id="go_to_login_payment" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered mdl_dialogu" role="document">
                    <div className="modal-content mdl_cont">
                        <div className="modal-header mdl_header">
                            <h5 className="modal-title mdl_title" id="exampleModalLabel">Alert</h5>
                        </div>
                        <div className="modal-body mdl_bdy">
                            Something Went Wrong.Login Again
                        </div>
                        <div className="modal-footer">
                            <button onClick={Logout} type="button" data-bs-dismiss="modal" className="btn mdl_btn_dwn">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="h4_btm_bdr"><h4>Payment Terms</h4></div>
            <div className='Trm_condition_head'>
                <span>Create Payment Terms & Conditions</span>
                <button className='grn_btn' onClick={() => setpaymentedit(false)}>Edit</button>
            </div>
            <div className='total_outfit_fr_tab p_t_c'>
                <span>Payment Terms & Conditions</span><span className="mandatory_star2">*</span>
                <textarea disabled={paymentedit === true} style={{ marginBottom: "5px" }} className='input_txt_are' name="payment" value={payment.payment} onChange={handle_payment_change} placeholder='Immediate Payment' id="" cols="30" rows="4"></textarea>
                {
                    paymentvald === false &&
                    <p style={{ color: 'red' }}>{payment.payment === '' ? 'Please Provide valid Information' : ''}</p>
                }

                {
                    paymentedit === false &&

                    < div className='p_T_c_btn'>
                        <button onClick={handle_payment_submit} className="grn_btn">Submit</button>
                        <button onClick={handle_Cancel} className="diable_btn_edit">Cancel</button>
                    </div>
                }
            </div>
        </div >
    )
}

export default Payment_Trems_config