import React, { useContext, useState } from 'react'
import AuthService from '../../../../Service/Authservice'
import { E_invoice_context } from '../../../../E_invoice_context'

const Create_state_code = ({ setstateconfig, GetStateconfigdata }) => {
    const { statecodevald, setstatecodevald, statenamecode, handlestatenamecodechange, handlesubmitstate } = useContext(E_invoice_context)
 
    return (
        <div>
            <div className='creat_c_m c_s_c'>
                <i onClick={() => setstateconfig(1)} className="fa-solid fa-circle-arrow-left"></i>
                <p style={{ marginBottom: '0', color: '#c95643', fontSize: '14px' }}>Create State Code</p>
            </div>
            <div className='row mt-3 mb-3'>
                <div className='col-lg-4'>
                    <label htmlFor="" className='label_w_s'>State Name<span className="mandatory_star2">*</span></label>
                    <input className = "search_inp_filed in_tb" type="text" name="stateName" value={statenamecode.stateName} onChange={handlestatenamecodechange} id="" placeholder=""></input>
                    {
                        statecodevald === false &&
                        <p style={{ marginBottom: 0, fontSize: '12px', color: "red" }}>{statenamecode.stateName === "" || statenamecode.stateName.length>100 ? ('Please Provide valid Information & Cannot exceed 100 characters') : ('')}</p>
                    }
                </div>
                <div className='col-lg-4'>
                    <label htmlFor="" className='label_w_s'>State Code<span className="mandatory_star2">*</span></label>
                    {/* <input className="search_inp_filed in_tb" type="text" name="stateCode" value={statenamecode.stateCode} onChange={handlestatenamecodechange} id="" placeholder=""></input> */}
                    <input className = "search_inp_filed in_tb" type="text" name="stateCode" value={statenamecode.stateCode} onChange={handlestatenamecodechange} id="" placeholder=""></input>
                    {
                        statecodevald === false &&
                        <p style={{ marginBottom: 0, fontSize: '12px', color: "red" }}>{statenamecode.stateCode === "" || statenamecode.stateCode.length> 100? ('Please Provide valid Information & Cannot exceed 100 characters') : ('')}</p>
                    }
                </div>
            </div>
            <div className='p_T_c_btn'>
                <button className="grn_btn" onClick={handlesubmitstate}>Submit</button>
                <button onClick={() => setstateconfig(1)} className="diable_btn_edit">Cancel</button>
            </div>

        </div>
    )
}

export default Create_state_code 